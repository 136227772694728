import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "100vw",
    height: "100vh",
    backgroundColor: theme.palette.common.white,
  },
  fullHeight: {
    minHeight: "100vh",
    height: "auto",
    position: "relative",
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  fullWidth: {
    width: "100%",
  },
  scroll: {
    overflow: "scroll",
  },
  childrenContainer: {
    padding: "0 2rem 2rem 2rem",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    height: "1.5rem",
    padding: "0 2rem",
  },
}));

export default useStyles;
