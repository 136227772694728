/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ProjectCreate
 */
export interface ProjectCreate {
  /**
   *
   * @type {string}
   * @memberof ProjectCreate
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectCreate
   */
  opportunityNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProjectCreate
   */
  projectManagerEmail?: string | null;
}

export function ProjectCreateFromJSON(json: any): ProjectCreate {
  return ProjectCreateFromJSONTyped(json, false);
}

export function ProjectCreateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    opportunityNumber: !exists(json, "opportunityNumber") ? undefined : json["opportunityNumber"],
    projectManagerEmail: !exists(json, "projectManagerEmail") ? undefined : json["projectManagerEmail"],
  };
}

export function ProjectCreateToJSON(value?: ProjectCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    opportunityNumber: value.opportunityNumber,
    projectManagerEmail: value.projectManagerEmail,
  };
}
