/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import { Phase, PhaseFromJSON, PhaseFromJSONTyped, PhaseToJSON } from "./Phase";

/**
 *
 * @export
 * @interface PhaseControllerResponse
 */
export interface PhaseControllerResponse {
  /**
   *
   * @type {Phase}
   * @memberof PhaseControllerResponse
   */
  data?: Phase;
  /**
   *
   * @type {ErrorResponse}
   * @memberof PhaseControllerResponse
   */
  error?: ErrorResponse;
}

export function PhaseControllerResponseFromJSON(json: any): PhaseControllerResponse {
  return PhaseControllerResponseFromJSONTyped(json, false);
}

export function PhaseControllerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhaseControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : PhaseFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function PhaseControllerResponseToJSON(value?: PhaseControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: PhaseToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
