import { format } from "date-fns";

export const DATE_DISPLAY_FORMAT_WITH_TIME = "MM/dd/yyyy hh:mm:ss aa";
export const SHORT_DATE_DISPLAY_FORMAT = "MM/dd/yyyy";
export const LONG_DATE_DISPLAY_FORMAT = "MMM dd, yyyy";
export const ONLY_TIME = "hh:mm:ss aa";

/**
 * @description Format date based on this application's standard format.
 */
export const dateFormat = (date: string | number | Date, type: "short" | "long" | "time" | "dateWithTime") => {
  const formatType = {
    short: SHORT_DATE_DISPLAY_FORMAT,
    long: LONG_DATE_DISPLAY_FORMAT,
    time: ONLY_TIME,
    dateWithTime: DATE_DISPLAY_FORMAT_WITH_TIME,
  }[type];
  try {
    const dateObject = date instanceof Date ? date : new Date(date);
    // format may fail if dateObject is an invalid date
    return format(dateObject, formatType);
  } catch (err) {
    return format(new Date(), formatType);
  }
};
