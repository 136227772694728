import { TableBodyClassKey } from "@material-ui/core/TableBody";
import { TableCellClassKey } from "@material-ui/core/TableCell";
import { TableRowClassKey } from "@material-ui/core/TableRow";
import { TableClassKey } from "@material-ui/core/Table";
import { CSSProperties } from "@material-ui/styles/withStyles";

import { black, grayLight, white } from "../colors";

export const MuiTableTheme: Partial<Record<TableClassKey, CSSProperties>> = {
  root: {
    border: `1px solid ${grayLight}`,
  },
};

export const MuiTableBodyTheme: Partial<Record<TableBodyClassKey, CSSProperties>> = {
  root: {
    "& tbody": {
      background: white,
    },
  },
};
export const MuiTableRowTheme: Partial<Record<TableRowClassKey, CSSProperties>> = {
  head: {
    height: "unset",
  },
};

export const MuiTableCellTheme: Partial<Record<TableCellClassKey, CSSProperties>> = {
  root: {
    padding: "0.875rem 0.625rem 0.875rem 1rem",
    borderBottom: `1px solid ${grayLight}`,
  },
  head: {
    height: "auto",
    paddingBottom: "1rem",
    paddingTop: "1rem",
    fontSize: "0.875rem",
    fontWeight: "bold",
    color: black,
  },
  body: {
    fontSize: "0.875rem",
  },
};
