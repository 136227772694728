import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { AppState } from "store/types";
import { User, UsersApi, ApiV1UsersIdArchivedPostRequest, UserMultipleRoles } from "data/api";
import { call } from "data/api/rest";

const createAction = actionCreatorFactory("ADMIN/SYSTEM_USERS");
const createAsyncAction = asyncFactory<AppState>(createAction);

export const getSystemUsers = createAsyncAction<void, UserMultipleRoles[], Error>("GET_USERS", async () => {
  const { data: users } = await call(UsersApi).apiV1UsersAllGet();
  return users || [];
});

export const resendAdminInvite = createAsyncAction<{ email: string }, User, Error>(
  "RESEND_INVITE_ADMIN_USER",
  async ({ email }, dispatch) => {
    return dispatch(inviteAdmin({ email }));
  }
);

export const inviteAdmin = createAsyncAction<{ email: string }, User, Error>("INVITE_ADMIN_USER", async ({ email }) => {
  const response = await call(UsersApi).apiV1UsersAdminsPost({
    user: {
      email,
      isAdmin: true,
    },
  });
  return response ? response.data! : {};
});

export const removeSystemUser = createAsyncAction<number, User, Error>("REMOVE_USER", async (userId) => {
  const archiveRequest: ApiV1UsersIdArchivedPostRequest = {
    id: userId,
    archiveUserRequest: {
      userId: userId,
      archive: true,
    },
  };
  const response = await call(UsersApi).apiV1UsersIdArchivedPost(archiveRequest);
  return response ? response.data! : {};
});
