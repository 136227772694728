import { AnyAction } from "typescript-fsa";
import { mapValues } from "data/api";

type FromUserTechnicianOutboxReducerState = {
  working: boolean;
  queue: AnyAction[];
  deadletter: AnyAction[];
};

type FromTechnicianOutboxReducerState = {
  currentUserId: number | null;
  byId: {
    [userId: number]: FromUserTechnicianOutboxReducerState;
  };
};

type FromTechnicianAppState = {
  outbox: FromTechnicianOutboxReducerState;
};

type ToUserTechnicianOutboxReducerState = {
  working: boolean;
  queue: AnyAction[];
  deadletter: AnyAction[];
  isRemovingDeadLetters: boolean;
};

type ToTechnicianOutboxReducerState = {
  currentUserId: number | null;
  byId: {
    [userId: number]: ToUserTechnicianOutboxReducerState;
  };
};

type ToTechnicianAppState = {
  outbox: ToTechnicianOutboxReducerState;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const migrate = (state: any): any => {
  const { technician: technicianReducerState } = state;
  const { outbox } = technicianReducerState as FromTechnicianAppState;
  const { byId } = outbox;
  const newTechnicianState: ToTechnicianAppState = {
    ...technicianReducerState,
    outbox: {
      ...outbox,
      byId: mapValues(byId, (userOutbox: FromUserTechnicianOutboxReducerState) => ({
        ...userOutbox,
        isRemovingDeadLetters: false,
      })),
    },
  };

  return {
    ...state,
    technician: newTechnicianState,
  };
};

export default migrate;
