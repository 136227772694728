/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";

/**
 *
 * @export
 * @interface BooleanControllerResponse
 */
export interface BooleanControllerResponse {
  /**
   *
   * @type {boolean}
   * @memberof BooleanControllerResponse
   */
  data?: boolean;
  /**
   *
   * @type {ErrorResponse}
   * @memberof BooleanControllerResponse
   */
  error?: ErrorResponse;
}

export function BooleanControllerResponseFromJSON(json: any): BooleanControllerResponse {
  return BooleanControllerResponseFromJSONTyped(json, false);
}

export function BooleanControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BooleanControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : json["data"],
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function BooleanControllerResponseToJSON(value?: BooleanControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data,
    error: ErrorResponseToJSON(value.error),
  };
}
