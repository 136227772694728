import { reducerWithInitialState } from "typescript-fsa-reducers";
import { AdminSystemUsersReducerState } from "./types";
import { getSystemUsers, inviteAdmin, removeSystemUser } from "./actions";

const initialState: AdminSystemUsersReducerState = {
  isLoading: false,
  isInviting: false,
  isRemoving: false,
  users: [],
  error: null,
};

const reducer = reducerWithInitialState(initialState)
  .case(getSystemUsers.async.started, (state) => ({
    ...state,
    isLoading: true,
  }))
  .case(getSystemUsers.async.failed, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  }))
  .case(getSystemUsers.async.done, (state, { result: users }) => ({
    ...state,
    users,
    isLoading: false,
  }))
  .case(inviteAdmin.async.started, (state) => ({
    ...state,
    isInviting: true,
  }))
  .case(inviteAdmin.async.failed, (state, { error }) => ({
    ...state,
    isInviting: false,
    error,
  }))
  .case(inviteAdmin.async.done, (state, { result: user }) => {
    const alreadyExists = state.users.some((u) => u.id === user.id);
    return {
      ...state,
      isInviting: false,
      users: alreadyExists ? state.users : [...state.users, user],
    };
  })
  .case(removeSystemUser.async.started, (state) => ({
    ...state,
    isRemoving: true,
  }))
  .case(removeSystemUser.async.failed, (state, { error }) => ({
    ...state,
    isRemoving: false,
    error,
  }))
  .case(removeSystemUser.async.done, (state, { result: { id } }) => ({
    ...state,
    isRemoving: false,
    users: state.users.map((user) => (user.id === id ? { ...user, isActive: false } : user)),
  }));

export default reducer;
