import { SelectClassKey, SelectProps } from "@material-ui/core/Select";
import withStyles, { CSSProperties } from "@material-ui/styles/withStyles";
import * as React from "react";
import { InputBase, Theme } from "@material-ui/core";

export const MuiSelectTheme: Partial<Record<SelectClassKey, CSSProperties>> = {
  root: {
    borderRadius: "initial",
  },
  select: {
    padding: "0.3125rem 1.5rem 0.3125rem 0.875rem",
    "&$disabled": {
      cursor: "not-allowed",
    },
  },
};

const SelectInput = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
}))(InputBase);

export const MuiSelectProps: Partial<SelectProps> = {
  variant: "outlined",
  input: <SelectInput />,
  fullWidth: true,
};
