import * as React from "react";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

export interface Item {
  label: React.ReactNode;
  selected?: boolean;
  shouldRender?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

interface PopoverMenuProps {
  items: Item[];
  children: (isOpen: boolean, openPopover: (e: React.MouseEvent) => void) => React.ReactNode;
  onChange?: (isOpen: boolean) => void;
  closeOnItemClick?: boolean;
}

const PopoverMenu = ({ items, closeOnItemClick = true, onChange, children }: PopoverMenuProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [elementRef, setElementRef] = React.useState<undefined | Element>();
  const uniqueId = React.useRef(String(Math.random()));

  React.useEffect(() => {
    onChange && onChange(isOpen);
  }, [isOpen, onChange]);

  const openPopover = React.useCallback(
    (e: React.MouseEvent) => {
      setIsOpen(!isOpen);
      setElementRef(e.currentTarget);
    },
    [isOpen]
  );

  return (
    <Grid container>
      {children(isOpen, openPopover)}
      <Popover id={uniqueId.current} open={isOpen} onClose={() => setIsOpen(false)} anchorEl={elementRef}>
        <List>
          {items
            .filter(({ shouldRender = true }) => shouldRender)
            .map((item: Item, i) => (
              <ListItem
                button
                className={item.className}
                key={i}
                selected={item.selected}
                aria-roledescription="listitem"
                onClick={(e) => {
                  if (item.disabled) return;
                  item.onClick && item.onClick(e);
                  if (closeOnItemClick) setIsOpen(false);
                }}
              >
                {typeof item.label === "string" ? <ListItemText>{item.label}</ListItemText> : item.label}
              </ListItem>
            ))}
        </List>
      </Popover>
    </Grid>
  );
};

export default PopoverMenu;
