/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  PhaseCompletionStatus,
  PhaseCompletionStatusFromJSON,
  PhaseCompletionStatusFromJSONTyped,
  PhaseCompletionStatusToJSON,
} from "./PhaseCompletionStatus";

/**
 *
 * @export
 * @interface PhaseCompletion
 */
export interface PhaseCompletion {
  /**
   *
   * @type {string}
   * @memberof PhaseCompletion
   */
  tagId?: string;
  /**
   *
   * @type {number}
   * @memberof PhaseCompletion
   */
  projectId?: number;
  /**
   *
   * @type {number}
   * @memberof PhaseCompletion
   */
  areaId?: number;
  /**
   *
   * @type {number}
   * @memberof PhaseCompletion
   */
  phaseId?: number;
  /**
   *
   * @type {string}
   * @memberof PhaseCompletion
   */
  phaseName?: string | null;
  /**
   *
   * @type {number}
   * @memberof PhaseCompletion
   */
  itemsCompleted?: number;
  /**
   *
   * @type {number}
   * @memberof PhaseCompletion
   */
  itemsNotCompleted?: number;
  /**
   *
   * @type {boolean}
   * @memberof PhaseCompletion
   */
  isHeaderComplete?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PhaseCompletion
   */
  isExcluded?: boolean;
  /**
   *
   * @type {string}
   * @memberof PhaseCompletion
   */
  completedByUser?: string | null;
  /**
   *
   * @type {Date}
   * @memberof PhaseCompletion
   */
  dateCompleted?: Date | null;
  /**
   *
   * @type {boolean}
   * @memberof PhaseCompletion
   */
  hasCriticalIssue?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PhaseCompletion
   */
  hasNonCriticalIssue?: boolean;
  /**
   *
   * @type {PhaseCompletionStatus}
   * @memberof PhaseCompletion
   */
  phaseCompletionStatus?: PhaseCompletionStatus;
}

export function PhaseCompletionFromJSON(json: any): PhaseCompletion {
  return PhaseCompletionFromJSONTyped(json, false);
}

export function PhaseCompletionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhaseCompletion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tagId: !exists(json, "tagId") ? undefined : json["tagId"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    areaId: !exists(json, "areaId") ? undefined : json["areaId"],
    phaseId: !exists(json, "phaseId") ? undefined : json["phaseId"],
    phaseName: !exists(json, "phaseName") ? undefined : json["phaseName"],
    itemsCompleted: !exists(json, "itemsCompleted") ? undefined : json["itemsCompleted"],
    itemsNotCompleted: !exists(json, "itemsNotCompleted") ? undefined : json["itemsNotCompleted"],
    isHeaderComplete: !exists(json, "isHeaderComplete") ? undefined : json["isHeaderComplete"],
    isExcluded: !exists(json, "isExcluded") ? undefined : json["isExcluded"],
    completedByUser: !exists(json, "completedByUser") ? undefined : json["completedByUser"],
    dateCompleted: !exists(json, "dateCompleted")
      ? undefined
      : json["dateCompleted"] === null
      ? null
      : new Date(json["dateCompleted"]),
    hasCriticalIssue: !exists(json, "hasCriticalIssue") ? undefined : json["hasCriticalIssue"],
    hasNonCriticalIssue: !exists(json, "hasNonCriticalIssue") ? undefined : json["hasNonCriticalIssue"],
    phaseCompletionStatus: !exists(json, "phaseCompletionStatus")
      ? undefined
      : PhaseCompletionStatusFromJSON(json["phaseCompletionStatus"]),
  };
}

export function PhaseCompletionToJSON(value?: PhaseCompletion | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tagId: value.tagId,
    projectId: value.projectId,
    areaId: value.areaId,
    phaseId: value.phaseId,
    phaseName: value.phaseName,
    itemsCompleted: value.itemsCompleted,
    itemsNotCompleted: value.itemsNotCompleted,
    isHeaderComplete: value.isHeaderComplete,
    isExcluded: value.isExcluded,
    completedByUser: value.completedByUser,
    dateCompleted:
      value.dateCompleted === undefined
        ? undefined
        : value.dateCompleted === null
        ? null
        : value.dateCompleted.toISOString(),
    hasCriticalIssue: value.hasCriticalIssue,
    hasNonCriticalIssue: value.hasNonCriticalIssue,
    phaseCompletionStatus: PhaseCompletionStatusToJSON(value.phaseCompletionStatus),
  };
}
