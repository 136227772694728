/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { AssetType, AssetTypeFromJSON, AssetTypeFromJSONTyped, AssetTypeToJSON } from "./AssetType";

/**
 *
 * @export
 * @interface Asset
 */
export interface Asset {
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof Asset
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof Asset
   */
  updatedAt?: Date;
  /**
   *
   * @type {number}
   * @memberof Asset
   */
  projectId?: number;
  /**
   *
   * @type {boolean}
   * @memberof Asset
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  manufacturer?: string | null;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  model?: string | null;
  /**
   *
   * @type {string}
   * @memberof Asset
   */
  serialNumber?: string | null;
  /**
   *
   * @type {AssetType}
   * @memberof Asset
   */
  assetType?: AssetType;
}

export function AssetFromJSON(json: any): Asset {
  return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "createdAt") ? undefined : new Date(json["createdAt"]),
    updatedAt: !exists(json, "updatedAt") ? undefined : new Date(json["updatedAt"]),
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    deleted: !exists(json, "deleted") ? undefined : json["deleted"],
    manufacturer: !exists(json, "manufacturer") ? undefined : json["manufacturer"],
    model: !exists(json, "model") ? undefined : json["model"],
    serialNumber: !exists(json, "serialNumber") ? undefined : json["serialNumber"],
    assetType: !exists(json, "assetType") ? undefined : AssetTypeFromJSON(json["assetType"]),
  };
}

export function AssetToJSON(value?: Asset | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt === undefined /*|| null*/ ? undefined : value.createdAt.toISOString(),
    updatedAt: value.updatedAt === undefined /*|| null*/ ? undefined : value.updatedAt.toISOString(),
    projectId: value.projectId,
    deleted: value.deleted,
    manufacturer: value.manufacturer,
    model: value.model,
    serialNumber: value.serialNumber,
    assetType: AssetTypeToJSON(value.assetType),
  };
}
