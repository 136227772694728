/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { AssetType, AssetTypeFromJSON, AssetTypeFromJSONTyped, AssetTypeToJSON } from "./AssetType";

/**
 *
 * @export
 * @interface ExclusionList
 */
export interface ExclusionList {
  /**
   *
   * @type {number}
   * @memberof ExclusionList
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ExclusionList
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof ExclusionList
   */
  projectId?: number;
  /**
   *
   * @type {number}
   * @memberof ExclusionList
   */
  phaseId?: number;
  /**
   *
   * @type {number}
   * @memberof ExclusionList
   */
  createdById?: number;
  /**
   *
   * @type {Date}
   * @memberof ExclusionList
   */
  lastUpdatedAt?: Date | null;
  /**
   *
   * @type {Array<AssetType>}
   * @memberof ExclusionList
   */
  assetTypes?: Array<AssetType> | null;
}

export function ExclusionListFromJSON(json: any): ExclusionList {
  return ExclusionListFromJSONTyped(json, false);
}

export function ExclusionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExclusionList {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    phaseId: !exists(json, "phaseId") ? undefined : json["phaseId"],
    createdById: !exists(json, "createdById") ? undefined : json["createdById"],
    lastUpdatedAt: !exists(json, "lastUpdatedAt")
      ? undefined
      : json["lastUpdatedAt"] === null
      ? null
      : new Date(json["lastUpdatedAt"]),
    assetTypes: !exists(json, "assetTypes")
      ? undefined
      : json["assetTypes"] === null
      ? null
      : (json["assetTypes"] as Array<any>).map(AssetTypeFromJSON),
  };
}

export function ExclusionListToJSON(value?: ExclusionList | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    projectId: value.projectId,
    phaseId: value.phaseId,
    createdById: value.createdById,
    lastUpdatedAt:
      value.lastUpdatedAt === undefined
        ? undefined
        : value.lastUpdatedAt === null
        ? null
        : value.lastUpdatedAt.toISOString(),
    assetTypes:
      value.assetTypes === undefined
        ? undefined
        : value.assetTypes === null
        ? null
        : (value.assetTypes as Array<any>).map(AssetTypeToJSON),
  };
}
