import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { InputClassKey } from "@material-ui/core/Input";
import { gray } from "styles/colors";

const MuiInputTheme: Partial<Record<InputClassKey, CSSProperties>> = {
  root: {
    height: "2.5rem",
    borderRadius: 0,
    "&$disabled": {
      cursor: "not-allowed",
    },
    borderBottom: `1px solid ${gray}`,
    padding: "0 0.3125rem",
  },
};

export { MuiInputTheme };
