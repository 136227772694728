import { reducerWithInitialState } from "typescript-fsa-reducers";
import { TechnicianChecklistReducerState } from "./types";
import { completeChecklists } from "./actions";

const initialState: TechnicianChecklistReducerState = {
  isCompleting: false,
  error: null,
};

const reducer = reducerWithInitialState(initialState)
  .case(completeChecklists.async.started, (state) => ({
    ...state,
    isCompleting: true,
  }))
  .case(completeChecklists.async.failed, (state, { error }) => ({
    ...state,
    isCompleting: false,
    error,
  }))
  .case(completeChecklists.async.done, (state) => ({
    ...state,
    error: null,
    isCompleting: false,
  }));

export default reducer;
