/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Area, AreaFromJSON, AreaFromJSONTyped, AreaToJSON } from "./Area";
import { AssetType, AssetTypeFromJSON, AssetTypeFromJSONTyped, AssetTypeToJSON } from "./AssetType";
import {
  ChecklistItemHeader,
  ChecklistItemHeaderFromJSON,
  ChecklistItemHeaderFromJSONTyped,
  ChecklistItemHeaderToJSON,
} from "./ChecklistItemHeader";
import { Phase, PhaseFromJSON, PhaseFromJSONTyped, PhaseToJSON } from "./Phase";
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {number}
   * @memberof Project
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  opportunityNumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  hasDownloaded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  isDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Project
   */
  updateableFlexFields?: string | null;
  /**
   *
   * @type {Array<User>}
   * @memberof Project
   */
  projectManagers?: Array<User> | null;
  /**
   *
   * @type {Array<User>}
   * @memberof Project
   */
  techs?: Array<User> | null;
  /**
   *
   * @type {Array<User>}
   * @memberof Project
   */
  clients?: Array<User> | null;
  /**
   *
   * @type {Array<Phase>}
   * @memberof Project
   */
  phases?: Array<Phase> | null;
  /**
   *
   * @type {Array<Area>}
   * @memberof Project
   */
  areas?: Array<Area> | null;
  /**
   *
   * @type {Array<ChecklistItemHeader>}
   * @memberof Project
   */
  checklistItemHeaders?: Array<ChecklistItemHeader> | null;
  /**
   *
   * @type {Array<AssetType>}
   * @memberof Project
   */
  assetTypes?: Array<AssetType> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof Project
   */
  flexFieldHeaders?: Array<string> | null;
}

export function ProjectFromJSON(json: any): Project {
  return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    opportunityNumber: !exists(json, "opportunityNumber") ? undefined : json["opportunityNumber"],
    isActive: !exists(json, "isActive") ? undefined : json["isActive"],
    isDeleted: !exists(json, "isDeleted") ? undefined : json["isDeleted"],
    updateableFlexFields: !exists(json, "updateableFlexFields") ? undefined : json["updateableFlexFields"],
    projectManagers: !exists(json, "projectManagers")
      ? undefined
      : json["projectManagers"] === null
      ? null
      : (json["projectManagers"] as Array<any>).map(UserFromJSON),
    techs: !exists(json, "techs")
      ? undefined
      : json["techs"] === null
      ? null
      : (json["techs"] as Array<any>).map(UserFromJSON),
    clients: !exists(json, "clients")
      ? undefined
      : json["clients"] === null
      ? null
      : (json["clients"] as Array<any>).map(UserFromJSON),
    phases: !exists(json, "phases")
      ? undefined
      : json["phases"] === null
      ? null
      : (json["phases"] as Array<any>).map(PhaseFromJSON),
    areas: !exists(json, "areas")
      ? undefined
      : json["areas"] === null
      ? null
      : (json["areas"] as Array<any>).map(AreaFromJSON),
    checklistItemHeaders: !exists(json, "checklistItemHeaders")
      ? undefined
      : json["checklistItemHeaders"] === null
      ? null
      : (json["checklistItemHeaders"] as Array<any>).map(ChecklistItemHeaderFromJSON),
    assetTypes: !exists(json, "assetTypes")
      ? undefined
      : json["assetTypes"] === null
      ? null
      : (json["assetTypes"] as Array<any>).map(AssetTypeFromJSON),
    flexFieldHeaders: !exists(json, "flexFieldHeaders") ? undefined : json["flexFieldHeaders"],
  };
}

export function ProjectToJSON(value?: Project | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    opportunityNumber: value.opportunityNumber,
    isActive: value.isActive,
    isDeleted: value.isDeleted,
    updateableFlexFields: value.updateableFlexFields,
    projectManagers:
      value.projectManagers === undefined
        ? undefined
        : value.projectManagers === null
        ? null
        : (value.projectManagers as Array<any>).map(UserToJSON),
    techs:
      value.techs === undefined ? undefined : value.techs === null ? null : (value.techs as Array<any>).map(UserToJSON),
    clients:
      value.clients === undefined
        ? undefined
        : value.clients === null
        ? null
        : (value.clients as Array<any>).map(UserToJSON),
    phases:
      value.phases === undefined
        ? undefined
        : value.phases === null
        ? null
        : (value.phases as Array<any>).map(PhaseToJSON),
    areas:
      value.areas === undefined ? undefined : value.areas === null ? null : (value.areas as Array<any>).map(AreaToJSON),
    checklistItemHeaders:
      value.checklistItemHeaders === undefined
        ? undefined
        : value.checklistItemHeaders === null
        ? null
        : (value.checklistItemHeaders as Array<any>).map(ChecklistItemHeaderToJSON),
    assetTypes:
      value.assetTypes === undefined
        ? undefined
        : value.assetTypes === null
        ? null
        : (value.assetTypes as Array<any>).map(AssetTypeToJSON),
    flexFieldHeaders: value.flexFieldHeaders,
  };
}
