/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ControllerResponse,
  ControllerResponseFromJSON,
  ControllerResponseToJSON,
  Tag,
  TagFromJSON,
  TagToJSON,
  TagControllerResponse,
  TagControllerResponseFromJSON,
  TagControllerResponseToJSON,
} from "../models";

export interface ApiV1TagsIdTechnicianPostRequest {
  id: string;
  tag?: Tag;
}

/**
 *
 */
export class TagsApi extends runtime.BaseAPI {
  /**
   */
  async apiV1TagsIdTechnicianPostRaw(
    requestParameters: ApiV1TagsIdTechnicianPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TagsIdTechnicianPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Tags/{id}/technician`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TagToJSON(requestParameters.tag),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TagsIdTechnicianPost(
    requestParameters: ApiV1TagsIdTechnicianPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagControllerResponse> {
    const response = await this.apiV1TagsIdTechnicianPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
