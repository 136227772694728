import {
  optimisiticAsyncFactory as asyncFactory,
  optimisiticActionCreatorFactory as actionCreatorFactory,
} from "../offline";
import { v4 as uuid } from "uuid";
import { AppState } from "store/types";
import {
  TechApi,
  ApiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPostRequest,
  ApiV1TechProjectsIdTagsTagIdImagesPostRequest,
  ApiV1TechProjectsIdTagsTagIdAttachmentsPostRequest,
  IssueImageControllerResponse,
  TagIssue,
  Tag,
  ApiV1TechProjectsIdTagsTagIdImagesImageIdDeleteRequest,
  TagImage,
  ApiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDeleteRequest
} from "data/api";
import { TagAttachment } from "data/api/models/TagAttachment";
import { call } from "data/api/rest";
import {
  CreateImagesPost,
  CreateAttachmentsPost,
  ApiV1TechProjectsIdTagsPutRequestWithImages,
  ApiV1TechProjectsIdTagsTagIdIssuesPostRequestWithImages,
  DeleteImage,
  DeleteAttachment,
} from "./types";
import { dataURLtoFile, dataURLtoFileWithFileName } from "utils/blobUtils";

const createAction = actionCreatorFactory("TECH/TAGS");
const createAsyncAction = asyncFactory<AppState>(createAction);

export const upsertTag = createAsyncAction<ApiV1TechProjectsIdTagsPutRequestWithImages, Tag, Error>(
  "UPSERT_TAG",
  // runs only on admin screen
  ({ newPhotos, removePhotos = [], newAttachments, removeAttachments = [], tag }) => {
    const existingPhotos = removePhotos && removePhotos.length > 0 ? [] : tag?.images;
    const existingAttachments = removeAttachments && removeAttachments.length > 0 ? [] : tag?.attachments;
    // Nick update
    /* TODO later
    if ( removePhotos && removePhotos.length > 0 ) {
      deleteImages({ removePhotos, tagRequestId: tag!.asset!.projectId!, tagId: tag?.id! });
      //removePhotos = []
    }
    */

    console.log("UPSERT_TAG", newPhotos, removePhotos = [], newAttachments, removeAttachments = [], tag);
    return {
      ...tag!,
      images: [
        ...(existingPhotos as TagImage[]),
        ...newPhotos.map(
          (photo): TagImage => ({
            tagId: tag?.id,
            image: {
              imageId: photo.image.imageId || uuid(),
              fileExtension: photo.image.fileExtension || null,
              url: photo.image.url,
            },
          })
        ),
      ],
      attachments: [
        ...(existingAttachments as TagAttachment[]),
        ...newAttachments.map(
          (attachment): TagAttachment => ({
            tagId: tag?.id,
            file: {
              id: attachment.file.id || uuid(),
              fileExtension: attachment.file.fileExtension || null,
              url: attachment.file.url,
            },
          })
        ),
      ],
    };
  },
  async ({ newPhotos, removePhotos = [], newAttachments, removeAttachments = [], ...newTagRequest }) => {
    const newRequest = {
      id: newTagRequest.id,
      tag: {
        ...newTagRequest.tag,
        images: removePhotos && removePhotos.length > 0 ? [] : newTagRequest.tag?.images,
        attachments: removeAttachments && removeAttachments.length > 0 ? [] : newTagRequest.tag?.attachments,
      },
    };

    const { data: tag } = await call(TechApi).apiV1TechProjectsIdTagsPut(newRequest);
    if (removePhotos && removePhotos.length > 0) {
      const deletions = await deleteImages({ removePhotos, tagRequestId: newTagRequest.id, tagId: tag?.id! });
      if (deletions.length > 0) {
        tag!.images = [];
      }
    }

    if (removeAttachments && removeAttachments.length > 0) {
      const deletions = await deleteAttachments({ removeAttachments, tagRequestId: newTagRequest.id, tagId: tag?.id! });
      if (deletions.length > 0) {
        tag!.images = [];
      }
    }

    let res = {
      ...tag!,
      users: newTagRequest.tag!.users
    };

    if (newPhotos.length > 0) {
      const images = await createImages({ newPhotos, tagRequestId: newTagRequest.id, tagId: tag?.id! });
      res = {
        ...res,
        images
      };
      // return {
      //   ...tag!,
      //   users: newTagRequest.tag!.users,
      //   images,
      // };
    } else {
      // return {
      //   ...tag!,
      //   users: newTagRequest.tag!.users,
      // };
    }

    console.log("Upsert Tag => newAttachments", newAttachments);
    if (newAttachments.length > 0) {
      const attachments = await createAttachments({ newAttachments, tagRequestId: newTagRequest.id, tagId: tag?.id! });
      res = {
        ...res,
        attachments
      };
    } else {
    }

    return res;
  }
);

const createImages = async ({ newPhotos, tagRequestId, tagId }: CreateImagesPost) => {
  const responses = await Promise.all(
    newPhotos.map((newPhotos) => {
      const newPhotoRequest: ApiV1TechProjectsIdTagsTagIdImagesPostRequest = {
        id: tagRequestId,
        tagId: tagId,
        image: dataURLtoFile(newPhotos.image.url!),
      };
      return call(TechApi).apiV1TechProjectsIdTagsTagIdImagesPost(newPhotoRequest);
    })
  );

  const images = responses.map((response) => response.data!);
  return images;
};

const createAttachments = async ({ newAttachments, tagRequestId, tagId }: CreateAttachmentsPost) => {
  const responses = await Promise.all(
    newAttachments.map((newAttachments) => {
      const newAttachmentRequest: ApiV1TechProjectsIdTagsTagIdAttachmentsPostRequest = {
        id: tagRequestId,
        tagId: tagId,
        file: dataURLtoFileWithFileName(newAttachments.file.url!, newAttachments.file.fileName!),
      };

      return call(TechApi).apiV1TechProjectsIdTagsTagIdAttachmentsPost(newAttachmentRequest);
    })
  );

  const attachments = responses.map((response) => response.data!);
  return attachments;
};

const deleteImages = async ({ removePhotos, tagRequestId, tagId }: DeleteImage) => {
  const responses = removePhotos.map(async (removedPhoto) => {
    const removedPhotoRequest: ApiV1TechProjectsIdTagsTagIdImagesImageIdDeleteRequest = {
      id: tagRequestId,
      tagId: tagId,
      imageId: removedPhoto.image.imageId!,
    };
    return await call(TechApi).apiV1TechProjectsIdTagsTagIdImagesImageIdDelete(removedPhotoRequest);
  });
  return Promise.all(responses);
};

const deleteAttachments = async ({ removeAttachments, tagRequestId, tagId }: DeleteAttachment) => {
  const responses = removeAttachments.map(async (removedAttachment) => {
    const removedAttachmentRequest: ApiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDeleteRequest = {
      id: tagRequestId,
      tagId: tagId,
      attachmentId: removedAttachment.file.id!,
    };
    return await call(TechApi).apiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDelete(removedAttachmentRequest);
  });
  return Promise.all(responses);
};

export const createIssue = createAsyncAction<ApiV1TechProjectsIdTagsTagIdIssuesPostRequestWithImages, TagIssue, Error>(
  "CREATE_ISSUE",
  ({ tagIssue, dataUrls }) => {
    return {
      ...tagIssue!,
      images: dataUrls.map((dataUrl: string) => ({
        issueId: tagIssue?.id!,
        image: {
          imageId: uuid(),
          fileExtension: null,
          url: dataUrl,
        },
      })),
    };
  },
  async ({ dataUrls, ...newIssueRequest }) => {
    const { data: issue } = await call(TechApi).apiV1TechProjectsIdTagsTagIdIssuesPost(newIssueRequest);

    if (dataUrls.length > 0) {
      const responses: IssueImageControllerResponse[] = await Promise.all(
        dataUrls.map((dataUrl: string) => {
          const newPhotoRequest: ApiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPostRequest = {
            id: newIssueRequest.id,
            tagId: issue?.tagId!,
            issueId: issue?.id!,
            image: dataURLtoFile(dataUrl),
          };
          return call(TechApi).apiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPost(newPhotoRequest);
        })
      );

      const images = responses.map((response) => response.data!);

      return {
        ...issue!,
        images,
      };
    } else {
      return issue!;
    }
  }
);
