/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Area, AreaFromJSON, AreaFromJSONTyped, AreaToJSON } from "./Area";
import { Asset, AssetFromJSON, AssetFromJSONTyped, AssetToJSON } from "./Asset";
import {
  PhaseCompletion,
  PhaseCompletionFromJSON,
  PhaseCompletionFromJSONTyped,
  PhaseCompletionToJSON,
} from "./PhaseCompletion";
import { TagImage, TagImageFromJSON, TagImageFromJSONTyped, TagImageToJSON } from "./TagImage";
import { TagAttachment, TagAttachmentFromJSON, TagAttachmentFromJSONTyped, TagAttachmentToJSON } from "./TagAttachment";
import { TagIssue, TagIssueFromJSON, TagIssueFromJSONTyped, TagIssueToJSON } from "./TagIssue";
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof Tag
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof Tag
   */
  updatedAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof Tag
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof Tag
   */
  latitude?: number | null;
  /**
   *
   * @type {number}
   * @memberof Tag
   */
  longitude?: number | null;
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  notes?: string | null;
  /**
   *
   * @type {number}
   * @memberof Tag
   */
  areaId?: number;
  /**
   *
   * @type {Area}
   * @memberof Tag
   */
  area?: Area;
  /**
   *
   * @type {string}
   * @memberof Tag
   */
  flexFields?: string | null;
  /**
   *
   * @type {Asset}
   * @memberof Tag
   */
  asset?: Asset;
  /**
   *
   * @type {Array<User>}
   * @memberof Tag
   */
  users?: Array<User> | null;
  /**
   *
   * @type {Array<PhaseCompletion>}
   * @memberof Tag
   */
  phaseCompletions?: Array<PhaseCompletion> | null;
  /**
   *
   * @type {Array<TagImage>}
   * @memberof Tag
   */
  images?: Array<TagImage> | null;
  /**
   *
   * @type {Array<TagIssue>}
   * @memberof Tag
   */
  attachments?: Array<TagAttachment> | null;
  /**
   *
   * @type {Array<TagIssue>}
   * @memberof Tag
   */
  issues?: Array<TagIssue> | null;
}

export function TagFromJSON(json: any): Tag {
  return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "createdAt") ? undefined : new Date(json["createdAt"]),
    updatedAt: !exists(json, "updatedAt") ? undefined : new Date(json["updatedAt"]),
    deleted: !exists(json, "deleted") ? undefined : json["deleted"],
    name: !exists(json, "name") ? undefined : json["name"],
    latitude: !exists(json, "latitude") ? undefined : json["latitude"],
    longitude: !exists(json, "longitude") ? undefined : json["longitude"],
    notes: !exists(json, "notes") ? undefined : json["notes"],
    areaId: !exists(json, "areaId") ? undefined : json["areaId"],
    area: !exists(json, "area") ? undefined : AreaFromJSON(json["area"]),
    flexFields: !exists(json, "flexFields") ? undefined : json["flexFields"],
    asset: !exists(json, "asset") ? undefined : AssetFromJSON(json["asset"]),
    users: !exists(json, "users")
      ? undefined
      : json["users"] === null
      ? null
      : (json["users"] as Array<any>).map(UserFromJSON),
    phaseCompletions: !exists(json, "phaseCompletions")
      ? undefined
      : json["phaseCompletions"] === null
      ? null
      : (json["phaseCompletions"] as Array<any>).map(PhaseCompletionFromJSON),
    images: !exists(json, "images")
      ? undefined
      : json["images"] === null
      ? null
      : (json["images"] as Array<any>).map(TagImageFromJSON),
    attachments: !exists(json, "attachments")
      ? undefined
      : json["attachments"] === null
      ? null
      : (json["attachments"] as Array<any>).map(TagAttachmentFromJSON),
    issues: !exists(json, "issues")
      ? undefined
      : json["issues"] === null
      ? null
      : (json["issues"] as Array<any>).map(TagIssueFromJSON),
  };
}

export function TagToJSON(value?: Tag | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updatedAt: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    deleted: value.deleted,
    name: value.name,
    latitude: value.latitude,
    longitude: value.longitude,
    notes: value.notes,
    areaId: value.areaId,
    area: AreaToJSON(value.area),
    flexFields: value.flexFields,
    asset: AssetToJSON(value.asset),
    users:
      value.users === undefined ? undefined : value.users === null ? null : (value.users as Array<any>).map(UserToJSON),
    phaseCompletions:
      value.phaseCompletions === undefined
        ? undefined
        : value.phaseCompletions === null
        ? null
        : (value.phaseCompletions as Array<any>).map(PhaseCompletionToJSON),
    images:
      value.images === undefined
        ? undefined
        : value.images === null
        ? null
        : (value.images as Array<any>).map(TagImageToJSON),
    attachments:
        value.attachments === undefined
          ? undefined
          : value.attachments === null
          ? null
          : (value.attachments as Array<any>).map(TagAttachmentToJSON),
    issues:
      value.issues === undefined
        ? undefined
        : value.issues === null
        ? null
        : (value.issues as Array<any>).map(TagIssueToJSON),
  };
}
