import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import { blue } from "styles/colors";

const useStyles = makeStyles((theme: Theme) => ({
  filledInfo: {
    color: theme.palette.common.white,
    backgroundColor: blue,
  },
}));

type Props = {
  onApplyUpdate: () => void;
};

const UpdateAvailable: React.FC<Props> = ({ onApplyUpdate }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  return (
    <Alert
      severity="info"
      variant="filled"
      classes={{
        filledInfo: classes.filledInfo,
      }}
      action={
        <Button color="inherit" size="small" onClick={onApplyUpdate}>
          {t("updateAvailable.update")}
        </Button>
      }
    >
      {t("updateAvailable.message")}
    </Alert>
  );
};

export default UpdateAvailable;
