/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Image, ImageFromJSON, ImageFromJSONTyped, ImageToJSON } from "./Image";

/**
 *
 * @export
 * @interface TagImage
 */
export interface TagImage {
  /**
   *
   * @type {string}
   * @memberof TagImage
   */
  tagId?: string;
  /**
   *
   * @type {Image}
   * @memberof TagImage
   */
  image?: Image;
}

export function TagImageFromJSON(json: any): TagImage {
  return TagImageFromJSONTyped(json, false);
}

export function TagImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tagId: !exists(json, "tagId") ? undefined : json["tagId"],
    image: !exists(json, "image") ? undefined : ImageFromJSON(json["image"]),
  };
}

export function TagImageToJSON(value?: TagImage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tagId: value.tagId,
    image: ImageToJSON(value.image),
  };
}
