import { User } from "data/api";
import { AnyAction } from "redux";

type FromCurrentUserState = {
  isLoadingUser: boolean;
  user: User | null;
  error: Error | null;
};

type FromTechnicianOutboxReducerState = {
  working: boolean;
  queue: AnyAction[];
  deadletter: AnyAction[];
};

type FromTechnicianAppState = {
  outbox: FromTechnicianOutboxReducerState;
};

type ToTechnicianOutboxReducerState = {
  currentUserId: number | null;
  byId: {
    [userId: number]: FromTechnicianOutboxReducerState;
  };
};

type ToTechnicianAppState = {
  outbox: ToTechnicianOutboxReducerState;
};

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
const migrate = (state: any): any => {
  const { technician: technicianReducerState, user: userReducerState } = state;
  const { user } = userReducerState as FromCurrentUserState;
  const { outbox } = technicianReducerState as FromTechnicianAppState;

  const newTechnicianState: ToTechnicianAppState = {
    ...technicianReducerState,
    outbox: {
      currentUserId: user ? user.id! : null,
      byId: user
        ? {
            [user.id!]: {
              ...outbox,
            },
          }
        : {},
    },
  };

  return {
    ...state,
    technician: newTechnicianState,
  };
};

export default migrate;
