/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ChecklistItemTemplate,
  ChecklistItemTemplateFromJSON,
  ChecklistItemTemplateFromJSONTyped,
  ChecklistItemTemplateToJSON,
} from "./ChecklistItemTemplate";

/**
 *
 * @export
 * @interface ChecklistSection
 */
export interface ChecklistSection {
  /**
   *
   * @type {number}
   * @memberof ChecklistSection
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChecklistSection
   */
  title?: string | null;
  /**
   *
   * @type {Array<ChecklistItemTemplate>}
   * @memberof ChecklistSection
   */
  items?: Array<ChecklistItemTemplate> | null;
}

export function ChecklistSectionFromJSON(json: any): ChecklistSection {
  return ChecklistSectionFromJSONTyped(json, false);
}

export function ChecklistSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChecklistSection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    title: !exists(json, "title") ? undefined : json["title"],
    items: !exists(json, "items")
      ? undefined
      : json["items"] === null
      ? null
      : (json["items"] as Array<any>).map(ChecklistItemTemplateFromJSON),
  };
}

export function ChecklistSectionToJSON(value?: ChecklistSection | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    items:
      value.items === undefined
        ? undefined
        : value.items === null
        ? null
        : (value.items as Array<any>).map(ChecklistItemTemplateToJSON),
  };
}
