import { InputBaseClassKey } from "@material-ui/core/InputBase";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const MuiInputBaseTheme: Partial<Record<InputBaseClassKey, CSSProperties>> = {
  root: {
    height: "2.5rem",
    borderRadius: 0,
    "&$disabled": {
      cursor: "not-allowed",
    },
  },
};

export { MuiInputBaseTheme };
