import { reducerWithInitialState } from "typescript-fsa-reducers";
import { TechnicianOutboxReducerState } from "./types";
import { dequeue, postSyncErrors } from "./actions";
import { upsertTag, createIssue } from "data/technician/tags/actions";
import { completeChecklists } from "data/technician/checklists/actions";
import { getCurrentUserOutboxOrDefault, getCurrentUserOutbox } from "./selectors";
import { getCurrentUser } from "data/user/actions";

const initialState: TechnicianOutboxReducerState = {
  currentUserId: null,
  byId: {},
};

const enqueue = <T>(state: TechnicianOutboxReducerState, type: string, params: T) => {
  if (!state.currentUserId) {
    return state;
  }

  const byId = getCurrentUserOutboxOrDefault(state);
  return {
    ...state,
    byId: {
      ...state.byId,
      [state.currentUserId]: {
        ...byId,
        queue: [...byId.queue, { type, payload: params }],
      },
    },
  };
};

const reducer = reducerWithInitialState(initialState)
  .case(getCurrentUser.async.done, (state, { result: user }) => {
    return {
      ...state,
      currentUserId: user.id!,
    };
  })
  .case(upsertTag.async.optimistic, (state, { params }) => enqueue(state, upsertTag.type, params))
  .case(createIssue.async.optimistic, (state, { params }) => enqueue(state, createIssue.type, params))
  .case(completeChecklists.async.optimistic, (state, { params }) => enqueue(state, completeChecklists.type, params))
  .case(dequeue.async.started, (state) => {
    const byId = getCurrentUserOutbox(state);
    if (!state.currentUserId || !byId) {
      return state;
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        [state.currentUserId]: {
          ...byId,
          working: true,
        },
      },
    };
  })
  .case(dequeue.async.done, (state) => {
    const byId = getCurrentUserOutbox(state);
    if (!state.currentUserId || !byId) {
      return state;
    }
    return {
      ...state,
      byId: {
        ...state.byId,
        [state.currentUserId]: {
          ...byId,
          working: false,
          queue: byId.queue.slice(1),
        },
      },
    };
  })
  .case(dequeue.async.failed, (state) => {
    const byId = getCurrentUserOutbox(state);
    if (!state.currentUserId || !byId) {
      return state;
    }

    const action = byId.queue[0];
    return {
      ...state,
      byId: {
        ...state.byId,
        [state.currentUserId]: {
          ...byId,
          working: false,
          queue: byId.queue.slice(1),
          deadletter: action ? [...byId.deadletter, action] : byId.deadletter,
        },
      },
    };
  })
  .case(postSyncErrors.async.started, (state) => ({
    ...state,
    byId: {
      ...state.byId,
      [state.currentUserId!]: {
        ...state.byId[state.currentUserId!],
        isRemovingDeadLetters: true,
      },
    },
  }))
  .case(postSyncErrors.async.failed, (state, { error }) => ({
    ...state,
    byId: {
      ...state.byId,
      [state.currentUserId!]: {
        ...state.byId[state.currentUserId!],
        isRemovingDeadLetters: false,
      },
    },
    error,
  }))
  .case(postSyncErrors.async.done, (state, { result: { success, deadletter } }) => {
    if (!success) return state;
    const byId = getCurrentUserOutbox(state);
    if (!state.currentUserId || !byId) {
      return state;
    }
    const newDeadLetters = byId.deadletter.splice(0, deadletter.length);
    return {
      ...state,
      byId: {
        ...state.byId,
        [state.currentUserId!]: {
          ...byId,
          deadletter: newDeadLetters,
          isRemovingDeadLetters: false,
        },
      },
    };
  });

export default reducer;
