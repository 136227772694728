/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import { ExclusionList, ExclusionListFromJSON, ExclusionListFromJSONTyped, ExclusionListToJSON } from "./ExclusionList";

/**
 *
 * @export
 * @interface ExclusionListControllerResponse
 */
export interface ExclusionListControllerResponse {
  /**
   *
   * @type {ExclusionList}
   * @memberof ExclusionListControllerResponse
   */
  data?: ExclusionList;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ExclusionListControllerResponse
   */
  error?: ErrorResponse;
}

export function ExclusionListControllerResponseFromJSON(json: any): ExclusionListControllerResponse {
  return ExclusionListControllerResponseFromJSONTyped(json, false);
}

export function ExclusionListControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExclusionListControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : ExclusionListFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ExclusionListControllerResponseToJSON(value?: ExclusionListControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ExclusionListToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
