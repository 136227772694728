import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { ListItemClassKey } from "@material-ui/core/ListItem";
import { blueLight } from "styles/colors";

const MuiListItemTheme: Partial<Record<ListItemClassKey, CSSProperties>> = {
  root: {
    cursor: "pointer",
    "&$selected": {
      backgroundColor: blueLight,
      borderRadius: "0.625rem",
      "&:hover": {
        backgroundColor: blueLight,
      },
    },
  },
};

export { MuiListItemTheme };
