/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FlexField
 */
export interface FlexField {
  /**
   *
   * @type {string}
   * @memberof FlexField
   */
  name?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof FlexField
   */
  editable?: boolean;
}

export function FlexFieldFromJSON(json: any): FlexField {
  return FlexFieldFromJSONTyped(json, false);
}

export function FlexFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlexField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    editable: !exists(json, "editable") ? undefined : json["editable"],
  };
}

export function FlexFieldToJSON(value?: FlexField | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    editable: value.editable,
  };
}
