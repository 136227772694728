/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  TagHistoryEvent,
  TagHistoryEventFromJSON,
  TagHistoryEventFromJSONTyped,
  TagHistoryEventToJSON,
} from "./TagHistoryEvent";

/**
 *
 * @export
 * @interface TagHistory
 */
export interface TagHistory {
  /**
   *
   * @type {number}
   * @memberof TagHistory
   */
  phaseId?: number | null;
  /**
   *
   * @type {string}
   * @memberof TagHistory
   */
  performedBy?: string | null;
  /**
   *
   * @type {Date}
   * @memberof TagHistory
   */
  date?: Date;
  /**
   *
   * @type {TagHistoryEvent}
   * @memberof TagHistory
   */
  event?: TagHistoryEvent;
}

export function TagHistoryFromJSON(json: any): TagHistory {
  return TagHistoryFromJSONTyped(json, false);
}

export function TagHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagHistory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    phaseId: !exists(json, "phaseId") ? undefined : json["phaseId"],
    performedBy: !exists(json, "performedBy") ? undefined : json["performedBy"],
    date: !exists(json, "date") ? undefined : new Date(json["date"]),
    event: !exists(json, "event") ? undefined : TagHistoryEventFromJSON(json["event"]),
  };
}

export function TagHistoryToJSON(value?: TagHistory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    phaseId: value.phaseId,
    performedBy: value.performedBy,
    date: value.date === undefined ? undefined : value.date.toISOString(),
    event: TagHistoryEventToJSON(value.event),
  };
}
