import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { AppState } from "store/types";
import { TechApi } from "data/api";
import { call } from "data/api/rest";
import { ClientSideProject } from "./types";

const createAction = actionCreatorFactory("TECH/PROJECTS");
const createAsyncAction = asyncFactory<AppState>(createAction);

export const getTechnicianProjects = createAsyncAction<void, ClientSideProject[], Error>("GET_PROJECTS", async () => {
  const { data } = await call(TechApi).apiV1TechProjectsGet();
  return data || [];
});

export const getTechnicianProjectById = createAsyncAction<number, ClientSideProject, Error>(
  "GET_PROJECT",
  async (projectId: number) => {
    const { data } = await call(TechApi).apiV1TechProjectsIdGet({ id: projectId });
    console.log("getTechnicianProjectById",data);
    if (!data) throw new Error();
    return data;
  }
);

export const refreshTechnicianProjectData = createAsyncAction<void, void>(
  "REFRESH_PROJECTS",
  async (params, dispatch) => {
    const projects = await dispatch(getTechnicianProjects());
    await Promise.all(projects.map((project) => dispatch(getTechnicianProjectById(project.id!))));
  }
);

export const selectTechnicianProjectById = createAction<number>("SELECT_PROJECT");

export const updateDownloadedProjects = createAsyncAction<void, void, Error>(
  "UPDATE_DOWNLOADED_PROJECTS",
  async (_, dispatch, getState) => {
    const state = getState();
    const projects = Object.values(state.technician.projects.byId);
    await Promise.all(
      projects
        .map((project) => project as ClientSideProject)
        .filter((project) => project.hasDownloaded)
        .map((project) => dispatch(getTechnicianProjectById(project.id)))
    );
  }
);
