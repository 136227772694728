import { createMuiTheme } from "@material-ui/core/styles";
import { black, white, magenta, gray, red, green, textPrimary, redLight } from "./colors";
import "./fonts/index.css";
import { MuiTableBodyTheme, MuiTableCellTheme, MuiTableRowTheme, MuiTableTheme } from "./MuiComponentDefaults/MuiTable";
import { MuiSvgIconProps, MuiSvgIconTheme } from "./MuiComponentDefaults/MuiSvgIcon";
import { MuiInputBaseTheme } from "./MuiComponentDefaults/MuiInputBase";
import { MuiOutlinedInputTheme } from "./MuiComponentDefaults/MuiOutlinedInput";
import { MuiButtonTheme, MuiButtonProps } from "./MuiComponentDefaults/MuiButton";
import { MuiListItemTheme } from "./MuiComponentDefaults/MuiListItem";
import { MuiMenuItemTheme } from "./MuiComponentDefaults/MuiMenuItem";
import { MuiCheckboxTheme, MuiCheckboxProps } from "./MuiComponentDefaults/MuiCheckbox";
import { MuiSelectTheme, MuiSelectProps } from "./MuiComponentDefaults/MuiSelect";
import { MuiInputLabelTheme, MuiInputLabelProps } from "./MuiComponentDefaults/MuiInputLabel";
import { MuiInputTheme } from "./MuiComponentDefaults/MuiInput";
import { MuiTabsTheme, MuiTabTheme } from "./MuiComponentDefaults/MuiTabs";
import {
  MuiExpansionPanelDetailsTheme,
  MuiExpansionPanelSummaryTheme,
  MuiExpansionPanelTheme,
} from "./MuiComponentDefaults/MuiExpansionPanel";
import { MuiTypographyTheme } from "./MuiComponentDefaults/MuiTypography";
import { fontFamily } from "./fonts";
import { MuiTooltipTheme } from "./MuiComponentDefaults/MuiTooltip";

const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: fontFamily,
    h1: {
      fontSize: "2.25rem",
      lineHeight: "2.8125rem",
      fontWeight: "normal",
    },
    h2: {
      fontSize: "2.125rem",
      lineHeight: "2.625rem",
      fontWeight: "normal",
    },
    h3: {
      fontSize: "1.875rem",
      lineHeight: "2.3125rem",
      fontWeight: "normal",
    },
    body1: {
      fontSize: "1.25rem",
      lineHeight: "1.5625rem",
      fontWeight: "normal",
    },
    body2: {
      fontSize: "1rem",
      lineHeight: "118%",
      fontWeight: "normal",
    },
  },
  palette: {
    common: {
      black,
      white,
    },
    primary: {
      main: magenta,
    },
    secondary: {
      main: gray,
    },
    text: {
      primary: textPrimary,
    },
    error: {
      main: red,
      light: redLight,
    },
    action: {
      active: green,
    },
    background: {
      default: white,
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 2,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    MuiButton: MuiButtonTheme,
    MuiTable: MuiTableTheme,
    MuiTableBody: MuiTableBodyTheme,
    MuiTableRow: MuiTableRowTheme,
    MuiTableCell: MuiTableCellTheme,
    MuiSvgIcon: MuiSvgIconTheme,
    MuiInputBase: MuiInputBaseTheme,
    MuiOutlinedInput: MuiOutlinedInputTheme,
    MuiListItem: MuiListItemTheme,
    MuiMenuItem: MuiMenuItemTheme,
    MuiCheckbox: MuiCheckboxTheme,
    MuiSelect: MuiSelectTheme,
    MuiInputLabel: MuiInputLabelTheme,
    MuiInput: MuiInputTheme,
    MuiTab: MuiTabTheme,
    MuiTabs: MuiTabsTheme,
    MuiExpansionPanel: MuiExpansionPanelTheme,
    MuiExpansionPanelSummary: MuiExpansionPanelSummaryTheme,
    MuiExpansionPanelDetails: MuiExpansionPanelDetailsTheme,
    MuiTypography: MuiTypographyTheme,
    MuiTooltip: MuiTooltipTheme,
  },
  props: {
    MuiSvgIcon: MuiSvgIconProps,
    MuiButton: MuiButtonProps,
    MuiCheckbox: MuiCheckboxProps,
    MuiSelect: MuiSelectProps,
    MuiInputLabel: MuiInputLabelProps,
  },
});

export default defaultTheme;
