import { TabClassKey } from "@material-ui/core/Tab";
import { TabsClassKey } from "@material-ui/core/Tabs";
import { black, grayLight, blue, white } from "../colors";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export const MuiTabsTheme: Partial<Record<TabsClassKey, CSSProperties>> = {
  root: {
    borderBottom: `1px solid ${grayLight}`,
    color: black,
    backgroundColor: white,
  },
  indicator: {
    backgroundColor: blue,
  },
  flexContainer: {
    height: "100%",
  },
};

export const MuiTabTheme: Partial<Record<TabClassKey, CSSProperties>> = {
  root: {
    minWidth: "10rem",
    padding: "1rem 0.75rem",
    textTransform: "uppercase",
    "&:hover": {
      color: blue,
      opacity: 1,
    },
    "&$selected": {
      color: blue,
    },
    "&:focus": {
      color: blue,
    },
  },
  textColorInherit: {
    opacity: 1,
    fontSize: "1rem",
  },
};
