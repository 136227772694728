/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const TagHistoryEvent = {
  Created: "Created",
  Saved: "Saved",
  Completed: "Completed",
  Rejected: "Rejected",
} as const;
export type TagHistoryEvent = typeof TagHistoryEvent[keyof typeof TagHistoryEvent];

export function TagHistoryEventFromJSON(json: any): TagHistoryEvent {
  return TagHistoryEventFromJSONTyped(json, false);
}

export function TagHistoryEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagHistoryEvent {
  return json as TagHistoryEvent;
}

export function TagHistoryEventToJSON(value?: TagHistoryEvent | null): any {
  return value as any;
}
