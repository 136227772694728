/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { UserRole, UserRoleFromJSON, UserRoleFromJSONTyped, UserRoleToJSON } from "./UserRole";

/**
 *
 * @export
 * @interface UserMultipleRoles
 */
export interface UserMultipleRoles {
  /**
   *
   * @type {number}
   * @memberof UserMultipleRoles
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UserMultipleRoles
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserMultipleRoles
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserMultipleRoles
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof UserMultipleRoles
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {Array<UserRole>}
   * @memberof UserMultipleRoles
   */
  roles?: Array<UserRole> | null;
  /**
   *
   * @type {boolean}
   * @memberof UserMultipleRoles
   */
  isAdmin?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserMultipleRoles
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserMultipleRoles
   */
  isRegistered?: boolean;
}

export function UserMultipleRolesFromJSON(json: any): UserMultipleRoles {
  return UserMultipleRolesFromJSONTyped(json, false);
}

export function UserMultipleRolesFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMultipleRoles {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    email: !exists(json, "email") ? undefined : json["email"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    roles: !exists(json, "roles")
      ? undefined
      : json["roles"] === null
      ? null
      : (json["roles"] as Array<any>).map(UserRoleFromJSON),
    isAdmin: !exists(json, "isAdmin") ? undefined : json["isAdmin"],
    isActive: !exists(json, "isActive") ? undefined : json["isActive"],
    isRegistered: !exists(json, "isRegistered") ? undefined : json["isRegistered"],
  };
}

export function UserMultipleRolesToJSON(value?: UserMultipleRoles | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    phoneNumber: value.phoneNumber,
    roles:
      value.roles === undefined
        ? undefined
        : value.roles === null
        ? null
        : (value.roles as Array<any>).map(UserRoleToJSON),
    isAdmin: value.isAdmin,
    isActive: value.isActive,
    isRegistered: value.isRegistered,
  };
}
