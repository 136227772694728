/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ArchiveUserRequest,
  ArchiveUserRequestFromJSON,
  ArchiveUserRequestToJSON,
  BooleanControllerResponse,
  BooleanControllerResponseFromJSON,
  BooleanControllerResponseToJSON,
  ChecklistControllerResponse,
  ChecklistControllerResponseFromJSON,
  ChecklistControllerResponseToJSON,
  ControllerResponse,
  ControllerResponseFromJSON,
  ControllerResponseToJSON,
  ProblemDetails,
  ProblemDetailsFromJSON,
  ProblemDetailsToJSON,
  ProjectListControllerResponse,
  ProjectListControllerResponseFromJSON,
  ProjectListControllerResponseToJSON,
  UpdateUserRequest,
  UpdateUserRequestFromJSON,
  UpdateUserRequestToJSON,
  User,
  UserFromJSON,
  UserToJSON,
  UserControllerResponse,
  UserControllerResponseFromJSON,
  UserControllerResponseToJSON,
  UserMultipleRolesListControllerResponse,
  UserMultipleRolesListControllerResponseFromJSON,
  UserMultipleRolesListControllerResponseToJSON,
} from "../models";

export interface ApiV1UsersAdminsPostRequest {
  user?: User;
}

export interface ApiV1UsersChangepassPatchRequest {
  password?: string;
}

export interface ApiV1UsersForgotpassPatchRequest {
  email?: string;
}

export interface ApiV1UsersIdArchivedPostRequest {
  id: number;
  archiveUserRequest?: ArchiveUserRequest;
}

export interface ApiV1UsersIdPutRequest {
  id: number;
  updateUserRequest?: UpdateUserRequest;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   */
  async apiV1UsersAdminsPostRaw(
    requestParameters: ApiV1UsersAdminsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Users/admins`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: UserToJSON(requestParameters.user),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersAdminsPost(
    requestParameters: ApiV1UsersAdminsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserControllerResponse> {
    const response = await this.apiV1UsersAdminsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersAllGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserMultipleRolesListControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Users/all`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserMultipleRolesListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1UsersAllGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserMultipleRolesListControllerResponse> {
    const response = await this.apiV1UsersAllGetRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersChangepassPatchRaw(
    requestParameters: ApiV1UsersChangepassPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.password !== undefined) {
      queryParameters["password"] = requestParameters.password;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Users/changepass`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersChangepassPatch(
    requestParameters: ApiV1UsersChangepassPatchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1UsersChangepassPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersChecklistsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChecklistControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Users/checklists`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChecklistControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersChecklistsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChecklistControllerResponse> {
    const response = await this.apiV1UsersChecklistsGetRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersForgotpassPatchRaw(
    requestParameters: ApiV1UsersForgotpassPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.email !== undefined) {
      queryParameters["email"] = requestParameters.email;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Users/forgotpass`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersForgotpassPatch(
    requestParameters: ApiV1UsersForgotpassPatchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1UsersForgotpassPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Users`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersGet(initOverrides?: RequestInit | runtime.InitOverideFunction): Promise<UserControllerResponse> {
    const response = await this.apiV1UsersGetRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersIdArchivedPostRaw(
    requestParameters: ApiV1UsersIdArchivedPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1UsersIdArchivedPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Users/{id}/archived`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ArchiveUserRequestToJSON(requestParameters.archiveUserRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersIdArchivedPost(
    requestParameters: ApiV1UsersIdArchivedPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserControllerResponse> {
    const response = await this.apiV1UsersIdArchivedPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersIdPutRaw(
    requestParameters: ApiV1UsersIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1UsersIdPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUserRequestToJSON(requestParameters.updateUserRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersIdPut(
    requestParameters: ApiV1UsersIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserControllerResponse> {
    const response = await this.apiV1UsersIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1UsersProjectsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ProjectListControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Users/projects`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1UsersProjectsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ProjectListControllerResponse> {
    const response = await this.apiV1UsersProjectsGetRaw(initOverrides);
    return await response.value();
  }
}
