/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ChecklistToCopy,
  ChecklistToCopyFromJSON,
  ChecklistToCopyFromJSONTyped,
  ChecklistToCopyToJSON,
} from "./ChecklistToCopy";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";

/**
 *
 * @export
 * @interface ChecklistToCopyListControllerResponse
 */
export interface ChecklistToCopyListControllerResponse {
  /**
   *
   * @type {Array<ChecklistToCopy>}
   * @memberof ChecklistToCopyListControllerResponse
   */
  data?: Array<ChecklistToCopy> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ChecklistToCopyListControllerResponse
   */
  error?: ErrorResponse;
}

export function ChecklistToCopyListControllerResponseFromJSON(json: any): ChecklistToCopyListControllerResponse {
  return ChecklistToCopyListControllerResponseFromJSONTyped(json, false);
}

export function ChecklistToCopyListControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChecklistToCopyListControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(ChecklistToCopyFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ChecklistToCopyListControllerResponseToJSON(value?: ChecklistToCopyListControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(ChecklistToCopyToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
