import { combineReducers } from "redux";
import { TechnicianAppState } from "./types";
import projectsReducer from "./projects/reducer";
import checklistsReducer from "./checklists/reducer";
import TagsReducer from "./tags/reducer";
import outboxReducer from "./outbox/reducer";

const reducer = combineReducers<TechnicianAppState>({
  projects: projectsReducer,
  checklists: checklistsReducer,
  tags: TagsReducer,
  outbox: outboxReducer,
});

export default reducer;
