/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Attachment, AttachmentFromJSON, AttachmentFromJSONTyped, AttachmentToJSON } from "./Attachment";

/**
 *
 * @export
 * @interface TagAttachment
 */
export interface TagAttachment {
  /**
   *
   * @type {string}
   * @memberof TagAttachment
   */
  tagId?: string;
  /**
   *
   * @type {Attachment}
   * @memberof TagAttachment
   */
  file?: Attachment;
}

export function TagAttachmentFromJSON(json: any): TagAttachment {
  return TagAttachmentFromJSONTyped(json, false);
}

export function TagAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagAttachment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tagId: !exists(json, "tagId") ? undefined : json["tagId"],
    file: !exists(json, "file") ? undefined : AttachmentFromJSON(json["file"]),
  };
}

export function TagAttachmentToJSON(value?: TagAttachment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tagId: value.tagId,
    image: AttachmentToJSON(value.file),
  };
}
