import { TooltipClassKey } from "@material-ui/core/Tooltip";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { black, white } from "../colors";

export const MuiTooltipTheme: Partial<Record<TooltipClassKey, CSSProperties>> = {
  tooltip: {
    backgroundColor: black,
    color: white,
    borderRadius: "none",
  },
  popper: {
    left: "2rem !important",
  },
};
