import { fade } from "@material-ui/core/styles/colorManipulator";

export const black = "#000000";
export const textPrimary = "#333333";
export const white = "#ffffff";
export const grayLight = "#E6ECF0";
export const grayLight2 = "#FAFAFA";
export const grayLight3 = "#808080";
export const grayLightHover = fade(black, 0.08);
export const baliHaiGray = "#8FA2AC";
export const red = "#D50C2F";
export const redLight = "#fbe7ea";
export const green = "#54A931";
export const blue = "#00AEEF";
export const blueLight = "#cceffc";
export const blueLight2 = "#C2ECFB";
export const darkBlue = "#235877";
export const magenta = "#A8005C";
export const gray = "#8FA2AC";
export const lime = "#E4E81F";
