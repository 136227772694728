import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/types";
import { SnackbarProps } from "@material-ui/core/Snackbar";
import { removeSnackbar, pushSnackbar } from "./actions";
import { SnackbarReducerState, SnackbarActions } from "./types";

export const useSnackbarState = () => {
  return useSelector((state: AppState) => state.snackbar);
};

export const useSnackbarActions = () => {
  const dispatch = useDispatch();
  return {
    remove: (snackbar: Partial<SnackbarProps>) => dispatch(removeSnackbar(snackbar)),
    push: (snackbar: Partial<SnackbarProps>) => dispatch(pushSnackbar(snackbar)),
    pushMessage: (message: string, options?: Partial<SnackbarProps>) => dispatch(pushSnackbar({ message, ...options })),
  };
};

export const useSnackbar = (): [SnackbarReducerState, SnackbarActions] => {
  return [useSnackbarState(), useSnackbarActions()];
};
