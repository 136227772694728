/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";

/**
 *
 * @export
 * @interface ControllerResponse
 */
export interface ControllerResponse {
  /**
   *
   * @type {string}
   * @memberof ControllerResponse
   */
  data?: string | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ControllerResponse
   */
  error?: ErrorResponse;
}

export function ControllerResponseFromJSON(json: any): ControllerResponse {
  return ControllerResponseFromJSONTyped(json, false);
}

export function ControllerResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : json["data"],
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ControllerResponseToJSON(value?: ControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data,
    error: ErrorResponseToJSON(value.error),
  };
}
