/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChecklistItemDetail
 */
export interface ChecklistItemDetail {
  /**
   *
   * @type {string}
   * @memberof ChecklistItemDetail
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof ChecklistItemDetail
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof ChecklistItemDetail
   */
  updatedAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItemDetail
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItemDetail
   */
  isComplete?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChecklistItemDetail
   */
  text?: string | null;
  /**
   *
   * @type {number}
   * @memberof ChecklistItemDetail
   */
  lastUpdatedBy?: number;
  /**
   *
   * @type {number}
   * @memberof ChecklistItemDetail
   */
  checklistItemTemplateId?: number;
}

export function ChecklistItemDetailFromJSON(json: any): ChecklistItemDetail {
  return ChecklistItemDetailFromJSONTyped(json, false);
}

export function ChecklistItemDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChecklistItemDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "createdAt") ? undefined : new Date(json["createdAt"]),
    updatedAt: !exists(json, "updatedAt") ? undefined : new Date(json["updatedAt"]),
    deleted: !exists(json, "deleted") ? undefined : json["deleted"],
    isComplete: !exists(json, "isComplete") ? undefined : json["isComplete"],
    text: !exists(json, "text") ? undefined : json["text"],
    lastUpdatedBy: !exists(json, "lastUpdatedBy") ? undefined : json["lastUpdatedBy"],
    checklistItemTemplateId: !exists(json, "checklistItemTemplateId") ? undefined : json["checklistItemTemplateId"],
  };
}

export function ChecklistItemDetailToJSON(value?: ChecklistItemDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updatedAt: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    deleted: value.deleted,
    isComplete: value.isComplete,
    text: value.text,
    lastUpdatedBy: value.lastUpdatedBy,
    checklistItemTemplateId: value.checklistItemTemplateId,
  };
}
