export const dataURLtoFile = (dataurl: string): Blob => {
  const [schemaMediaTypeExtension, base64] = dataurl.split(",");
  const [, mediaTypeExtension] = schemaMediaTypeExtension.split(":");
  const [mediaType] = mediaTypeExtension.split(";");
  return new File(base64toByteArray(base64), filenameForMediaType(mediaType), { type: mediaType });;
};

export const dataURLtoFileWithFileName = (dataurl: string, fileName: string): Blob => {
  const [schemaMediaTypeExtension, base64] = dataurl.split(",");
  const [, mediaTypeExtension] = schemaMediaTypeExtension.split(":");
  const [mediaType] = mediaTypeExtension.split(";");
  return new File(base64toByteArray(base64), fileName, { type: mediaType });;
};

const filenameForMediaType = (mediaType: string) => {
  switch (mediaType) {
    case "image/jpeg":
      return "image.jpg";
    case "image/png":
      return "image.png";
    default:
      throw new Error("Unsupported Media Type");
  }
};

const base64toByteArray = (base64: string, sliceSize = 512) => {
  const byteCharacters = atob(base64);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return byteArrays;
};

export const fileToDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        resolve(e.target?.result as string);
      } else {
        reject();
      }
    };
    reader.onerror = () => reject();
    reader.readAsDataURL(file);
  });
};
