/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Attachment
 */
export interface Attachment {
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  fileExtension?: string | null;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  fileName?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Attachment
   */
  createdDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Attachment
   */
  url?: string | null;
}

export function AttachmentFromJSON(json: any): Attachment {
  return AttachmentFromJSONTyped(json, false);
}

export function AttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Attachment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    fileName: !exists(json, "fileName") ? undefined : json["fileName"],
    fileExtension: !exists(json, "fileExtension") ? undefined : json["fileExtension"],
    createdDate: !exists(json, "createdDate") ? undefined : new Date(json["createdDate"]),
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function AttachmentToJSON(value?: Attachment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    fileName: value.fileName,
    fileExtension: value.fileExtension,
    createdDate: value.createdDate === undefined ? undefined : value.createdDate.toISOString(),
    url: value.url,
  };
}
