import React from "react";
import ErrorBoundary from "react-error-boundary";

import GlobalSnackbar from "components/GlobalSnackbar";
import UpdateAvailable from "components/UpdateAvailable";
import { useAuth } from "data/auth/hooks";
import { useServiceWorker } from "pwa";
import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";

const UnhandledError: React.FC = () => {
  return <h1>{"Something went wrong."}</h1>;
};

const App: React.FC = () => {
  const [{ isAuthenticated }] = useAuth();
  const { isUpdateAvailable, onApplyUpdate } = useServiceWorker();
  return (
    <ErrorBoundary FallbackComponent={UnhandledError}>
      {isUpdateAvailable && <UpdateAvailable onApplyUpdate={onApplyUpdate} />}
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <GlobalSnackbar />
    </ErrorBoundary>
  );
};

export default App;
