/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ChecklistToCopy
 */
export interface ChecklistToCopy {
  /**
   *
   * @type {number}
   * @memberof ChecklistToCopy
   */
  checklistId?: number;
  /**
   *
   * @type {number}
   * @memberof ChecklistToCopy
   */
  projectId?: number;
  /**
   *
   * @type {string}
   * @memberof ChecklistToCopy
   */
  checklistName?: string | null;
  /**
   *
   * @type {Date}
   * @memberof ChecklistToCopy
   */
  createdDate?: Date | null;
}

export function ChecklistToCopyFromJSON(json: any): ChecklistToCopy {
  return ChecklistToCopyFromJSONTyped(json, false);
}

export function ChecklistToCopyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChecklistToCopy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    checklistId: !exists(json, "checklistId") ? undefined : json["checklistId"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    checklistName: !exists(json, "checklistName") ? undefined : json["checklistName"],
    createdDate: !exists(json, "createdDate")
      ? undefined
      : json["createdDate"] === null
      ? null
      : new Date(json["createdDate"]),
  };
}

export function ChecklistToCopyToJSON(value?: ChecklistToCopy | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    checklistId: value.checklistId,
    projectId: value.projectId,
    checklistName: value.checklistName,
    createdDate:
      value.createdDate === undefined ? undefined : value.createdDate === null ? null : value.createdDate.toISOString(),
  };
}
