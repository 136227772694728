/* eslint-disable @typescript-eslint/no-explicit-any */
const migrate = (state: any): any => {
  return {
    ...state,
    technician: {
      ...state?.technician,
      outbox: {
        ...state.technician.outbox,
        deadletter: [],
      },
    },
  };
};

export default migrate;
