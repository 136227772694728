/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { TagHistory, TagHistoryFromJSON, TagHistoryFromJSONTyped, TagHistoryToJSON } from "./TagHistory";
import { TagImage, TagImageFromJSON, TagImageFromJSONTyped, TagImageToJSON } from "./TagImage";
import { TagAttachment, TagAttachmentFromJSON, TagAttachmentFromJSONTyped, TagAttachmentToJSON } from "./TagAttachment";
import { TagIssue, TagIssueFromJSON, TagIssueFromJSONTyped, TagIssueToJSON } from "./TagIssue";
import {
  TagPhaseDetail,
  TagPhaseDetailFromJSON,
  TagPhaseDetailFromJSONTyped,
  TagPhaseDetailToJSON,
} from "./TagPhaseDetail";

/**
 *
 * @export
 * @interface TagCompletionDetail
 */
export interface TagCompletionDetail {
  /**
   *
   * @type {string}
   * @memberof TagCompletionDetail
   */
  id?: string;
  /**
   *
   * @type {Array<TagIssue>}
   * @memberof TagCompletionDetail
   */
  issues?: Array<TagIssue> | null;
  /**
   *
   * @type {Array<TagIssue>}
   * @memberof TagCompletionDetail
   */
  images?: Array<TagImage> | null;
  /**
   *
   * @type {Array<TagImage>}
   * @memberof TagCompletionDetail
   */
  attachments?: Array<TagAttachment> | null;
  /**
   *
   * @type {Array<TagAttachment>}
   * @memberof TagCompletionDetail
   */
  phases?: Array<TagPhaseDetail> | null;
  /**
   *
   * @type {Array<TagHistory>}
   * @memberof TagCompletionDetail
   */
  history?: Array<TagHistory> | null;
}

export function TagCompletionDetailFromJSON(json: any): TagCompletionDetail {
  return TagCompletionDetailFromJSONTyped(json, false);
}

export function TagCompletionDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagCompletionDetail {
  if (json === undefined || json === null) {
    return json;
  }

  console.log("json",json);

  let res = {
    id: !exists(json, "id") ? undefined : json["id"],
    issues: !exists(json, "issues")
      ? undefined
      : json["issues"] === null
      ? null
      : (json["issues"] as Array<any>).map(TagIssueFromJSON),
    images: !exists(json, "images")
      ? undefined
      : json["images"] === null
      ? null
      : (json["images"] as Array<any>).map(TagImageFromJSON),
    attachments: !exists(json, "attachments")
      ? undefined
      : json["attachments"] === null
      ? null
      : (json["attachments"] as Array<any>).map(TagAttachmentFromJSON),
    phases: !exists(json, "phases")
      ? undefined
      : json["phases"] === null
      ? null
      : (json["phases"] as Array<any>).map(TagPhaseDetailFromJSON),
    history: !exists(json, "history")
      ? undefined
      : json["history"] === null
      ? null
      : (json["history"] as Array<any>).map(TagHistoryFromJSON),
  };

  console.log(res);
  return res;
}

export function TagCompletionDetailToJSON(value?: TagCompletionDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    issues:
      value.issues === undefined
        ? undefined
        : value.issues === null
        ? null
        : (value.issues as Array<any>).map(TagIssueToJSON),
    images:
      value.images === undefined
        ? undefined
        : value.images === null
        ? null
        : (value.images as Array<any>).map(TagImageToJSON),
    phases:
      value.phases === undefined
        ? undefined
        : value.phases === null
        ? null
        : (value.phases as Array<any>).map(TagPhaseDetailToJSON),
    history:
      value.history === undefined
        ? undefined
        : value.history === null
        ? null
        : (value.history as Array<any>).map(TagHistoryToJSON),
  };
}
