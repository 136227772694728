import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { MenuItemClassKey } from "@material-ui/core/MenuItem";
import { white, grayLightHover } from "styles/colors";

const MuiMenuItemTheme: Partial<Record<MenuItemClassKey, CSSProperties>> = {
  root: {
    cursor: "pointer",
    "&$selected": {
      backgroundColor: white,
      borderRadius: 0,
      "&:hover": {
        backgroundColor: grayLightHover,
      },
    },
  },
};

export { MuiMenuItemTheme };
