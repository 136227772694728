/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ArchiveProjectUserRequest,
  ArchiveProjectUserRequestFromJSON,
  ArchiveProjectUserRequestToJSON,
  Area,
  AreaFromJSON,
  AreaToJSON,
  AreaControllerResponse,
  AreaControllerResponseFromJSON,
  AreaControllerResponseToJSON,
  AreaListControllerResponse,
  AreaListControllerResponseFromJSON,
  AreaListControllerResponseToJSON,
  AssetTypeListControllerResponse,
  AssetTypeListControllerResponseFromJSON,
  AssetTypeListControllerResponseToJSON,
  BooleanControllerResponse,
  BooleanControllerResponseFromJSON,
  BooleanControllerResponseToJSON,
  ProjectAreaAttachmentListControllerResponseFromJSON,
  Checklist,
  ChecklistFromJSON,
  ChecklistToJSON,
  ChecklistControllerResponse,
  ChecklistControllerResponseFromJSON,
  ChecklistControllerResponseToJSON,
  ChecklistItemHeaderControllerResponse,
  ChecklistItemHeaderControllerResponseFromJSON,
  ChecklistItemHeaderControllerResponseToJSON,
  ChecklistToCopyListControllerResponse,
  ChecklistToCopyListControllerResponseFromJSON,
  ChecklistToCopyListControllerResponseToJSON,
  ControllerResponse,
  ControllerResponseFromJSON,
  ControllerResponseToJSON,
  ControllerResponse1,
  ControllerResponse1FromJSON,
  ControllerResponse1ToJSON,
  CopyChecklistRequest,
  CopyChecklistRequestFromJSON,
  CopyChecklistRequestToJSON,
  CreatePhaseRequest,
  CreatePhaseRequestFromJSON,
  CreatePhaseRequestToJSON,
  ExclusionList,
  ExclusionListFromJSON,
  ExclusionListToJSON,
  ExclusionListControllerResponse,
  ExclusionListControllerResponseFromJSON,
  ExclusionListControllerResponseToJSON,
  FlexFieldIEnumerableControllerResponse,
  FlexFieldIEnumerableControllerResponseFromJSON,
  FlexFieldIEnumerableControllerResponseToJSON,
  InviteUserToProjectRequest,
  InviteUserToProjectRequestFromJSON,
  InviteUserToProjectRequestToJSON,
  Phase,
  PhaseFromJSON,
  PhaseToJSON,
  PhaseControllerResponse,
  PhaseControllerResponseFromJSON,
  PhaseControllerResponseToJSON,
  PhaseListControllerResponse,
  PhaseListControllerResponseFromJSON,
  PhaseListControllerResponseToJSON,
  Project,
  ProjectFromJSON,
  ProjectToJSON,
  ProjectControllerResponse,
  ProjectControllerResponseFromJSON,
  ProjectControllerResponseToJSON,
  ProjectCreate,
  ProjectCreateFromJSON,
  ProjectCreateToJSON,
  RejectChecklistRequest,
  RejectChecklistRequestFromJSON,
  RejectChecklistRequestToJSON,
  StringControllerResponse,
  StringControllerResponseFromJSON,
  StringControllerResponseToJSON,
  Tag,
  TagFromJSON,
  TagToJSON,
  TagCompletionDetailControllerResponse,
  TagCompletionDetailControllerResponseFromJSON,
  TagCompletionDetailControllerResponseToJSON,
  TagControllerResponse,
  TagControllerResponseFromJSON,
  TagControllerResponseToJSON,
  TagCountsByAreaAndPhaseListControllerResponse,
  TagCountsByAreaAndPhaseListControllerResponseFromJSON,
  TagCountsByAreaAndPhaseListControllerResponseToJSON,
  TagIssue,
  TagIssueFromJSON,
  TagIssueToJSON,
  TagIssueControllerResponse,
  TagIssueControllerResponseFromJSON,
  TagIssueControllerResponseToJSON,
  TagIssueListControllerResponse,
  TagIssueListControllerResponseFromJSON,
  TagIssueListControllerResponseToJSON,
  TagListControllerResponse,
  TagListControllerResponseFromJSON,
  TagListControllerResponseToJSON,
  UpdateProjectRequest,
  UpdateProjectRequestFromJSON,
  UpdateProjectRequestToJSON,
  UploadTag,
  UploadTagFromJSON,
  UploadTagToJSON,
  User,
  UserFromJSON,
  UserToJSON,
  UserControllerResponse,
  UserControllerResponseFromJSON,
  UserControllerResponseToJSON,
  UserListControllerResponse,
  UserListControllerResponseFromJSON,
  UserListControllerResponseToJSON,
  ProjectAttachmentListControllerResponseFromJSON,
} from "../models";

export interface ApiV1ProjectsIdAreasGetRequest {
  id: number;
}
export interface ApiV1ProjectsIdAreasAttachmentPostRequest {
  projectId: number;
  areaId: number;
  file?: Blob;
}

export interface ApiV1ProjectsIdAttachmentPostRequest {
  projectId: number;
  file?: Blob;
}

export interface ApiV1ProjectsIdAttachmentsGetRequest {
  id: number;
}

export interface ApiV1ProjectsAreaAttachmentsGetRequest {
  projectId: number;
  areaId: number;
}

export interface ApiV1ProjectsAreaAttachmentsDeleteRequest {
  projectId: number;
  areaId: number;
  attachmentId: number;
}

export interface ApiV1ProjectsAttachmentsDeleteRequest {
  projectId: number;
  attachmentId: number;
}

export interface ApiV1ProjectsIdAreasTechnicianPostRequest {
  id: number;
  area?: Area;
}

export interface ApiV1ProjectsIdChecklistsChecklistIdDeleteRequest {
  id: number;
  checklistId: number;
}

export interface ApiV1ProjectsIdChecklistsChecklistIdPutRequest {
  id: number;
  checklistId: number;
  checklist?: Checklist;
}

export interface ApiV1ProjectsIdChecklistsPostRequest {
  id: number;
  checklist?: Checklist;
}

export interface ApiV1ProjectsIdChecklistsToCopyGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdCompletedchecklistpackagelinkGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdCopyChecklistPostRequest {
  id: number;
  copyChecklistRequest?: CopyChecklistRequest;
}

export interface ApiV1ProjectsIdExclusionListPutRequest {
  id: number;
  exclusionList?: ExclusionList;
}

export interface ApiV1ProjectsIdGenerateCompletedChecklistPackagePostRequest {
  id: number;
}

export interface ApiV1ProjectsIdGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdGetFlexFieldsGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdHandoverpackagelinkGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdIssuesGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdIssuesIssueIdPutRequest {
  id: number;
  issueId: string;
  tagIssue?: TagIssue;
}

export interface ApiV1ProjectsIdKpiGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdPhasePutRequest {
  id: number;
  phase?: Phase;
}

export interface ApiV1ProjectsIdPhasesGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdPhasesPostRequest {
  id: number;
  createPhaseRequest?: CreatePhaseRequest;
}

export interface ApiV1ProjectsIdPutRequest {
  id: number;
  updateProjectRequest?: UpdateProjectRequest;
}

export interface ApiV1ProjectsIdRejectChecklistPostRequest {
  id: number;
  rejectChecklistRequest?: RejectChecklistRequest;
}

export interface ApiV1ProjectsIdTagsAreaAreaIdGetRequest {
  id: number;
  areaId: number;
}

export interface ApiV1ProjectsIdTagsPutRequest {
  id: number;
  tag?: Tag;
}

export interface ApiV1ProjectsIdTagsTagIdGetRequest {
  id: number;
  tagId: string;
}

export interface ApiV1ProjectsIdTagsUploadPostRequest {
  id: number;
  uploadTag?: Array<UploadTag>;
}

export interface ApiV1ProjectsIdTagsUserUserIdGetRequest {
  id: number;
  userId: number;
}

export interface ApiV1ProjectsIdUsersArchivedPostRequest {
  id: number;
  archiveProjectUserRequest?: ArchiveProjectUserRequest;
}

export interface ApiV1ProjectsIdUsersGetRequest {
  id: number;
}

export interface ApiV1ProjectsIdUsersPostRequest {
  id: number;
  inviteUserToProjectRequest?: InviteUserToProjectRequest;
}

export interface ApiV1ProjectsIdUsersPutRequest {
  id: number;
  user?: User;
}

export interface ApiV1ProjectsPostRequest {
  projectCreate?: ProjectCreate;
}

/**
 *
 */
export class ProjectsApi extends runtime.BaseAPI {
  /**
   */
  async apiV1ProjectsIdAreasGetRaw(
    requestParameters: ApiV1ProjectsIdAreasGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AreaListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdAreasGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/areas`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AreaListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdAttachmentsGetRaw(
    requestParameters: ApiV1ProjectsIdAttachmentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdAttachmentsGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/attachments`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProjectAttachmentListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsAreaAttachmentsGetRaw(
    requestParameters: ApiV1ProjectsAreaAttachmentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectsAreaAttachmentsGet."
      );
    }
    if (requestParameters.areaId === null || requestParameters.areaId === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.areaId was null or undefined when calling apiV1ProjectsAreaAttachmentsGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    let path = `/api/v1/Projects/{projectId}/areas/{areaId}/attachments`.replace(
      `{${"projectId"}}`,
      encodeURIComponent(String(requestParameters.projectId))
    );
    path = path.replace(`{${"areaId"}}`, encodeURIComponent(String(requestParameters.areaId)));
    console.log(path);
    const response = await this.request(
      {
        path: path,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProjectAreaAttachmentListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsAreaAttachmentsDeleteRaw(
    requestParameters: ApiV1ProjectsAreaAttachmentsDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
      throw new runtime.RequiredError(
        "projectId",
        "Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectsAreaAttachmentsDelete."
      );
    }
    if (requestParameters.areaId === null || requestParameters.areaId === undefined) {
      throw new runtime.RequiredError(
        "areaId",
        "Required parameter requestParameters.areaId was null or undefined when calling apiV1ProjectsAreaAttachmentsDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    let path = `/api/v1/Projects/{projectId}/areas/{areaId}/attachments/{attachmentId}`.replace(
      `{${"projectId"}}`,
      encodeURIComponent(String(requestParameters.projectId))
    );
    path = path.replace(`{${"areaId"}}`, encodeURIComponent(String(requestParameters.areaId)));
    path = path.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId)));
    console.log(path);
    const response = await this.request(
      {
        path: path,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProjectAreaAttachmentListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsAttachmentsDeleteRaw(
    requestParameters: ApiV1ProjectsAttachmentsDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
      throw new runtime.RequiredError(
        "projectId",
        "Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectsAttachmentsDelete."
      );
    }
    if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
      throw new runtime.RequiredError(
        "attachmentId",
        "Required parameter requestParameters.attachmentId was null or undefined when calling apiV1ProjectsAttachmentsDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    let path = `/api/v1/Projects/{projectId}/attachments/{attachmentId}`.replace(
      `{${"projectId"}}`,
      encodeURIComponent(String(requestParameters.projectId))
    );
    path = path.replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId)));
    console.log(path);
    const response = await this.request(
      {
        path: path,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProjectAreaAttachmentListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsAreaAttachmentsPostRaw(
    requestParameters: ApiV1ProjectsIdAreasAttachmentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.JSONApiResponse<StringControllerResponse>> {
    if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
      throw new runtime.RequiredError(
        "projectId",
        "Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectsAreaAttachmentsPost."
      );
    }
    if (requestParameters.areaId === null || requestParameters.areaId === undefined) {
      throw new runtime.RequiredError(
        "areaId",
        "Required parameter requestParameters.areaId was null or undefined when calling apiV1ProjectsAreaAttachmentsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    let path = `/api/v1/Projects/{projectId}/areas/{areaId}/attachments`.replace(
      `{${"projectId"}}`,
      encodeURIComponent(String(requestParameters.projectId))
    );
    path = path.replace(`{${"areaId"}}`, encodeURIComponent(String(requestParameters.areaId)));
    const response = await this.request(
      {
        path: path,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProjectAreaAttachmentListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsAttachmentsPostRaw(
    requestParameters: ApiV1ProjectsIdAttachmentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.JSONApiResponse<StringControllerResponse>> {
    if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
      throw new runtime.RequiredError(
        "projectId",
        "Required parameter requestParameters.projectId was null or undefined when calling apiV1ProjectsAttachmentsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    let path = `/api/v1/Projects/{projectId}/attachments`.replace(
      `{${"projectId"}}`,
      encodeURIComponent(String(requestParameters.projectId))
    );
    const response = await this.request(
      {
        path: path,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ProjectAreaAttachmentListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsIdTestGetRaw(
    requestParameters: ApiV1ProjectsIdAreasGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.JSONApiResponse<StringControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdAreasGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/test`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdAreasGet(
    requestParameters: ApiV1ProjectsIdAreasGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AreaListControllerResponse> {
    const response = await this.apiV1ProjectsIdAreasGetRaw(requestParameters, initOverrides);
    // const testResponse = await this.apiV1ProjectsIdTestGetRaw(requestParameters, initOverrides);
    // console.log((await testResponse.value()).data);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdAttachmentsGet(
    requestParameters: ApiV1ProjectsIdAttachmentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<any> {
    const response = await this.apiV1ProjectsIdAttachmentsGetRaw(requestParameters, initOverrides);
    // const testResponse = await this.apiV1ProjectsIdTestGetRaw(requestParameters, initOverrides);
    // console.log((await testResponse.value()).data);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsAreaAttachmentsGet(
    requestParameters: ApiV1ProjectsAreaAttachmentsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<any> {
    const response = await this.apiV1ProjectsAreaAttachmentsGetRaw(requestParameters, initOverrides);
    // const testResponse = await this.apiV1ProjectsIdTestGetRaw(requestParameters, initOverrides);
    // console.log((await testResponse.value()).data);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsAreaAttachmentsPost(
    requestParameters: ApiV1ProjectsIdAreasAttachmentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<any> {
    const response = await this.apiV1ProjectsAreaAttachmentsPostRaw(requestParameters, initOverrides);
    // const testResponse = await this.apiV1ProjectsIdTestGetRaw(requestParameters, initOverrides);
    // console.log((await testResponse.value()).data);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsAttachmentsPost(
    requestParameters: ApiV1ProjectsIdAttachmentPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<any> {
    const response = await this.apiV1ProjectsAttachmentsPostRaw(requestParameters, initOverrides);
    // const testResponse = await this.apiV1ProjectsIdTestGetRaw(requestParameters, initOverrides);
    // console.log((await testResponse.value()).data);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdAreasTechnicianPostRaw(
    requestParameters: ApiV1ProjectsIdAreasTechnicianPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AreaControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdAreasTechnicianPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/areas/technician`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AreaToJSON(requestParameters.area),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AreaControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdAreasTechnicianPost(
    requestParameters: ApiV1ProjectsIdAreasTechnicianPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AreaControllerResponse> {
    const response = await this.apiV1ProjectsIdAreasTechnicianPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdChecklistsChecklistIdDeleteRaw(
    requestParameters: ApiV1ProjectsIdChecklistsChecklistIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdChecklistsChecklistIdDelete."
      );
    }

    if (requestParameters.checklistId === null || requestParameters.checklistId === undefined) {
      throw new runtime.RequiredError(
        "checklistId",
        "Required parameter requestParameters.checklistId was null or undefined when calling apiV1ProjectsIdChecklistsChecklistIdDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/checklists/{checklistId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"checklistId"}}`, encodeURIComponent(String(requestParameters.checklistId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdChecklistsChecklistIdDelete(
    requestParameters: ApiV1ProjectsIdChecklistsChecklistIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1ProjectsIdChecklistsChecklistIdDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdAreasAttachmentIdDelete(
    requestParameters: ApiV1ProjectsAreaAttachmentsDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1ProjectsAreaAttachmentsDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  //apiV1ProjectsAttachmentsDeleteRaw
  /**
   */
  async apiV1ProjectsIdAttachmentIdDelete(
    requestParameters: ApiV1ProjectsAttachmentsDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1ProjectsAttachmentsDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }
  /**
   */
  async apiV1ProjectsIdChecklistsChecklistIdPutRaw(
    requestParameters: ApiV1ProjectsIdChecklistsChecklistIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChecklistControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdChecklistsChecklistIdPut."
      );
    }

    if (requestParameters.checklistId === null || requestParameters.checklistId === undefined) {
      throw new runtime.RequiredError(
        "checklistId",
        "Required parameter requestParameters.checklistId was null or undefined when calling apiV1ProjectsIdChecklistsChecklistIdPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/checklists/{checklistId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"checklistId"}}`, encodeURIComponent(String(requestParameters.checklistId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ChecklistToJSON(requestParameters.checklist),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChecklistControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdChecklistsChecklistIdPut(
    requestParameters: ApiV1ProjectsIdChecklistsChecklistIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChecklistControllerResponse> {
    const response = await this.apiV1ProjectsIdChecklistsChecklistIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdChecklistsPostRaw(
    requestParameters: ApiV1ProjectsIdChecklistsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChecklistControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdChecklistsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/checklists`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ChecklistToJSON(requestParameters.checklist),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChecklistControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdChecklistsPost(
    requestParameters: ApiV1ProjectsIdChecklistsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChecklistControllerResponse> {
    const response = await this.apiV1ProjectsIdChecklistsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdChecklistsToCopyGetRaw(
    requestParameters: ApiV1ProjectsIdChecklistsToCopyGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChecklistToCopyListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdChecklistsToCopyGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/checklistsToCopy`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChecklistToCopyListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsIdChecklistsToCopyGet(
    requestParameters: ApiV1ProjectsIdChecklistsToCopyGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChecklistToCopyListControllerResponse> {
    const response = await this.apiV1ProjectsIdChecklistsToCopyGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdCompletedchecklistpackagelinkGetRaw(
    requestParameters: ApiV1ProjectsIdCompletedchecklistpackagelinkGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<StringControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdCompletedchecklistpackagelinkGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/completedchecklistpackagelink`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdCompletedchecklistpackagelinkGet(
    requestParameters: ApiV1ProjectsIdCompletedchecklistpackagelinkGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<StringControllerResponse> {
    const response = await this.apiV1ProjectsIdCompletedchecklistpackagelinkGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdCopyChecklistPostRaw(
    requestParameters: ApiV1ProjectsIdCopyChecklistPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChecklistControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdCopyChecklistPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/copyChecklist`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CopyChecklistRequestToJSON(requestParameters.copyChecklistRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChecklistControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdCopyChecklistPost(
    requestParameters: ApiV1ProjectsIdCopyChecklistPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChecklistControllerResponse> {
    const response = await this.apiV1ProjectsIdCopyChecklistPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdExclusionListPutRaw(
    requestParameters: ApiV1ProjectsIdExclusionListPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ExclusionListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdExclusionListPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/exclusionList`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ExclusionListToJSON(requestParameters.exclusionList),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ExclusionListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdExclusionListPut(
    requestParameters: ApiV1ProjectsIdExclusionListPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ExclusionListControllerResponse> {
    const response = await this.apiV1ProjectsIdExclusionListPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdGenerateCompletedChecklistPackagePostRaw(
    requestParameters: ApiV1ProjectsIdGenerateCompletedChecklistPackagePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdGenerateCompletedChecklistPackagePost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/generateCompletedChecklistPackage`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    const versionResponse = await this.request({
      path: `/api/v1/Projects/{id}/version`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    console.log("VERSION: ", versionResponse);

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdGenerateCompletedChecklistPackagePost(
    requestParameters: ApiV1ProjectsIdGenerateCompletedChecklistPackagePostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1ProjectsIdGenerateCompletedChecklistPackagePostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdGetRaw(
    requestParameters: ApiV1ProjectsIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<Project>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdGet."
      );
    }

    console.log("apiV1ProjectsIdGetRaw");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdGet(
    requestParameters: ApiV1ProjectsIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<Project> {
    const response = await this.apiV1ProjectsIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdGetFlexFieldsGetRaw(
    requestParameters: ApiV1ProjectsIdGetFlexFieldsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<FlexFieldIEnumerableControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdGetFlexFieldsGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/getFlexFields`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      FlexFieldIEnumerableControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsIdGetFlexFieldsGet(
    requestParameters: ApiV1ProjectsIdGetFlexFieldsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<FlexFieldIEnumerableControllerResponse> {
    const response = await this.apiV1ProjectsIdGetFlexFieldsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdHandoverpackagelinkGetRaw(
    requestParameters: ApiV1ProjectsIdHandoverpackagelinkGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<StringControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdHandoverpackagelinkGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/handoverpackagelink`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdHandoverpackagelinkGet(
    requestParameters: ApiV1ProjectsIdHandoverpackagelinkGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<StringControllerResponse> {
    const response = await this.apiV1ProjectsIdHandoverpackagelinkGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdIssuesGetRaw(
    requestParameters: ApiV1ProjectsIdIssuesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagIssueListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdIssuesGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/issues`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagIssueListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdIssuesGet(
    requestParameters: ApiV1ProjectsIdIssuesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagIssueListControllerResponse> {
    const response = await this.apiV1ProjectsIdIssuesGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdIssuesIssueIdPutRaw(
    requestParameters: ApiV1ProjectsIdIssuesIssueIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagIssueControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdIssuesIssueIdPut."
      );
    }

    if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
      throw new runtime.RequiredError(
        "issueId",
        "Required parameter requestParameters.issueId was null or undefined when calling apiV1ProjectsIdIssuesIssueIdPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/issues/{issueId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"issueId"}}`, encodeURIComponent(String(requestParameters.issueId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: TagIssueToJSON(requestParameters.tagIssue),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagIssueControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdIssuesIssueIdPut(
    requestParameters: ApiV1ProjectsIdIssuesIssueIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagIssueControllerResponse> {
    const response = await this.apiV1ProjectsIdIssuesIssueIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdKpiGetRaw(
    requestParameters: ApiV1ProjectsIdKpiGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagCountsByAreaAndPhaseListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdKpiGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/kpi`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TagCountsByAreaAndPhaseListControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsIdKpiGet(
    requestParameters: ApiV1ProjectsIdKpiGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagCountsByAreaAndPhaseListControllerResponse> {
    const response = await this.apiV1ProjectsIdKpiGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdPhasePutRaw(
    requestParameters: ApiV1ProjectsIdPhasePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PhaseControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdPhasePut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/phase`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PhaseToJSON(requestParameters.phase),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhaseControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdPhasePut(
    requestParameters: ApiV1ProjectsIdPhasePutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PhaseControllerResponse> {
    const response = await this.apiV1ProjectsIdPhasePutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdPhasesGetRaw(
    requestParameters: ApiV1ProjectsIdPhasesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PhaseListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdPhasesGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/phases`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhaseListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdPhasesGet(
    requestParameters: ApiV1ProjectsIdPhasesGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PhaseListControllerResponse> {
    const response = await this.apiV1ProjectsIdPhasesGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdPhasesPostRaw(
    requestParameters: ApiV1ProjectsIdPhasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<PhaseControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdPhasesPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/phases`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: CreatePhaseRequestToJSON(requestParameters.createPhaseRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PhaseControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdPhasesPost(
    requestParameters: ApiV1ProjectsIdPhasesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<PhaseControllerResponse> {
    const response = await this.apiV1ProjectsIdPhasesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdPutRaw(
    requestParameters: ApiV1ProjectsIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ProjectControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateProjectRequestToJSON(requestParameters.updateProjectRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProjectControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdPut(
    requestParameters: ApiV1ProjectsIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ProjectControllerResponse> {
    const response = await this.apiV1ProjectsIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdRejectChecklistPostRaw(
    requestParameters: ApiV1ProjectsIdRejectChecklistPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChecklistItemHeaderControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdRejectChecklistPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/rejectChecklist`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RejectChecklistRequestToJSON(requestParameters.rejectChecklistRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChecklistItemHeaderControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsIdRejectChecklistPost(
    requestParameters: ApiV1ProjectsIdRejectChecklistPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChecklistItemHeaderControllerResponse> {
    const response = await this.apiV1ProjectsIdRejectChecklistPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdTagsAreaAreaIdGetRaw(
    requestParameters: ApiV1ProjectsIdTagsAreaAreaIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdTagsAreaAreaIdGet."
      );
    }

    if (requestParameters.areaId === null || requestParameters.areaId === undefined) {
      throw new runtime.RequiredError(
        "areaId",
        "Required parameter requestParameters.areaId was null or undefined when calling apiV1ProjectsIdTagsAreaAreaIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/tags/area/{areaId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"areaId"}}`, encodeURIComponent(String(requestParameters.areaId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdTagsAreaAreaIdGet(
    requestParameters: ApiV1ProjectsIdTagsAreaAreaIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagListControllerResponse> {
    const response = await this.apiV1ProjectsIdTagsAreaAreaIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdTagsPutRaw(
    requestParameters: ApiV1ProjectsIdTagsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdTagsPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/tags`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: TagToJSON(requestParameters.tag),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdTagsPut(
    requestParameters: ApiV1ProjectsIdTagsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagControllerResponse> {
    const response = await this.apiV1ProjectsIdTagsPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdTagsTagIdGetRaw(
    requestParameters: ApiV1ProjectsIdTagsTagIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagCompletionDetailControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdTagsTagIdGet."
      );
    }

    if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
      throw new runtime.RequiredError(
        "tagId",
        "Required parameter requestParameters.tagId was null or undefined when calling apiV1ProjectsIdTagsTagIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/tags/{tagId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    console.log(`/api/v1/Projects/{id}/tags/{tagId}`);

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TagCompletionDetailControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1ProjectsIdTagsTagIdGet(
    requestParameters: ApiV1ProjectsIdTagsTagIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagCompletionDetailControllerResponse> {
    const response = await this.apiV1ProjectsIdTagsTagIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdTagsUploadPostRaw(
    requestParameters: ApiV1ProjectsIdTagsUploadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<AssetTypeListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdTagsUploadPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/tags/upload`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.uploadTag?.map(UploadTagToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AssetTypeListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdTagsUploadPost(
    requestParameters: ApiV1ProjectsIdTagsUploadPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<AssetTypeListControllerResponse> {
    const response = await this.apiV1ProjectsIdTagsUploadPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdTagsUserUserIdGetRaw(
    requestParameters: ApiV1ProjectsIdTagsUserUserIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdTagsUserUserIdGet."
      );
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        "userId",
        "Required parameter requestParameters.userId was null or undefined when calling apiV1ProjectsIdTagsUserUserIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/tags/user/{userId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdTagsUserUserIdGet(
    requestParameters: ApiV1ProjectsIdTagsUserUserIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagListControllerResponse> {
    const response = await this.apiV1ProjectsIdTagsUserUserIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdUsersArchivedPostRaw(
    requestParameters: ApiV1ProjectsIdUsersArchivedPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdUsersArchivedPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/users/archived`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ArchiveProjectUserRequestToJSON(requestParameters.archiveProjectUserRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdUsersArchivedPost(
    requestParameters: ApiV1ProjectsIdUsersArchivedPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1ProjectsIdUsersArchivedPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdUsersGetRaw(
    requestParameters: ApiV1ProjectsIdUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserListControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdUsersGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdUsersGet(
    requestParameters: ApiV1ProjectsIdUsersGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserListControllerResponse> {
    const response = await this.apiV1ProjectsIdUsersGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdUsersPostRaw(
    requestParameters: ApiV1ProjectsIdUsersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdUsersPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: InviteUserToProjectRequestToJSON(requestParameters.inviteUserToProjectRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdUsersPost(
    requestParameters: ApiV1ProjectsIdUsersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserControllerResponse> {
    const response = await this.apiV1ProjectsIdUsersPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsIdUsersPutRaw(
    requestParameters: ApiV1ProjectsIdUsersPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<UserControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1ProjectsIdUsersPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: UserToJSON(requestParameters.user),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => UserControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsIdUsersPut(
    requestParameters: ApiV1ProjectsIdUsersPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<UserControllerResponse> {
    const response = await this.apiV1ProjectsIdUsersPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1ProjectsPostRaw(
    requestParameters: ApiV1ProjectsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ProjectControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Projects`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ProjectCreateToJSON(requestParameters.projectCreate),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProjectControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1ProjectsPost(
    requestParameters: ApiV1ProjectsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ProjectControllerResponse> {
    const response = await this.apiV1ProjectsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
