import { User, UserRole, PhaseCompletion, PhaseCompletionStatus, Area, Tag, TagIssue } from "data/api";
import { SafePhaseCompletion, SafeArea, TagRow, SafeTagIssue } from "./types";

export const formatUser = (user: User): User => {
  return {
    id: Number(user.id),
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    email: user.email || "",
    phoneNumber: user.phoneNumber || "",
    isRegistered: user.isRegistered || false,
    isAdmin: user.isAdmin || false,
    isActive: user.isActive || false,
    projectRoles: user.projectRoles,
  };
};

export const formatPhaseCompletion = (phase: PhaseCompletion): SafePhaseCompletion => ({
  areaId: Number(phase.areaId),
  phaseId: Number(phase.phaseId),
  phaseName: phase.phaseName || "",
  projectId: Number(phase.projectId),
  tagId: phase.tagId || "",
  itemsCompleted: Number(phase.itemsCompleted),
  itemsNotCompleted: Number(phase.itemsNotCompleted),
  phaseCompletionStatus: phase.phaseCompletionStatus || PhaseCompletionStatus.NotStarted,
  hasCriticalIssue: phase.hasCriticalIssue || false,
  hasNonCriticalIssue: phase.hasNonCriticalIssue || false,
  isExcluded: phase.isExcluded || false,
  completedByUser: phase.completedByUser || null,
  dateCompleted: phase.dateCompleted || null,
  isHeaderComplete: phase.isHeaderComplete || false,
});

export const formatTag = (tag: Tag): TagRow => ({
  id: tag.id || "",
  areaId: Number(tag.areaId),
  name: tag.name || "",
  latitude: Number(tag.latitude),
  longitude: Number(tag.longitude),
  users: tag.users?.filter((user) => user).map(formatUser) || [],
  deleted: false,
  phaseCompletions: tag.phaseCompletions!.map(formatPhaseCompletion) || [],
  notes: tag.notes || "",
  area: tag.area!,
  asset: tag.asset,
  images: [],
  attachments: [],
  issues: (tag.issues || []).map(formatTagIssue),
  flexFields: tag.flexFields || "",
});

export const formatTagIssue = (issue: TagIssue): SafeTagIssue => ({
  id: issue.id || "",
  createdAt: issue.createdAt || undefined,
  updatedAt: issue.updatedAt || undefined,
  deleted: issue.deleted || false,
  tagId: issue.tagId || "",
  issueType: issue.issueType || "",
  description: issue.description || "",
  isCritical: issue.isCritical || false,
  isResolved: issue.isResolved || false,
  createdByUser: issue.createdByUser || undefined,
  createdByUserId: issue.createdByUserId || 0,
  images: issue.images || [],
  tag: issue.tag!,
  resolvedByUser: issue.resolvedByUser || undefined,
  resolvedByUserId: issue.resolvedByUserId || 0,
  resolvedAt: issue.resolvedAt!,
});

export const formatArea = (area: Area): SafeArea => ({
  id: Number(area.id),
  name: area.name || "",
  users: area.users!.filter((user) => user).map(formatUser) || [],
  tags: area.tags!.map(formatTag) || [],
});
