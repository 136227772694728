import { call } from "data/api/rest";
import { RegisterApi, ApiV1RegisterGetRequest } from "data/api/apis/RegisterApi";
import { RegisterUserRequest, BooleanControllerResponse } from "data/api";

export const verifyUserRegistration = async (requestParams: ApiV1RegisterGetRequest): Promise<string> => {
  const { data } = await call(RegisterApi).apiV1RegisterGet(requestParams);
  return data!;
};

export const completeInvite = async (requestParams: RegisterUserRequest): Promise<BooleanControllerResponse> => {
  const response = await call(RegisterApi).apiV1RegisterPost({ registerUserRequest: requestParams });
  return response;
};
