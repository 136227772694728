/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Image, ImageFromJSON, ImageFromJSONTyped, ImageToJSON } from "./Image";

/**
 *
 * @export
 * @interface IssueImage
 */
export interface IssueImage {
  /**
   *
   * @type {string}
   * @memberof IssueImage
   */
  issueId?: string;
  /**
   *
   * @type {Image}
   * @memberof IssueImage
   */
  image?: Image;
}

export function IssueImageFromJSON(json: any): IssueImage {
  return IssueImageFromJSONTyped(json, false);
}

export function IssueImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): IssueImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    issueId: !exists(json, "issueId") ? undefined : json["issueId"],
    image: !exists(json, "image") ? undefined : ImageFromJSON(json["image"]),
  };
}

export function IssueImageToJSON(value?: IssueImage | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    issueId: value.issueId,
    image: ImageToJSON(value.image),
  };
}
