import { InputLabelClassKey, InputLabelProps } from "@material-ui/core/InputLabel";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { black } from "styles/colors";

export const MuiInputLabelTheme: Partial<Record<InputLabelClassKey, CSSProperties>> = {
  root: {
    color: black,
  },
};

export const MuiInputLabelProps: Partial<InputLabelProps> = {
  disableAnimation: true,
  shrink: false,
};
