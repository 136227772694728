/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Checklist, ChecklistFromJSON, ChecklistFromJSONTyped, ChecklistToJSON } from "./Checklist";
import {
  ChecklistItemHeader,
  ChecklistItemHeaderFromJSON,
  ChecklistItemHeaderFromJSONTyped,
  ChecklistItemHeaderToJSON,
} from "./ChecklistItemHeader";
import {
  PhaseCompletion,
  PhaseCompletionFromJSON,
  PhaseCompletionFromJSONTyped,
  PhaseCompletionToJSON,
} from "./PhaseCompletion";

/**
 *
 * @export
 * @interface TagPhaseDetail
 */
export interface TagPhaseDetail {
  /**
   *
   * @type {PhaseCompletion}
   * @memberof TagPhaseDetail
   */
  phaseCompletion?: PhaseCompletion;
  /**
   *
   * @type {Checklist}
   * @memberof TagPhaseDetail
   */
  checklist?: Checklist;
  /**
   *
   * @type {ChecklistItemHeader}
   * @memberof TagPhaseDetail
   */
  checklistItemHeader?: ChecklistItemHeader;
}

export function TagPhaseDetailFromJSON(json: any): TagPhaseDetail {
  return TagPhaseDetailFromJSONTyped(json, false);
}

export function TagPhaseDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagPhaseDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    phaseCompletion: !exists(json, "phaseCompletion") ? undefined : PhaseCompletionFromJSON(json["phaseCompletion"]),
    checklist: !exists(json, "checklist") ? undefined : ChecklistFromJSON(json["checklist"]),
    checklistItemHeader: !exists(json, "checklistItemHeader")
      ? undefined
      : ChecklistItemHeaderFromJSON(json["checklistItemHeader"]),
  };
}

export function TagPhaseDetailToJSON(value?: TagPhaseDetail | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    phaseCompletion: PhaseCompletionToJSON(value.phaseCompletion),
    checklist: ChecklistToJSON(value.checklist),
    checklistItemHeader: ChecklistItemHeaderToJSON(value.checklistItemHeader),
  };
}
