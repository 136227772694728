import { InputBaseClassKey } from "@material-ui/core/InputBase";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { grayLight } from "styles/colors";

const MuiOutlinedInputTheme: Partial<Record<InputBaseClassKey, CSSProperties>> = {
  root: {
    borderRadius: 0,
    "&$disabled": {
      backgroundColor: grayLight,
    },
  },
  multiline: {
    height: "auto",
    padding: "0.4rem",
  },
};

export { MuiOutlinedInputTheme };
