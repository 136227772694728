import { ButtonClassKey, ButtonProps } from "@material-ui/core/Button";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { gray, grayLight } from "../colors";

export const MuiButtonTheme: Partial<Record<ButtonClassKey, CSSProperties>> = {
  root: {
    textTransform: "none",
    fontSize: "1.125rem",
    lineHeight: "1.375rem",
    padding: "0.5rem 1.75rem",
    borderRadius: "unset",
  },
  contained: {
    boxShadow: "none",
    "&$disabled": {
      color: gray,
      backgroundColor: grayLight,
    },
  },
  text: {
    textTransform: "none",
    padding: "0.5rem 1.75rem",
  },
};

export const MuiButtonProps: Partial<ButtonProps> = {
  disableTouchRipple: true,
  disableRipple: true,
};
