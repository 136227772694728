import * as React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { white } from "styles/colors";
import { useTranslation } from "react-i18next";
import logo from "img/logo.svg";

export const useStyles = makeStyles(() => ({
  header: {
    width: "100%",
    height: "5.5rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: white,
    padding: "0 2rem",
  },
  headerText: {
    fontSize: "1.5rem",
    lineHeight: "1.875rem",
    marginTop: "0.75rem",
  },
}));

const Header: React.FC = () => {
  const [t] = useTranslation();
  const classes = useStyles();
  return (
    <header className={classes.header}>
      <Typography className={classes.headerText} color="secondary">
        {t("common.appName")}
      </Typography>
      <img src={logo} alt="logo" width="208" />
    </header>
  );
};

export default Header;
