import React, { createContext, useContext, useEffect, useCallback, useState, useMemo } from "react";
import * as serviceWorker from "./serviceWorker";

type ServiceWorkerState = {
  isUpdateAvailable: boolean;
  onApplyUpdate: () => void;
};

const ServiceWorkerContext = createContext<ServiceWorkerState | undefined>(undefined);

export const ServiceWorkerProvider: React.FC = ({ children }) => {
  const [worker, setWorker] = useState<ServiceWorker | null>(null);

  const isUpdateAvailable = useMemo(() => Boolean(worker), [worker]);

  const onApplyUpdate = useCallback(() => {
    if (worker) {
      worker.postMessage({ type: "SKIP_WAITING" });
      worker.addEventListener("statechange", (e) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (e?.target && (e.target as any).state === "activated") {
          window.location.reload();
        }
      });
    }
  }, [worker]);

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration: ServiceWorkerRegistration) => setWorker(registration.waiting),
    });
  }, []);

  return (
    <ServiceWorkerContext.Provider value={{ isUpdateAvailable, onApplyUpdate }}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export function useServiceWorker() {
  const context = useContext(ServiceWorkerContext);
  if (context === undefined) {
    throw new Error("useServiceWorker must be used within a ServiceWorkerProvider");
  }
  return context;
}
