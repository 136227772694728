import {
  optimisiticAsyncFactory as asyncFactory,
  optimisiticActionCreatorFactory as actionCreatorFactory,
} from "../offline";
import { AppState } from "store/types";
import { TechApi, ChecklistItemHeader } from "data/api";
import { call } from "data/api/rest";
import { ApiV1TechProjectsIdChecklistsPutRequestBulk } from "./types";

const createAction = actionCreatorFactory("TECH/CHECKLIST");
const createAsyncAction = asyncFactory<AppState>(createAction);

export const completeChecklists = createAsyncAction<
  ApiV1TechProjectsIdChecklistsPutRequestBulk,
  ChecklistItemHeader[],
  Error
>(
  "SAVE_CHECKLIST",
  ({ checklistItemHeaders }) => {
    return checklistItemHeaders!;
  },
  async ({ id, checklistItemHeaders }) => {
    const result = await Promise.all(
      (checklistItemHeaders || []).map((checklistItemHeader) =>
        call(TechApi).apiV1TechProjectsIdChecklistsPut({ id, checklistItemHeader })
      )
    );
    return result.map(({ data }) => data!);
  }
);
