/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ChecklistItemDetail,
  ChecklistItemDetailFromJSON,
  ChecklistItemDetailFromJSONTyped,
  ChecklistItemDetailToJSON,
} from "./ChecklistItemDetail";
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface ChecklistItemHeader
 */
export interface ChecklistItemHeader {
  /**
   *
   * @type {string}
   * @memberof ChecklistItemHeader
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof ChecklistItemHeader
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof ChecklistItemHeader
   */
  updatedAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItemHeader
   */
  deleted?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItemHeader
   */
  isComplete?: boolean;
  /**
   *
   * @type {string}
   * @memberof ChecklistItemHeader
   */
  assetId?: string;
  /**
   *
   * @type {number}
   * @memberof ChecklistItemHeader
   */
  completedBy?: number | null;
  /**
   *
   * @type {User}
   * @memberof ChecklistItemHeader
   */
  completedByUser?: User;
  /**
   *
   * @type {number}
   * @memberof ChecklistItemHeader
   */
  checklistId?: number;
  /**
   *
   * @type {Array<ChecklistItemDetail>}
   * @memberof ChecklistItemHeader
   */
  checklistItemDetails?: Array<ChecklistItemDetail> | null;
}

export function ChecklistItemHeaderFromJSON(json: any): ChecklistItemHeader {
  return ChecklistItemHeaderFromJSONTyped(json, false);
}

export function ChecklistItemHeaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChecklistItemHeader {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "createdAt") ? undefined : new Date(json["createdAt"]),
    updatedAt: !exists(json, "updatedAt") ? undefined : new Date(json["updatedAt"]),
    deleted: !exists(json, "deleted") ? undefined : json["deleted"],
    isComplete: !exists(json, "isComplete") ? undefined : json["isComplete"],
    assetId: !exists(json, "assetId") ? undefined : json["assetId"],
    completedBy: !exists(json, "completedBy") ? undefined : json["completedBy"],
    completedByUser: !exists(json, "completedByUser") ? undefined : UserFromJSON(json["completedByUser"]),
    checklistId: !exists(json, "checklistId") ? undefined : json["checklistId"],
    checklistItemDetails: !exists(json, "checklistItemDetails")
      ? undefined
      : json["checklistItemDetails"] === null
      ? null
      : (json["checklistItemDetails"] as Array<any>).map(ChecklistItemDetailFromJSON),
  };
}

export function ChecklistItemHeaderToJSON(value?: ChecklistItemHeader | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updatedAt: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    deleted: value.deleted,
    isComplete: value.isComplete,
    assetId: value.assetId,
    completedBy: value.completedBy,
    completedByUser: UserToJSON(value.completedByUser),
    checklistId: value.checklistId,
    checklistItemDetails:
      value.checklistItemDetails === undefined
        ? undefined
        : value.checklistItemDetails === null
        ? null
        : (value.checklistItemDetails as Array<any>).map(ChecklistItemDetailToJSON),
  };
}
