import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { AppState } from "store/types";
import {
  UsersApi,
  User,
  ApiV1UsersIdPutRequest,
  UserControllerResponse,
  BooleanControllerResponse,
  RegisterApi,
  ApiV1RegisterEmailPostRequest,
  ApiV1RegisterEmailGetRequest,
  ChangeEmailResponseControllerResponse,
} from "data/api";
import { call } from "data/api/rest";

const createAction = actionCreatorFactory("PROFILE");
const createAsyncAction = asyncFactory<AppState>(createAction);

export const getCurrentUser = createAsyncAction<number, User, Error>("GET_CURRENT_USER", async () => {
  const { data, error } = await call(UsersApi).apiV1UsersGet();
  if (!data) throw new Error(error?.message || "");
  return data;
});

export const editUserProfile = createAsyncAction<ApiV1UsersIdPutRequest, UserControllerResponse, Error>(
  "EDIT_USER_PROFILE",
  async (editUserRequest, dispatch) => {
    const data = await call(UsersApi).apiV1UsersIdPut(editUserRequest);
    await dispatch(getCurrentUser());
    return data;
  }
);

export const confirmEmailChange = createAsyncAction<ApiV1RegisterEmailPostRequest, BooleanControllerResponse, Error>(
  "CONFIRM_EMAIL_CHANGE",
  async (confirmEmailRequest) => {
    const data = await call(RegisterApi).apiV1RegisterEmailPost(confirmEmailRequest);
    return data;
  }
);

export const getEmailChange = createAsyncAction<
  ApiV1RegisterEmailGetRequest,
  ChangeEmailResponseControllerResponse,
  Error
>("GET_EMAIL_CHANGE", async (getEmailRequest) => {
  const data = await call(RegisterApi).apiV1RegisterEmailGet(getEmailRequest);
  return data;
});
