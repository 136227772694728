/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import {
  TagCountsByAreaAndPhase,
  TagCountsByAreaAndPhaseFromJSON,
  TagCountsByAreaAndPhaseFromJSONTyped,
  TagCountsByAreaAndPhaseToJSON,
} from "./TagCountsByAreaAndPhase";

/**
 *
 * @export
 * @interface TagCountsByAreaAndPhaseListControllerResponse
 */
export interface TagCountsByAreaAndPhaseListControllerResponse {
  /**
   *
   * @type {Array<TagCountsByAreaAndPhase>}
   * @memberof TagCountsByAreaAndPhaseListControllerResponse
   */
  data?: Array<TagCountsByAreaAndPhase> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof TagCountsByAreaAndPhaseListControllerResponse
   */
  error?: ErrorResponse;
}

export function TagCountsByAreaAndPhaseListControllerResponseFromJSON(
  json: any
): TagCountsByAreaAndPhaseListControllerResponse {
  return TagCountsByAreaAndPhaseListControllerResponseFromJSONTyped(json, false);
}

export function TagCountsByAreaAndPhaseListControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TagCountsByAreaAndPhaseListControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(TagCountsByAreaAndPhaseFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function TagCountsByAreaAndPhaseListControllerResponseToJSON(
  value?: TagCountsByAreaAndPhaseListControllerResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(TagCountsByAreaAndPhaseToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
