import { useSelector, useDispatch } from "react-redux";
import { AppState } from "store/types";
import { getCurrentUser, editUserProfile, confirmEmailChange, getEmailChange } from "data/user/actions";
import { useMemo } from "react";
import { CurrentUserDispatch, CurrentUserState } from "./types";

const useCurrentUserState = (): CurrentUserState =>
  useSelector(({ user }: AppState) => {
    return user;
  });

const useCurrentUserDispatch = (): CurrentUserDispatch => {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      getCurrentUser: () => dispatch(getCurrentUser()),
      editUserProfile: (newUpdateRequest) => dispatch(editUserProfile(newUpdateRequest)),
      confirmEmailChange: (confirmEmailRequest) => dispatch(confirmEmailChange(confirmEmailRequest)),
      getEmailChange: (getEmailRequest) => dispatch(getEmailChange(getEmailRequest)),
    }),
    [dispatch]
  );
};

export const useCurrentUser = (): [CurrentUserState, CurrentUserDispatch] => {
  return [useCurrentUserState(), useCurrentUserDispatch()];
};
