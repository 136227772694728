/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import { Phase, PhaseFromJSON, PhaseFromJSONTyped, PhaseToJSON } from "./Phase";

/**
 *
 * @export
 * @interface PhaseListControllerResponse
 */
export interface PhaseListControllerResponse {
  /**
   *
   * @type {Array<Phase>}
   * @memberof PhaseListControllerResponse
   */
  data?: Array<Phase> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof PhaseListControllerResponse
   */
  error?: ErrorResponse;
}

export function PhaseListControllerResponseFromJSON(json: any): PhaseListControllerResponse {
  return PhaseListControllerResponseFromJSONTyped(json, false);
}

export function PhaseListControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PhaseListControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(PhaseFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function PhaseListControllerResponseToJSON(value?: PhaseListControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined ? undefined : value.data === null ? null : (value.data as Array<any>).map(PhaseToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
