/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { IssueImage, IssueImageFromJSON, IssueImageFromJSONTyped, IssueImageToJSON } from "./IssueImage";
import { Tag, TagFromJSON, TagFromJSONTyped, TagToJSON } from "./Tag";
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface TagIssue
 */
export interface TagIssue {
  /**
   *
   * @type {string}
   * @memberof TagIssue
   */
  id?: string;
  /**
   *
   * @type {Date}
   * @memberof TagIssue
   */
  createdAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof TagIssue
   */
  updatedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof TagIssue
   */
  resolvedAt?: Date;
  /**
   *
   * @type {boolean}
   * @memberof TagIssue
   */
  deleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof TagIssue
   */
  tagId?: string;
  /**
   *
   * @type {string}
   * @memberof TagIssue
   */
  issueType?: string | null;
  /**
   *
   * @type {string}
   * @memberof TagIssue
   */
  description?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof TagIssue
   */
  isCritical?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TagIssue
   */
  isResolved?: boolean;
  /**
   *
   * @type {User}
   * @memberof TagIssue
   */
  resolvedByUser?: User;
  /**
   *
   * @type {number}
   * @memberof TagIssue
   */
  resolvedByUserId?: number;
  /**
   *
   * @type {User}
   * @memberof TagIssue
   */
  createdByUser?: User;
  /**
   *
   * @type {number}
   * @memberof TagIssue
   */
  createdByUserId?: number;
  /**
   *
   * @type {Tag}
   * @memberof TagIssue
   */
  tag?: Tag;
  /**
   *
   * @type {Array<IssueImage>}
   * @memberof TagIssue
   */
  images?: Array<IssueImage> | null;
}

export function TagIssueFromJSON(json: any): TagIssue {
  return TagIssueFromJSONTyped(json, false);
}

export function TagIssueFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagIssue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    createdAt: !exists(json, "createdAt") ? undefined : new Date(json["createdAt"]),
    updatedAt: !exists(json, "updatedAt") ? undefined : new Date(json["updatedAt"]),
    resolvedAt: !exists(json, "resolvedAt") ? undefined : new Date(json["resolvedAt"]),
    deleted: !exists(json, "deleted") ? undefined : json["deleted"],
    tagId: !exists(json, "tagId") ? undefined : json["tagId"],
    issueType: !exists(json, "issueType") ? undefined : json["issueType"],
    description: !exists(json, "description") ? undefined : json["description"],
    isCritical: !exists(json, "isCritical") ? undefined : json["isCritical"],
    isResolved: !exists(json, "isResolved") ? undefined : json["isResolved"],
    resolvedByUser: !exists(json, "resolvedByUser") ? undefined : UserFromJSON(json["resolvedByUser"]),
    resolvedByUserId: !exists(json, "resolvedByUserId") ? undefined : json["resolvedByUserId"],
    createdByUser: !exists(json, "createdByUser") ? undefined : UserFromJSON(json["createdByUser"]),
    createdByUserId: !exists(json, "createdByUserId") ? undefined : json["createdByUserId"],
    tag: !exists(json, "tag") ? undefined : TagFromJSON(json["tag"]),
    images: !exists(json, "images")
      ? undefined
      : json["images"] === null
      ? null
      : (json["images"] as Array<any>).map(IssueImageFromJSON),
  };
}

export function TagIssueToJSON(value?: TagIssue | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    createdAt: value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    updatedAt: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    resolvedAt: value.resolvedAt === undefined ? undefined : value.resolvedAt.toISOString(),
    deleted: value.deleted,
    tagId: value.tagId,
    issueType: value.issueType,
    description: value.description,
    isCritical: value.isCritical,
    isResolved: value.isResolved,
    resolvedByUser: UserToJSON(value.resolvedByUser),
    resolvedByUserId: value.resolvedByUserId,
    createdByUser: UserToJSON(value.createdByUser),
    createdByUserId: value.createdByUserId,
    tag: TagToJSON(value.tag),
    images:
      value.images === undefined
        ? undefined
        : value.images === null
        ? null
        : (value.images as Array<any>).map(IssueImageToJSON),
  };
}
