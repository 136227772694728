/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import {
  TagCompletionDetail,
  TagCompletionDetailFromJSON,
  TagCompletionDetailFromJSONTyped,
  TagCompletionDetailToJSON,
} from "./TagCompletionDetail";

/**
 *
 * @export
 * @interface TagCompletionDetailControllerResponse
 */
export interface TagCompletionDetailControllerResponse {
  /**
   *
   * @type {TagCompletionDetail}
   * @memberof TagCompletionDetailControllerResponse
   */
  data?: TagCompletionDetail;
  /**
   *
   * @type {ErrorResponse}
   * @memberof TagCompletionDetailControllerResponse
   */
  error?: ErrorResponse;
}

export function TagCompletionDetailControllerResponseFromJSON(json: any): TagCompletionDetailControllerResponse {
  return TagCompletionDetailControllerResponseFromJSONTyped(json, false);
}

export function TagCompletionDetailControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TagCompletionDetailControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  
  return {
    data: !exists(json, "data") ? undefined : TagCompletionDetailFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function TagCompletionDetailControllerResponseToJSON(value?: TagCompletionDetailControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: TagCompletionDetailToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
