import { TagKeys, PlainCSVHeaders, CSVHeaders } from "data/admin/projects";

type KeyTypes = TagKeys | PlainCSVHeaders | CSVHeaders;

export const textTransform = (
  text: string,
  originalKeys: Record<string, KeyTypes>,
  desiredKeys: Record<string, KeyTypes>
) => {
  let transformedString = text.repeat(1);
  Object.keys(originalKeys).forEach((key) => {
    transformedString = transformedString.replace(originalKeys[key], desiredKeys[key]);
  });
  return transformedString;
};
