/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { AssetType, AssetTypeFromJSON, AssetTypeFromJSONTyped, AssetTypeToJSON } from "./AssetType";

/**
 *
 * @export
 * @interface CopyChecklistRequest
 */
export interface CopyChecklistRequest {
  /**
   *
   * @type {number}
   * @memberof CopyChecklistRequest
   */
  existingChecklistProjectId?: number;
  /**
   *
   * @type {number}
   * @memberof CopyChecklistRequest
   */
  existingChecklistId?: number;
  /**
   *
   * @type {string}
   * @memberof CopyChecklistRequest
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof CopyChecklistRequest
   */
  projectId?: number;
  /**
   *
   * @type {number}
   * @memberof CopyChecklistRequest
   */
  phaseId?: number;
  /**
   *
   * @type {Array<AssetType>}
   * @memberof CopyChecklistRequest
   */
  assetTypes?: Array<AssetType> | null;
}

export function CopyChecklistRequestFromJSON(json: any): CopyChecklistRequest {
  return CopyChecklistRequestFromJSONTyped(json, false);
}

export function CopyChecklistRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyChecklistRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    existingChecklistProjectId: !exists(json, "existingChecklistProjectId")
      ? undefined
      : json["existingChecklistProjectId"],
    existingChecklistId: !exists(json, "existingChecklistId") ? undefined : json["existingChecklistId"],
    name: !exists(json, "name") ? undefined : json["name"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    phaseId: !exists(json, "phaseId") ? undefined : json["phaseId"],
    assetTypes: !exists(json, "assetTypes")
      ? undefined
      : json["assetTypes"] === null
      ? null
      : (json["assetTypes"] as Array<any>).map(AssetTypeFromJSON),
  };
}

export function CopyChecklistRequestToJSON(value?: CopyChecklistRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    existingChecklistProjectId: value.existingChecklistProjectId,
    existingChecklistId: value.existingChecklistId,
    name: value.name,
    projectId: value.projectId,
    phaseId: value.phaseId,
    assetTypes:
      value.assetTypes === undefined
        ? undefined
        : value.assetTypes === null
        ? null
        : (value.assetTypes as Array<any>).map(AssetTypeToJSON),
  };
}
