import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { theme } from "./styles";
import browserHistory from "./browserHistory";
import { configureStore } from "store";
import i18n from "./i18n";
import AuthProvider from "data/auth/AuthProvider";
import App from "ui";
import { PersistGate } from "redux-persist/integration/react";
import { ServiceWorkerProvider } from "pwa";

const { store, persistor } = configureStore(browserHistory);

const Root: React.FC = () => {
  return (
    <ServiceWorkerProvider>
      <React.Suspense fallback={<p>Loading...</p>}>
        <I18nextProvider i18n={i18n}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
              <AuthProvider>
                <Provider store={store}>
                  <PersistGate loading={null} persistor={persistor}>
                    <App />
                  </PersistGate>
                </Provider>
              </AuthProvider>
            </Router>
          </ThemeProvider>
        </I18nextProvider>
      </React.Suspense>
    </ServiceWorkerProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
