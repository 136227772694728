/* eslint-disable @typescript-eslint/no-explicit-any */
import actionCreatorFactory, {
  ActionCreator,
  Success,
  Failure,
  Meta,
  AsyncActionCreators,
  AnyAction,
} from "typescript-fsa";
import { AsyncWorker, ThunkReturnType } from "typescript-fsa-redux-thunk";
import { ThunkDispatch } from "redux-thunk";

export type OptimisiticAsyncActionCreators<Params, Result, Error = {}> = AsyncActionCreators<Params, Result, Error> & {
  optimistic: ActionCreator<Success<Params, Result>>;
};

export type OptimisiticActionCreatorFactory = {
  <Payload = void>(type: string, commonMeta?: Meta, isError?: boolean): ActionCreator<Payload>;
  <Payload = void>(type: string, commonMeta?: Meta, isError?: (payload: Payload) => boolean): ActionCreator<Payload>;
  async<Params, Result, Error = {}>(
    type: string,
    commonMeta?: Meta
  ): OptimisiticAsyncActionCreators<Params, Result, Error>;
};

export function optimisiticActionCreatorFactory(
  prefix?: string | null,
  defaultIsError: (payload: any) => boolean = (p) => p instanceof Error
): OptimisiticActionCreatorFactory {
  const base = prefix ? `${prefix}/` : "";
  const actionCreator = actionCreatorFactory(prefix, defaultIsError);

  function optimisiticAsyncActionCreators<Params, Result, Error>(
    type: string,
    commonMeta?: Meta
  ): OptimisiticAsyncActionCreators<Params, Result, Error> {
    return {
      type: base + type,
      optimistic: actionCreator<Success<Params, Result>>(`${type}_OPTIMISTIC`, commonMeta, false),
      started: actionCreator<Params>(`${type}_STARTED`, commonMeta, false),
      done: actionCreator<Success<Params, Result>>(`${type}_DONE`, commonMeta, false),
      failed: actionCreator<Failure<Params, Error>>(`${type}_FAILED`, commonMeta, true),
    };
  }

  return Object.assign(actionCreator, { async: optimisiticAsyncActionCreators });
}

export interface OptimisiticThunkFunction<S, P, R, E, A> {
  (params?: P): (dispatch: ThunkDispatch<S, any, AnyAction>, getState: () => S, extraArgument: A) => R;
  type: string;
  optimistic(params?: P): (dispatch: ThunkDispatch<S, any, AnyAction>, getState: () => S, extraArgument: A) => R;
  persist(params?: P): (dispatch: ThunkDispatch<S, any, AnyAction>, getState: () => S, extraArgument: A) => Promise<R>;
  async: OptimisiticAsyncActionCreators<P, R, E>;
}

export interface PersistFunction<S, P, R, E, A> {
  (params?: P): (dispatch: ThunkDispatch<S, any, AnyAction>, getState: () => S, extraArgument: A) => Promise<R>;
}

export type Worker<P, R, S, A> = (
  params: P,
  dispatch: ThunkDispatch<S, any, AnyAction>,
  getState: () => S,
  extraArgument: A
) => R;

export const optimisiticAsyncFactory =
  <S, A = any>(create: OptimisiticActionCreatorFactory, resolve: () => Promise<void> = Promise.resolve.bind(Promise)) =>
  <P, R, E = any>(
    type: string,
    optimisiticWorker: Worker<P, ThunkReturnType<R>, S, A>,
    persistWorker: AsyncWorker<P, ThunkReturnType<R>, S, A>,
    commonMeta?: Meta
  ) => {
    type OptimisticProcedure = OptimisiticThunkFunction<S, P, ThunkReturnType<R>, E, A>;
    type PersistProcedure = PersistFunction<S, P, ThunkReturnType<R>, E, A>;
    const async = create.async<P, ThunkReturnType<R>, E>(type, commonMeta);
    const fn: OptimisticProcedure = (params) => (dispatch, getState, extraArgument) => {
      const result = optimisiticWorker(params!, dispatch, getState, extraArgument);
      dispatch(
        async.optimistic({
          params: params!,
          result,
        })
      );
      return result;
    };
    const persist: PersistProcedure = (params) => (dispatch, getState, extraArgument) =>
      resolve()
        .then(() => {
          dispatch(async.started(params!));
        })
        .then(() => persistWorker(params!, dispatch, getState, extraArgument))
        .then((result) => {
          dispatch(
            async.done({
              params: params!,
              result,
            })
          );
          return result;
        })
        .catch((error) => {
          dispatch(
            async.failed({
              params: params!,
              error,
            })
          );
          throw error;
        });
    fn.type = type;
    fn.optimistic = fn;
    fn.persist = persist;
    fn.async = async;
    return fn;
  };
