/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Tag, TagFromJSON, TagFromJSONTyped, TagToJSON } from "./Tag";
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 *
 * @export
 * @interface Area
 */
export interface Area {
  /**
   *
   * @type {number}
   * @memberof Area
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Area
   */
  name?: string | null;
  /**
   *
   * @type {Array<Tag>}
   * @memberof Area
   */
  tags?: Array<Tag> | null;
  /**
   *
   * @type {Array<User>}
   * @memberof Area
   */
  users?: Array<User> | null;
}

export function AreaFromJSON(json: any): Area {
  return AreaFromJSONTyped(json, false);
}

export function AreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Area {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    tags: !exists(json, "tags")
      ? undefined
      : json["tags"] === null
      ? null
      : (json["tags"] as Array<any>).map(TagFromJSON),
    users: !exists(json, "users")
      ? undefined
      : json["users"] === null
      ? null
      : (json["users"] as Array<any>).map(UserFromJSON),
  };
}

export function AreaToJSON(value?: Area | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    tags: value.tags === undefined ? undefined : value.tags === null ? null : (value.tags as Array<any>).map(TagToJSON),
    users:
      value.users === undefined ? undefined : value.users === null ? null : (value.users as Array<any>).map(UserToJSON),
  };
}
