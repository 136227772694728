/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const DataType = {
  None: "None",
  String: "String",
  Number: "Number",
} as const;
export type DataType = typeof DataType[keyof typeof DataType];

export function DataTypeFromJSON(json: any): DataType {
  return DataTypeFromJSONTyped(json, false);
}

export function DataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataType {
  return json as DataType;
}

export function DataTypeToJSON(value?: DataType | null): any {
  return value as any;
}
