import { Auth0DecodedHash } from "auth0-js";
import { AuthData } from "./types";

const getAuthTokenFromLocalStorage = (): AuthData => {
  return JSON.parse(localStorage.getItem("auth") || "{}");
};

const saveAuthTokenToLocalStorage = ({
  accessToken,
  expiresIn: accessTokenExpiresAt,
  idToken,
  idTokenPayload: user,
  idTokenPayload: { exp: idTokenExpiresAt },
}: Auth0DecodedHash): AuthData => {
  const auth = {
    accessToken,
    accessTokenExpiresAt: Date.now() + (accessTokenExpiresAt || 0) * 1000,
    idToken,
    idTokenExpiresAt: idTokenExpiresAt * 1000,
    user,
  };
  localStorage.setItem("auth", JSON.stringify(auth));
  return auth;
};

const getAccessToken = ({ accessToken }: AuthData = getAuthTokenFromLocalStorage()): string => {
  return accessToken || "";
};

const getAccessTokenExpiresAt = ({ accessTokenExpiresAt }: AuthData = getAuthTokenFromLocalStorage()): number => {
  return accessTokenExpiresAt || 0;
};

const getIdentityToken = ({ idToken }: AuthData = getAuthTokenFromLocalStorage()): string => {
  return idToken || "";
};

const getIdentityTokenExpiresAt = ({ idTokenExpiresAt }: AuthData = getAuthTokenFromLocalStorage()): number => {
  return idTokenExpiresAt || 0;
};

const isIdentityTokenExpired = (auth: AuthData = getAuthTokenFromLocalStorage()): boolean => {
  return Date.now() >= getIdentityTokenExpiresAt(auth);
};

const isAccessTokenExpired = (auth: AuthData = getAuthTokenFromLocalStorage()): boolean => {
  return Date.now() >= getAccessTokenExpiresAt(auth);
};

const isAuthenticated = (auth: AuthData = getAuthTokenFromLocalStorage()): boolean => {
  const { idToken } = auth;
  return Boolean(idToken) && !isIdentityTokenExpired(auth);
};

export {
  getAuthTokenFromLocalStorage,
  saveAuthTokenToLocalStorage,
  getAccessToken,
  getAccessTokenExpiresAt,
  getIdentityToken,
  getIdentityTokenExpiresAt,
  isAccessTokenExpired,
  isIdentityTokenExpired,
  isAuthenticated,
};
