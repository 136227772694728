import { reducerWithInitialState } from "typescript-fsa-reducers";
import { keyBy, map } from "lodash";
import { TechnicianProjectsReducerState } from "./types";
import {
  getTechnicianProjects,
  getTechnicianProjectById,
  selectTechnicianProjectById,
  updateDownloadedProjects,
} from "./actions";
import { completeChecklists } from "../checklists/actions";
import { upsertTag, createIssue } from "../tags/actions";
import { getProjectById } from "./selectors";

const initialState: TechnicianProjectsReducerState = {
  isUpdatingDownloadedProjects: false,
  isLoadingProjects: true,
  byId: {},
  allIds: [],
  isLoadingById: {},
  selectedProject: null,
  error: null,
};

const normalize = <T>(items: T[]) => {
  const byId = keyBy(items, "id");
  const allIds = map(items, "id");
  return { byId, allIds };
};

const upsert = <T extends { id?: string }>(items: T[], item: T) => {
  const existing = items.findIndex((t) => t.id === item.id) !== -1;
  if (!existing) {
    return [...items, item];
  } else {
    return items.map((t) => (t.id === item.id ? item : t));
  }
};

const reducer = reducerWithInitialState(initialState)
  .case(getTechnicianProjects.async.started, (state) => ({
    ...state,
    isLoadingProjects: true,
  }))
  .case(getTechnicianProjects.async.failed, (state, { error }) => ({
    ...state,
    isLoadingProjects: false,
    error,
  }))
  .case(getTechnicianProjects.async.done, (state, { result: projects }) => {
    projects = projects.map((project) => {
      if (state.byId[project.id!]) {
        project = state.byId[project.id!];
      }
      return project;
    });
    const { byId, allIds } = normalize(projects);

    return {
      ...state,
      error: null,
      isLoadingProjects: false,
      byId,
      allIds,
    };
  })
  .case(selectTechnicianProjectById, (state, projectId) => {
    return {
      ...state,
      selectedProject: projectId,
    };
  })
  .case(getTechnicianProjectById.async.started, (state, projectId) => {
    return {
      ...state,
      isLoadingById: {
        ...state.isLoadingById,
        [projectId]: true,
      },
    };
  })
  .case(getTechnicianProjectById.async.failed, (state, { params: projectId, error }) => ({
    ...state,
    isLoadingById: {
      ...state.isLoadingById,
      [projectId]: false,
    },
    error,
  }))
  .case(getTechnicianProjectById.async.done, (state, { params: projectId, result: project }) => {
    const savedProject = { ...project, hasDownloaded: true };
    const { byId } = normalize([savedProject]);

    return {
      ...state,
      isLoadingById: {
        ...state.isLoadingById,
        [projectId]: false,
      },
      byId: { ...state.byId, ...byId },
    };
  })
  .cases(
    [completeChecklists.async.optimistic, completeChecklists.async.done],
    (state, { params: request, result: newChecklistItemHeaders }) => {
      const projectForChecklist = getProjectById(state, request.id);
      if (!projectForChecklist) {
        return state;
      }

      const project = {
        ...projectForChecklist,
        checklistItemHeaders: newChecklistItemHeaders.reduce(
          (headers, newChecklistItemHeader) => upsert(headers, newChecklistItemHeader),
          projectForChecklist.checklistItemHeaders || []
        ),
      };
      const { byId } = normalize([project]);
      return {
        ...state,
        byId: {
          ...state.byId,
          ...byId,
        },
      };
    }
  )
  .case(completeChecklists.async.failed, (state) => {
    // TODO: should we remove from the list?
    return state;
  })
  .cases([createIssue.async.optimistic, createIssue.async.done], (state, { params: request, result: newIssue }) => {
    const projectForIssue = getProjectById(state, request.id);
    if (!projectForIssue) {
      return state;
    }

    const project = {
      ...projectForIssue,
      areas: (projectForIssue.areas || []).map((area) => {
        const tagInArea = area.tags?.find((t) => t.id === request.tagId);
        if (tagInArea) {
          return {
            ...area,
            tags: (area.tags || []).map((tag) => {
              if (tag.id === request.tagId) {
                return {
                  ...tag,
                  issues: upsert(tag.issues || [], newIssue),
                };
              } else {
                return tag;
              }
            }),
          };
        } else {
          return area;
        }
      }),
    };
    const { byId } = normalize([project]);
    return {
      ...state,
      byId: {
        ...state.byId,
        ...byId,
      },
    };
  })
  .case(createIssue.async.failed, (state) => {
    // TODO: should we remove from the list?
    return state;
  })
  .cases([upsertTag.async.optimistic, upsertTag.async.done], (state, { result: newTag }) => {
    const projectId = newTag.asset?.projectId!;
    const projectForTag = getProjectById(state, projectId);
    if (!projectForTag) return state;

    const project = {
      ...projectForTag,
      areas: (projectForTag.areas || []).map((area) => {
        if (newTag.areaId === area.id) {
          const tags = upsert(area.tags || [], newTag);
          return { ...area, tags };
        }
        return area;
      }),
    };
    const { byId } = normalize([project]);
    return {
      ...state,
      byId: { ...state.byId, ...byId },
    };
  })
  .case(upsertTag.async.failed, (state) => {
    // TODO: should we remove from the list?
    return state;
  })
  .case(updateDownloadedProjects.async.started, (state) => {
    return { ...state, isUpdatingDownloadedProjects: true };
  })
  .case(updateDownloadedProjects.async.failed, (state, { error }) => {
    return {
      ...state,
      isUpdatingDownloadedProjects: false,
      error,
    };
  })
  .case(updateDownloadedProjects.async.done, (state) => {
    return {
      ...state,
      isUpdatingDownloadedProjects: false,
    };
  });

export default reducer;
