import { reducerWithInitialState } from "typescript-fsa-reducers";
import { CurrentUserState } from "./types";
import { getCurrentUser, editUserProfile, confirmEmailChange, getEmailChange } from "./actions";

const initialState: CurrentUserState = {
  user: null,
  isLoadingUser: true,
  error: null,
  isChangingEmail: false,
  isGettingUserEmail: false,
  emails: undefined,
  isEditingUser: false,
};

const reducer = reducerWithInitialState(initialState)
  .case(getCurrentUser.async.started, (state) => ({
    ...state,
    isLoadingUser: true,
  }))
  .case(getCurrentUser.async.failed, (state, { error }) => ({
    ...state,
    isLoadingUser: false,
    error,
  }))
  .case(getCurrentUser.async.done, (state, { result: user }) => ({
    ...state,
    error: null,
    isLoadingUser: false,
    user,
  }))
  .case(editUserProfile.async.started, (state) => ({
    ...state,
    isEditingUser: true,
  }))
  .case(editUserProfile.async.failed, (state, { error }) => ({
    ...state,
    isEditingUser: false,
    error,
  }))
  .case(editUserProfile.async.done, (state) => ({
    ...state,
    error: null,
    isEditingUser: false,
  }))
  .case(confirmEmailChange.async.started, (state) => ({
    ...state,
    isChangingEmail: true,
  }))
  .case(confirmEmailChange.async.failed, (state, { error }) => ({
    ...state,
    isChangingEmail: false,
    error,
  }))
  .case(confirmEmailChange.async.done, (state) => ({
    ...state,
    isChangingEmail: false,
    error: null,
  }))
  .case(getEmailChange.async.started, (state) => ({
    ...state,
    isGettingUserEmail: true,
  }))
  .case(getEmailChange.async.failed, (state, { error }) => ({
    ...state,
    isGettingUserEmail: false,
    error,
  }))
  .case(getEmailChange.async.done, (state, { result: emails }) => ({
    ...state,
    isGettingUserEmail: false,
    error: null,
    emails: emails.data,
  }));

export default reducer;
