/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { Checklist, ChecklistFromJSON, ChecklistFromJSONTyped, ChecklistToJSON } from "./Checklist";
import { ExclusionList, ExclusionListFromJSON, ExclusionListFromJSONTyped, ExclusionListToJSON } from "./ExclusionList";

/**
 *
 * @export
 * @interface Phase
 */
export interface Phase {
  /**
   *
   * @type {number}
   * @memberof Phase
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof Phase
   */
  name?: string | null;
  /**
   *
   * @type {number}
   * @memberof Phase
   */
  projectId?: number;
  /**
   *
   * @type {Array<Checklist>}
   * @memberof Phase
   */
  checklists?: Array<Checklist> | null;
  /**
   *
   * @type {Array<ExclusionList>}
   * @memberof Phase
   */
  exclusionLists?: Array<ExclusionList> | null;
}

export function PhaseFromJSON(json: any): Phase {
  return PhaseFromJSONTyped(json, false);
}

export function PhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): Phase {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    checklists: !exists(json, "checklists")
      ? undefined
      : json["checklists"] === null
      ? null
      : (json["checklists"] as Array<any>).map(ChecklistFromJSON),
    exclusionLists: !exists(json, "exclusionLists")
      ? undefined
      : json["exclusionLists"] === null
      ? null
      : (json["exclusionLists"] as Array<any>).map(ExclusionListFromJSON),
  };
}

export function PhaseToJSON(value?: Phase | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    projectId: value.projectId,
    checklists:
      value.checklists === undefined
        ? undefined
        : value.checklists === null
        ? null
        : (value.checklists as Array<any>).map(ChecklistToJSON),
    exclusionLists:
      value.exclusionLists === undefined
        ? undefined
        : value.exclusionLists === null
        ? null
        : (value.exclusionLists as Array<any>).map(ExclusionListToJSON),
  };
}
