import { combineReducers } from "redux";
import { AdminAppState } from "./types";
import projectsReducer from "./projects/reducer";
import systemUsersReducer from "./systemUsers/reducer";

const reducer = combineReducers<AdminAppState>({
  projects: projectsReducer,
  systemUsers: systemUsersReducer,
});

export default reducer;
