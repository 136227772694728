/* eslint-disable @typescript-eslint/no-explicit-any */
import { persistStore, persistReducer, Persistor, createMigrate } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import { composeWithDevTools } from "redux-devtools-extension";
import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, createStore } from "redux";
import localForage from "localforage";
import { mapValues } from "lodash";
import { History } from "history";
import thunk from "redux-thunk";

import migrations from "store/migrations";
import { AppStore, AppState } from "store/types";
import { buildRootReducer } from "store/reducers";
import { TechnicianAppState } from "data/technician";
import { TechnicianUserOutboxState } from "data/technician/outbox";

const configureStore = (browserHistory: History): { store: AppStore; persistor: Persistor } => {
  const rootReducer = buildRootReducer(browserHistory);

  const middleware = applyMiddleware(thunk, routerMiddleware(browserHistory));

  const enhancers = composeWithDevTools(middleware);

  const persistConfig = {
    key: "technician-app",
    storage: localForage,
    whitelist: ["technician", "user"],
    debug: process.env.NODE_ENV !== "production",
    stateReconciler: (inboundState: any, originalState: any, reducedState: any, config: any): AppState => {
      const state = autoMergeLevel1(inboundState, originalState, reducedState, config);
      const { technician } = state;
      const { outbox } = technician as TechnicianAppState;
      const { byId } = outbox;

      const newState: AppState = {
        ...state,
        technician: {
          ...technician,
          projects: {
            ...technician.projects,
            byId: inboundState.technician.projects.byId,
            allIds: inboundState.technician.projects.allIds,
            isLoadingById: inboundState.technician.projects.isLoadingById,
          },
          outbox: {
            ...outbox,
            byId: mapValues(byId, (userOutbox: TechnicianUserOutboxState) => ({
              ...userOutbox,
              working: false,
              isRemovingDeadLetters: false,
            })),
          },
        },
      };
      return newState;
    },
    version: 20200220,
    migrate: createMigrate(migrations as any, { debug: process.env.NODE_ENV !== "production" }),
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(persistedReducer, enhancers);

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(persistedReducer));
  }

  const persistor = persistStore(store);
  return { store, persistor };
};

export { configureStore };
