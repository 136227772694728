import * as React from "react";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import useStyles from "./styles";
import PopoverMenu, { Item } from "components/PopoverMenu";

export interface SidebarProps {
  actions: {
    icon: JSX.Element;
    items?: Item[];
    onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
  }[];
  isOpen?: boolean;
}

const Sidebar = ({ actions, isOpen = true }: SidebarProps) => {
  const styles = useStyles({ isOpen });
  return (
    <Grid container className={styles.container} direction="column-reverse" alignItems="center">
      {isOpen && (
        <List>
          {actions.map(({ icon, items, onClick }, index) =>
            items ? (
              <PopoverMenu items={items} key={index}>
                {(isOpen, openPopover) => (
                  <ListItem
                    disableGutters
                    className={styles.item}
                    onClick={(e) => {
                      openPopover(e);
                      onClick && onClick(e);
                    }}
                  >
                    {icon}
                  </ListItem>
                )}
              </PopoverMenu>
            ) : (
              <ListItem key={index} disableGutters className={styles.item} onClick={onClick}>
                {icon}
              </ListItem>
            )
          )}
        </List>
      )}
    </Grid>
  );
};

export default Sidebar;
