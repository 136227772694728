import { TechnicianProjectsReducerState, ClientSideProject } from "./types";

export const getSelectedProject = (state: TechnicianProjectsReducerState): ClientSideProject | undefined => {
  const { selectedProject } = state;
  return selectedProject ? getProjectById(state, selectedProject) : undefined;
};

export const getProjectById = (
  { byId }: TechnicianProjectsReducerState,
  projectId: number
): ClientSideProject | undefined => {
  return byId[projectId];
};

export const getSelectedProjectLoading = ({
  selectedProject,
  isLoadingById,
}: TechnicianProjectsReducerState): boolean => {
  const isLoading = selectedProject ? isLoadingById[selectedProject] : undefined;
  return Boolean(isLoading);
};
