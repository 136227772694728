import { CheckboxClassKey, CheckboxProps } from "@material-ui/core/Checkbox";
import { CSSProperties } from "@material-ui/styles/withStyles";
import { blue, gray } from "styles/colors";

export const MuiCheckboxTheme: Partial<Record<CheckboxClassKey, CSSProperties>> = {
  colorSecondary: {
    color: gray,
    "&$checked": {
      color: blue,
    },
  },
};

export const MuiCheckboxProps: Partial<CheckboxProps> = {
  disableTouchRipple: true,
  disableRipple: true,
};
