import { actionCreatorFactory } from "typescript-fsa";
import { SnackbarProps } from "@material-ui/core/Snackbar";

const createAction = actionCreatorFactory("SNACKBAR");

const pushSnackbar = createAction<Partial<SnackbarProps>>("PUSH");

const removeSnackbar = createAction<Partial<SnackbarProps>>("REMOVE");

export { pushSnackbar, removeSnackbar };
