/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const PhaseCompletionStatus = {
  Started: "Started",
  NotStarted: "NotStarted",
  Completed: "Completed",
  Excluded: "Excluded",
} as const;
export type PhaseCompletionStatus = typeof PhaseCompletionStatus[keyof typeof PhaseCompletionStatus];

export function PhaseCompletionStatusFromJSON(json: any): PhaseCompletionStatus {
  return PhaseCompletionStatusFromJSONTyped(json, false);
}

export function PhaseCompletionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhaseCompletionStatus {
  return json as PhaseCompletionStatus;
}

export function PhaseCompletionStatusToJSON(value?: PhaseCompletionStatus | null): any {
  return value as any;
}
