/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BooleanControllerResponse,
  BooleanControllerResponseFromJSON,
  BooleanControllerResponseToJSON,
  ChangeEmailRequest,
  ChangeEmailRequestFromJSON,
  ChangeEmailRequestToJSON,
  ChangeEmailResponseControllerResponse,
  ChangeEmailResponseControllerResponseFromJSON,
  ChangeEmailResponseControllerResponseToJSON,
  ControllerResponse,
  ControllerResponseFromJSON,
  ControllerResponseToJSON,
  RegisterUserRequest,
  RegisterUserRequestFromJSON,
  RegisterUserRequestToJSON,
  StringControllerResponse,
  StringControllerResponseFromJSON,
  StringControllerResponseToJSON,
} from "../models";

export interface ApiV1RegisterEmailGetRequest {
  changeEmailId?: string;
}

export interface ApiV1RegisterEmailPostRequest {
  changeEmailRequest?: ChangeEmailRequest;
}

export interface ApiV1RegisterGetRequest {
  registrationId?: string;
}

export interface ApiV1RegisterPostRequest {
  registerUserRequest?: RegisterUserRequest;
}

/**
 *
 */
export class RegisterApi extends runtime.BaseAPI {
  /**
   */
  async apiV1RegisterEmailGetRaw(
    requestParameters: ApiV1RegisterEmailGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChangeEmailResponseControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.changeEmailId !== undefined) {
      queryParameters["changeEmailId"] = requestParameters.changeEmailId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Register/email`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChangeEmailResponseControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1RegisterEmailGet(
    requestParameters: ApiV1RegisterEmailGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChangeEmailResponseControllerResponse> {
    const response = await this.apiV1RegisterEmailGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1RegisterEmailPostRaw(
    requestParameters: ApiV1RegisterEmailPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Register/email`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ChangeEmailRequestToJSON(requestParameters.changeEmailRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1RegisterEmailPost(
    requestParameters: ApiV1RegisterEmailPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1RegisterEmailPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1RegisterGetRaw(
    requestParameters: ApiV1RegisterGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<StringControllerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.registrationId !== undefined) {
      queryParameters["registrationId"] = requestParameters.registrationId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Register`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => StringControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1RegisterGet(
    requestParameters: ApiV1RegisterGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<StringControllerResponse> {
    const response = await this.apiV1RegisterGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1RegisterPostRaw(
    requestParameters: ApiV1RegisterPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Register`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RegisterUserRequestToJSON(requestParameters.registerUserRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1RegisterPost(
    requestParameters: ApiV1RegisterPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1RegisterPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
