import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { blue } from "styles/colors";

export default makeStyles((theme: Theme) => ({
  container: (props: { isOpen: boolean }) => ({
    backgroundColor: blue,
    width: props.isOpen ? "5.5rem" : "1rem",
    height: "100vh",
    padding: ".5rem",
  }),
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "2.625rem",
    height: "2.625rem",
    borderRadius: "100%",
    border: `.25rem solid ${theme.palette.common.white}`,
    marginTop: ".5rem",
    "& svg": {
      fill: theme.palette.common.white,
    },
  },
}));
