/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ProjectRole, ProjectRoleFromJSON, ProjectRoleFromJSONTyped, ProjectRoleToJSON } from "./ProjectRole";
import { UserRole, UserRoleFromJSON, UserRoleFromJSONTyped, UserRoleToJSON } from "./UserRole";

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {UserRole}
   * @memberof User
   */
  role?: UserRole;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isAdmin?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isRegistered?: boolean;
  /**
   *
   * @type {Array<ProjectRole>}
   * @memberof User
   */
  projectRoles?: Array<ProjectRole> | null;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    email: !exists(json, "email") ? undefined : json["email"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    role: !exists(json, "role") ? undefined : UserRoleFromJSON(json["role"]),
    isAdmin: !exists(json, "isAdmin") ? undefined : json["isAdmin"],
    isActive: !exists(json, "isActive") ? undefined : json["isActive"],
    isRegistered: !exists(json, "isRegistered") ? undefined : json["isRegistered"],
    projectRoles: !exists(json, "projectRoles")
      ? undefined
      : json["projectRoles"] === null
      ? null
      : (json["projectRoles"] as Array<any>).map(ProjectRoleFromJSON),
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    email: value.email,
    phoneNumber: value.phoneNumber,
    role: UserRoleToJSON(value.role),
    isAdmin: value.isAdmin,
    isActive: value.isActive,
    isRegistered: value.isRegistered,
    projectRoles:
      value.projectRoles === undefined
        ? undefined
        : value.projectRoles === null
        ? null
        : (value.projectRoles as Array<any>).map(ProjectRoleToJSON),
  };
}
