/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ArchiveUserRequest
 */
export interface ArchiveUserRequest {
  /**
   *
   * @type {number}
   * @memberof ArchiveUserRequest
   */
  userId?: number;
  /**
   *
   * @type {boolean}
   * @memberof ArchiveUserRequest
   */
  archive?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ArchiveUserRequest
   */
  unarchive?: boolean | null;
}

export function ArchiveUserRequestFromJSON(json: any): ArchiveUserRequest {
  return ArchiveUserRequestFromJSONTyped(json, false);
}

export function ArchiveUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArchiveUserRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    archive: !exists(json, "archive") ? undefined : json["archive"],
    unarchive: !exists(json, "unarchive") ? undefined : json["unarchive"],
  };
}

export function ArchiveUserRequestToJSON(value?: ArchiveUserRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    archive: value.archive,
    unarchive: value.unarchive,
  };
}
