import { route } from "static-route-paths";

export const routes = route({
  root: route(),
  signup: route("signup", {
    verificationToken: route(":verificationToken"),
  }),
  login: route("login"),
  reset: route("reset"),
  terms: route("terms"),
  admin: route("admin", {
    project: route("project", {
      id: route(":id", {
        dashboard: route("dashboard"),
        tags: route("tags"),
        issues: route("issues"),
        attachments: route("attachments"),
      }),
    }),
  }),
  tech: route("tech", {
    project: route("project", {
      id: route(":id", {
        todo: route("todo"),
        issue: route("issues"),
        completed: route("completed"),
      }),
    }),
  }),
  user: route("profile", {
    id: route(":id"),
  }),
  users: route("user", {
    confirm: route("confirm", {
      id: route(":id"),
    }),
  }),
  notFound: route("notFound"),
});
