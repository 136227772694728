/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import { FlexField, FlexFieldFromJSON, FlexFieldFromJSONTyped, FlexFieldToJSON } from "./FlexField";

/**
 *
 * @export
 * @interface FlexFieldIEnumerableControllerResponse
 */
export interface FlexFieldIEnumerableControllerResponse {
  /**
   *
   * @type {Array<FlexField>}
   * @memberof FlexFieldIEnumerableControllerResponse
   */
  data?: Array<FlexField> | null;
  /**
   *
   * @type {ErrorResponse}
   * @memberof FlexFieldIEnumerableControllerResponse
   */
  error?: ErrorResponse;
}

export function FlexFieldIEnumerableControllerResponseFromJSON(json: any): FlexFieldIEnumerableControllerResponse {
  return FlexFieldIEnumerableControllerResponseFromJSONTyped(json, false);
}

export function FlexFieldIEnumerableControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): FlexFieldIEnumerableControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data")
      ? undefined
      : json["data"] === null
      ? null
      : (json["data"] as Array<any>).map(FlexFieldFromJSON),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function FlexFieldIEnumerableControllerResponseToJSON(
  value?: FlexFieldIEnumerableControllerResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data:
      value.data === undefined
        ? undefined
        : value.data === null
        ? null
        : (value.data as Array<any>).map(FlexFieldToJSON),
    error: ErrorResponseToJSON(value.error),
  };
}
