import { actionCreatorFactory } from "typescript-fsa";
import asyncFactory from "typescript-fsa-redux-thunk";
import { AnyAction } from "redux";
import retry from "async-retry";
import { AppState } from "store/types";
import { upsertTag, createIssue } from "../tags/actions";
import { completeChecklists } from "../checklists/actions";
import { getCurrentUserOutboxOrDefault } from "./selectors";
import { call } from "data/api/rest";
import { TechApi, SyncError } from "data/api";

const createAction = actionCreatorFactory("TECH/OUTBOX");
const createAsyncAction = asyncFactory<AppState>(createAction);

export const dequeue = createAsyncAction<void, void, Error>("DEQUEUE", async (params, dispatch, getState) =>
  retry(
    async () => {
      const {
        technician: { outbox: state },
      } = getState();
      const { queue } = getCurrentUserOutboxOrDefault(state);
      const { type, payload: request } = queue[0] || {};
      switch (type) {
        case upsertTag.type:
          await dispatch(upsertTag.persist(request));
          break;
        case createIssue.type:
          await dispatch(createIssue.persist(request));
          break;
        case completeChecklists.type:
          await dispatch(completeChecklists.persist(request));
          break;
        default: {
          console.warn("Queued Action was not found", type);
          break;
        }
      }
    },
    { retries: 5 }
  )
);

interface SyncErrorResponse {
  success: boolean;
  deadletter: AnyAction[];
}

export const postSyncErrors = createAsyncAction<AnyAction[], SyncErrorResponse, Error>(
  "SYNC_ERRORS_POST",
  async (deadletter) => {
    const syncError: SyncError = {
      message: JSON.stringify(deadletter),
    };
    const { data } = await call(TechApi).apiV1TechSyncerrorsPost({ syncError });
    return {
      success: data === "success",
      deadletter,
    };
  }
);
