/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateProjectRequest
 */
export interface UpdateProjectRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  name?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  opportunityNumber?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProjectRequest
   */
  isActive?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  netilionUserName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  netilionPassword?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  netilionApiKey?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProjectRequest
   */
  shouldSync?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdateProjectRequest
   */
  isDeleted?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateProjectRequest
   */
  updateableFlexFields?: string | null;
}

export function UpdateProjectRequestFromJSON(json: any): UpdateProjectRequest {
  return UpdateProjectRequestFromJSONTyped(json, false);
}

export function UpdateProjectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProjectRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    opportunityNumber: !exists(json, "opportunityNumber") ? undefined : json["opportunityNumber"],
    isActive: !exists(json, "isActive") ? undefined : json["isActive"],
    netilionUserName: !exists(json, "netilionUserName") ? undefined : json["netilionUserName"],
    netilionPassword: !exists(json, "netilionPassword") ? undefined : json["netilionPassword"],
    netilionApiKey: !exists(json, "netilionApiKey") ? undefined : json["netilionApiKey"],
    shouldSync: !exists(json, "shouldSync") ? undefined : json["shouldSync"],
    isDeleted: !exists(json, "isDeleted") ? undefined : json["isDeleted"],
    updateableFlexFields: !exists(json, "updateableFlexFields") ? undefined : json["updateableFlexFields"],
  };
}

export function UpdateProjectRequestToJSON(value?: UpdateProjectRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    opportunityNumber: value.opportunityNumber,
    isActive: value.isActive,
    netilionUserName: value.netilionUserName,
    netilionPassword: value.netilionPassword,
    netilionApiKey: value.netilionApiKey,
    shouldSync: value.shouldSync,
    isDeleted: value.isDeleted,
    updateableFlexFields: value.updateableFlexFields,
  };
}
