/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface Image
 */
export interface Image {
  /**
   *
   * @type {string}
   * @memberof Image
   */
  imageId?: string;
  /**
   *
   * @type {string}
   * @memberof Image
   */
  fileExtension?: string | null;
  /**
   *
   * @type {Date}
   * @memberof Image
   */
  createdDate?: Date;
  /**
   *
   * @type {string}
   * @memberof Image
   */
  url?: string | null;
}

export function ImageFromJSON(json: any): Image {
  return ImageFromJSONTyped(json, false);
}

export function ImageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Image {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    imageId: !exists(json, "imageId") ? undefined : json["imageId"],
    fileExtension: !exists(json, "fileExtension") ? undefined : json["fileExtension"],
    createdDate: !exists(json, "createdDate") ? undefined : new Date(json["createdDate"]),
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function ImageToJSON(value?: Image | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    imageId: value.imageId,
    fileExtension: value.fileExtension,
    createdDate: value.createdDate === undefined ? undefined : value.createdDate.toISOString(),
    url: value.url,
  };
}
