import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import auth0 from "auth0-js";
import { CustomWindow, AuthStateActionContext, AuthData } from "./types";
import { isAuthenticated, saveAuthTokenToLocalStorage, getAuthTokenFromLocalStorage } from "./utils";
import { verifyUserRegistration, completeInvite } from "./actions";
import { RegisterUserRequest } from "data/api";

export const AuthContext = React.createContext({} as AuthStateActionContext);

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const runtime: CustomWindow = window;
  const client = new auth0.WebAuth({
    domain: runtime && runtime.env ? runtime.env.REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN || "",
    clientID:
      runtime && runtime.env ? runtime.env.REACT_APP_AUTH0_CLIENT_ID : process.env.REACT_APP_AUTH0_CLIENT_ID || "",
    redirectUri: window.location.origin,
    responseType: "token id_token",
    scope: "openid profile email",
  });
  const [auth, setAuth] = useState<AuthData>(getAuthTokenFromLocalStorage());

  const state = useMemo(
    () => ({
      ...auth,
      isAuthenticated: isAuthenticated(auth),
    }),
    [auth]
  );

  const dispatch = {
    resetPassword: useCallback((email: string | undefined) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => resolve(email), 1000);
      });
    }, []),
    login: useCallback(() => {
      client.authorize();
    }, [client]),
    logout: useCallback(() => {
      client.logout({ returnTo: window.location.origin });
      localStorage.removeItem("auth");
    }, [client]),
    verifyUserRegistration: (guid: string) => verifyUserRegistration({ registrationId: guid }),
    completeInvite: (request: RegisterUserRequest) => completeInvite(request),
  };

  const context = useMemo(
    () => ({
      auth: state,
      actions: dispatch,
    }),
    [state, dispatch]
  );

  useEffect(() => {
    client.parseHash({ hash: location.hash }, (err, authResult) => {
      if (authResult) {
        setAuth(saveAuthTokenToLocalStorage(authResult!));
        history.replace("/");
      }
    });
  }, [client, history, location]);

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
