/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateUserRequest
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  email?: string | null;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
  return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUserRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    email: !exists(json, "email") ? undefined : json["email"],
  };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    firstName: value.firstName,
    lastName: value.lastName,
    phoneNumber: value.phoneNumber,
    email: value.email,
  };
}
