/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface AssetType
 */
export interface AssetType {
  /**
   *
   * @type {number}
   * @memberof AssetType
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof AssetType
   */
  name?: string | null;
}

export function AssetTypeFromJSON(json: any): AssetType {
  return AssetTypeFromJSONTyped(json, false);
}

export function AssetTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function AssetTypeToJSON(value?: AssetType | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
  };
}
