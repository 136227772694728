import React, { lazy } from "react";
import { Route, Switch } from "react-router";
import { CircularProgress } from "@material-ui/core";
import Layout from "components/Layout";
import { routes } from "./routes";

const SignIn = lazy(() => import("ui/Login/SignIn"));
const CompleteInvite = lazy(() => import("ui/Login/CompleteInvite"));
const ResetPassword = lazy(() => import("ui/Login/ResetPassword"));
const NotFound = lazy(() => import("ui/Login/NotFound"));
const EmailChange = lazy(() => import("ui/Users/EmailChangeConfirmation/index"));

const UnauthenticatedApp: React.FC = () => {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <Layout showSidebar>
        <Switch>
          <Route exact path={routes.signup.verificationToken.path} component={CompleteInvite} />
          <Route exact path={routes.login.path} component={SignIn} />
          <Route exact path={routes.reset.path} component={ResetPassword} />
          <Route exact path={routes.notFound.path} component={NotFound} />
          <Route path={routes.users.confirm.id.path} component={EmailChange} />
          <Route component={SignIn} />
        </Switch>
      </Layout>
    </React.Suspense>
  );
};

export default UnauthenticatedApp;
