import { TechnicianOutboxReducerState, TechnicianUserOutboxState } from "./types";

export const getCurrentUserOutbox = (state: TechnicianOutboxReducerState): TechnicianUserOutboxState | undefined => {
  const { currentUserId, byId } = state;
  return currentUserId ? byId[currentUserId] : undefined;
};

export const getCurrentUserOutboxOrDefault = (state: TechnicianOutboxReducerState): TechnicianUserOutboxState => {
  const userOutbox = getCurrentUserOutbox(state);
  const defaultOutbox: TechnicianUserOutboxState = {
    working: false,
    queue: [],
    deadletter: [],
    isRemovingDeadLetters: false,
  };
  return userOutbox || defaultOutbox;
};
