import { BaseAPI, Configuration, Middleware, ResponseContext, RequestContext, FetchParams } from "./runtime";
import { getIdentityToken } from "data/auth/utils";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Fetch = require("whatwg-fetch");

type ApiConstructor<T extends BaseAPI> = new (config: Configuration) => T;

const authenticatedRequestMiddleware: Middleware = {
  pre: async ({ url, init }: RequestContext): Promise<FetchParams | void> => {
    return await {
      url,
      init: {
        ...init,
        headers: {
          ...init.headers,
          Authorization: `Bearer ${getIdentityToken()}`,
        },
      },
    };
  },
};

const unauthenticatedResponseHandlerMiddleware: Middleware = {
  post: async (context: ResponseContext): Promise<Response | void> => {
    if (context.response.status === 401) {
      console.warn("Received 401 from API, redirecting to login service");
      window.location.assign(`/logout`);
    }
  },
};

const call = <T extends BaseAPI>(
  api: ApiConstructor<T>,
  middleware: Middleware[] = [authenticatedRequestMiddleware, unauthenticatedResponseHandlerMiddleware]
): T => {
  return new api(
    new Configuration({
      fetchApi: Fetch.fetch,
      basePath: window.origin,
      middleware,
    })
  );
};

export { call };
