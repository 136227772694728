import * as React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { useSnackbar } from "data/snackbar/hooks";
//TODO: decouple this from the data layer or move to the UI layer
const GlobalSnackbar = () => {
  const [{ snackbars }, { remove }] = useSnackbar();
  return (
    <>
      {snackbars.map((snackbar) => (
        <Snackbar
          open={snackbar.open || true}
          autoHideDuration={3000}
          key={snackbar.key}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          {...snackbar}
          onClose={(e, reason) => {
            if (reason !== "clickaway") {
              snackbar.onClose && snackbar.onClose(e, reason);
              remove(snackbar);
            }
          }}
        />
      ))}
    </>
  );
};

export default GlobalSnackbar;
