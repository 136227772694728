/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BooleanControllerResponse,
  BooleanControllerResponseFromJSON,
  BooleanControllerResponseToJSON,
  ChecklistItemHeader,
  ChecklistItemHeaderFromJSON,
  ChecklistItemHeaderToJSON,
  ChecklistItemHeaderControllerResponse,
  ChecklistItemHeaderControllerResponseFromJSON,
  ChecklistItemHeaderControllerResponseToJSON,
  ControllerResponse,
  ControllerResponseFromJSON,
  ControllerResponseToJSON,
  IssueImageControllerResponse,
  IssueImageControllerResponseFromJSON,
  IssueImageControllerResponseToJSON,
  ProjectControllerResponse,
  ProjectControllerResponseFromJSON,
  ProjectControllerResponseToJSON,
  ProjectListControllerResponse,
  ProjectListControllerResponseFromJSON,
  ProjectListControllerResponseToJSON,
  SyncError,
  SyncErrorFromJSON,
  SyncErrorToJSON,
  Tag,
  TagFromJSON,
  TagToJSON,
  TagControllerResponse,
  TagControllerResponseFromJSON,
  TagControllerResponseToJSON,
  TagImageControllerResponse,
  TagImageControllerResponseFromJSON,
  TagImageControllerResponseToJSON,
  TagIssue,
  TagIssueFromJSON,
  TagIssueToJSON,
  TagIssueControllerResponse,
  TagIssueControllerResponseFromJSON,
  TagIssueControllerResponseToJSON,
} from "../models";

import { TagAttachmentControllerResponse, TagAttachmentControllerResponseFromJSON } from "../models/TagAttachmentControllerResponse"

export interface ApiV1TechProjectsIdChecklistsPutRequest {
  id: number;
  checklistItemHeader?: ChecklistItemHeader;
}

export interface ApiV1TechProjectsIdGetRequest {
  id: number;
}

export interface ApiV1TechProjectsIdTagsPutRequest {
  id: number;
  tag?: Tag;
}

export interface ApiV1TechProjectsIdTagsTagIdImagesImageIdDeleteRequest {
  id: number;
  tagId: string;
  imageId: string;
}

export interface ApiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDeleteRequest {
  id: number;
  tagId: string;
  attachmentId: string;
}

export interface ApiV1TechProjectsIdTagsTagIdImagesPostRequest {
  id: number;
  tagId: string;
  image?: Blob;
}

export interface ApiV1TechProjectsIdTagsTagIdAttachmentsPostRequest {
  id: number;
  tagId: string;
  file?: Blob;
}

export interface ApiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPostRequest {
  id: number;
  tagId: string;
  issueId: string;
  image?: Blob;
}

export interface ApiV1TechProjectsIdTagsTagIdIssuesPostRequest {
  id: number;
  tagId: string;
  tagIssue?: TagIssue;
}

export interface ApiV1TechSyncerrorsPostRequest {
  syncError?: SyncError;
}

/**
 *
 */
export class TechApi extends runtime.BaseAPI {
  /**
   */
  async apiV1TechProjectsGetRaw(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ProjectListControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProjectListControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechProjectsGet(
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ProjectListControllerResponse> {
    const response = await this.apiV1TechProjectsGetRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1TechProjectsIdChecklistsPutRaw(
    requestParameters: ApiV1TechProjectsIdChecklistsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ChecklistItemHeaderControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdChecklistsPut."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/checklists`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: ChecklistItemHeaderToJSON(requestParameters.checklistItemHeader),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChecklistItemHeaderControllerResponseFromJSON(jsonValue)
    );
  }

  /**
   */
  async apiV1TechProjectsIdChecklistsPut(
    requestParameters: ApiV1TechProjectsIdChecklistsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ChecklistItemHeaderControllerResponse> {
    const response = await this.apiV1TechProjectsIdChecklistsPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1TechProjectsIdGetRaw(
    requestParameters: ApiV1TechProjectsIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ProjectControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdGet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ProjectControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechProjectsIdGet(
    requestParameters: ApiV1TechProjectsIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ProjectControllerResponse> {
    const response = await this.apiV1TechProjectsIdGetRaw(requestParameters, initOverrides);
    console.log("apiV1TechProjectsIdGet", response);
    return await response.value();
  }

  /**
   */
  async apiV1TechProjectsIdTagsPutRaw(
    requestParameters: ApiV1TechProjectsIdTagsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdTagsPut."
      );
    }

    console.log("apiV1TechProjectsIdTagsPutRaw", requestParameters);

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/tags`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: TagToJSON(requestParameters.tag),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechProjectsIdTagsPut(
    requestParameters: ApiV1TechProjectsIdTagsPutRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagControllerResponse> {
    const response = await this.apiV1TechProjectsIdTagsPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdImagesImageIdDeleteRaw(
    requestParameters: ApiV1TechProjectsIdTagsTagIdImagesImageIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdTagsTagIdImagesImageIdDelete."
      );
    }

    if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
      throw new runtime.RequiredError(
        "tagId",
        "Required parameter requestParameters.tagId was null or undefined when calling apiV1TechProjectsIdTagsTagIdImagesImageIdDelete."
      );
    }

    if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
      throw new runtime.RequiredError(
        "imageId",
        "Required parameter requestParameters.imageId was null or undefined when calling apiV1TechProjectsIdTagsTagIdImagesImageIdDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/tags/{tagId}/images/{imageId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId)))
          .replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  async apiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDeleteRaw(
    requestParameters: ApiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<BooleanControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDelete."
      );
    }

    if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
      throw new runtime.RequiredError(
        "tagId",
        "Required parameter requestParameters.tagId was null or undefined when calling apiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDelete."
      );
    }

    if (requestParameters.attachmentId === null || requestParameters.attachmentId === undefined) {
      throw new runtime.RequiredError(
        "imageId",
        "Required parameter requestParameters.imageId was null or undefined when calling apiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDelete."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/tags/{tagId}/attachments/{attachmentId}`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId)))
          .replace(`{${"attachmentId"}}`, encodeURIComponent(String(requestParameters.attachmentId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => BooleanControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdImagesImageIdDelete(
    requestParameters: ApiV1TechProjectsIdTagsTagIdImagesImageIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1TechProjectsIdTagsTagIdImagesImageIdDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  async apiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDelete(
    requestParameters: ApiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<BooleanControllerResponse> {
    const response = await this.apiV1TechProjectsIdTagsTagIdAttachmentsAttachmentIdDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdImagesPostRaw(
    requestParameters: ApiV1TechProjectsIdTagsTagIdImagesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagImageControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdTagsTagIdImagesPost."
      );
    }

    if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
      throw new runtime.RequiredError(
        "tagId",
        "Required parameter requestParameters.tagId was null or undefined when calling apiV1TechProjectsIdTagsTagIdImagesPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append("Image", requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/tags/{tagId}/images`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagImageControllerResponseFromJSON(jsonValue));
  }

  async apiV1TechProjectsIdTagsTagIdAttachmentsPostRaw(
    requestParameters: ApiV1TechProjectsIdTagsTagIdAttachmentsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagAttachmentControllerResponse>> {

    console.log("apiV1TechProjectsIdTagsTagIdAttachmentsPostRaw");

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdTagsTagIdAtachmentsPost."
      );
    }

    if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
      throw new runtime.RequiredError(
        "tagId",
        "Required parameter requestParameters.tagId was null or undefined when calling apiV1TechProjectsIdTagsTagIdAttachmentsPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file !== undefined) {
      formParams.append("file", requestParameters.file as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/tags/{tagId}/attachments`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagAttachmentControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdImagesPost(
    requestParameters: ApiV1TechProjectsIdTagsTagIdImagesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagImageControllerResponse> {
    const response = await this.apiV1TechProjectsIdTagsTagIdImagesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  async apiV1TechProjectsIdTagsTagIdAttachmentsPost(
    requestParameters: ApiV1TechProjectsIdTagsTagIdAttachmentsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagAttachmentControllerResponse> {
    const response = await this.apiV1TechProjectsIdTagsTagIdAttachmentsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPostRaw(
    requestParameters: ApiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<IssueImageControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPost."
      );
    }

    if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
      throw new runtime.RequiredError(
        "tagId",
        "Required parameter requestParameters.tagId was null or undefined when calling apiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPost."
      );
    }

    if (requestParameters.issueId === null || requestParameters.issueId === undefined) {
      throw new runtime.RequiredError(
        "issueId",
        "Required parameter requestParameters.issueId was null or undefined when calling apiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [{ contentType: "multipart/form-data" }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.image !== undefined) {
      formParams.append("Image", requestParameters.image as any);
    }

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/tags/{tagId}/issues/{issueId}/images`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId)))
          .replace(`{${"issueId"}}`, encodeURIComponent(String(requestParameters.issueId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => IssueImageControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPost(
    requestParameters: ApiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<IssueImageControllerResponse> {
    const response = await this.apiV1TechProjectsIdTagsTagIdIssuesIssueIdImagesPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdIssuesPostRaw(
    requestParameters: ApiV1TechProjectsIdTagsTagIdIssuesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<TagIssueControllerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling apiV1TechProjectsIdTagsTagIdIssuesPost."
      );
    }

    if (requestParameters.tagId === null || requestParameters.tagId === undefined) {
      throw new runtime.RequiredError(
        "tagId",
        "Required parameter requestParameters.tagId was null or undefined when calling apiV1TechProjectsIdTagsTagIdIssuesPost."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Tech/projects/{id}/tags/{tagId}/issues`
          .replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id)))
          .replace(`{${"tagId"}}`, encodeURIComponent(String(requestParameters.tagId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TagIssueToJSON(requestParameters.tagIssue),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => TagIssueControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechProjectsIdTagsTagIdIssuesPost(
    requestParameters: ApiV1TechProjectsIdTagsTagIdIssuesPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<TagIssueControllerResponse> {
    const response = await this.apiV1TechProjectsIdTagsTagIdIssuesPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   */
  async apiV1TechSyncerrorsPostRaw(
    requestParameters: ApiV1TechSyncerrorsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<runtime.ApiResponse<ControllerResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json-patch+json";

    const response = await this.request(
      {
        path: `/api/v1/Tech/syncerrors`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: SyncErrorToJSON(requestParameters.syncError),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ControllerResponseFromJSON(jsonValue));
  }

  /**
   */
  async apiV1TechSyncerrorsPost(
    requestParameters: ApiV1TechSyncerrorsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverideFunction
  ): Promise<ControllerResponse> {
    const response = await this.apiV1TechSyncerrorsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
