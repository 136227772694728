/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ArchiveProjectUserRequest
 */
export interface ArchiveProjectUserRequest {
  /**
   *
   * @type {number}
   * @memberof ArchiveProjectUserRequest
   */
  userId?: number;
  /**
   *
   * @type {number}
   * @memberof ArchiveProjectUserRequest
   */
  projectId?: number;
  /**
   *
   * @type {boolean}
   * @memberof ArchiveProjectUserRequest
   */
  archive?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof ArchiveProjectUserRequest
   */
  unarchive?: boolean | null;
}

export function ArchiveProjectUserRequestFromJSON(json: any): ArchiveProjectUserRequest {
  return ArchiveProjectUserRequestFromJSONTyped(json, false);
}

export function ArchiveProjectUserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ArchiveProjectUserRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userId: !exists(json, "userId") ? undefined : json["userId"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    archive: !exists(json, "archive") ? undefined : json["archive"],
    unarchive: !exists(json, "unarchive") ? undefined : json["unarchive"],
  };
}

export function ArchiveProjectUserRequestToJSON(value?: ArchiveProjectUserRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userId: value.userId,
    projectId: value.projectId,
    archive: value.archive,
    unarchive: value.unarchive,
  };
}
