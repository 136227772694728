import migration20200205 from "./20200205";
import migration20200210 from "./20200210";
import migration20200220 from "./20200220";
import { MigrationManifest } from "./type";

const migrations: MigrationManifest = {
  20200205: migration20200205,
  20200210: migration20200210,
  20200220: migration20200220,
};

export default migrations;
