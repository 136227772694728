/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ChangeEmailResponse,
  ChangeEmailResponseFromJSON,
  ChangeEmailResponseFromJSONTyped,
  ChangeEmailResponseToJSON,
} from "./ChangeEmailResponse";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";

/**
 *
 * @export
 * @interface ChangeEmailResponseControllerResponse
 */
export interface ChangeEmailResponseControllerResponse {
  /**
   *
   * @type {ChangeEmailResponse}
   * @memberof ChangeEmailResponseControllerResponse
   */
  data?: ChangeEmailResponse;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ChangeEmailResponseControllerResponse
   */
  error?: ErrorResponse;
}

export function ChangeEmailResponseControllerResponseFromJSON(json: any): ChangeEmailResponseControllerResponse {
  return ChangeEmailResponseControllerResponseFromJSONTyped(json, false);
}

export function ChangeEmailResponseControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChangeEmailResponseControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : ChangeEmailResponseFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ChangeEmailResponseControllerResponseToJSON(value?: ChangeEmailResponseControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ChangeEmailResponseToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
