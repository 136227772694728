import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles, Theme } from "@material-ui/core";
import { Link as LinkRouterDom } from "react-router-dom";
import { routes } from "ui/routes";
import { useTranslation } from "react-i18next";

const useClasses = makeStyles((theme: Theme) => ({
  list: {
    display: "flex",
    height: "2rem",
    padding: 0,
  },
  item: {
    display: "inline",
    width: "auto",
    padding: 0,
    position: "relative",
    "&:not(:last-child)::after": {
      content: "''",
      position: "absolute",
      margin: ".35rem .6rem 0 .6rem",
      width: ".0625rem",
      height: ".7rem",
      backgroundColor: theme.palette.common.black,
    },
    "&:not(:last-child)": {
      marginRight: "1.2rem",
    },
    "& a": {
      textDecoration: "none",
    },
  },
}));

const Footer = () => {
  const classes = useClasses();
  const [t] = useTranslation();
  return (
    <List className={classes.list}>
      <ListItem className={classes.item}>
        <Typography variant="caption">{t("footer.copyright")}</Typography>
      </ListItem>
      <ListItem className={classes.item}>
        <LinkRouterDom to={routes.terms.path}>
          <Typography variant="caption" color="primary">
            {t("footer.terms")}
          </Typography>
        </LinkRouterDom>
      </ListItem>
      <ListItem className={classes.item}>
        <Link
          data-testid="privacy-policy-test-id"
          href="https://netilion.endress.com/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography variant="caption" color="primary">
            {t("footer.privacy")}
          </Typography>
        </Link>
      </ListItem>
    </List>
  );
};

export default Footer;
