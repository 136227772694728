import { reducerWithInitialState } from "typescript-fsa-reducers";
import { pushSnackbar, removeSnackbar } from "./actions";
import { SnackbarReducerState } from "./types";

const initialState: SnackbarReducerState = {
  snackbars: [],
};

const snackbarReducer = reducerWithInitialState(initialState)
  .case(pushSnackbar, ({ snackbars }, snackbar) => ({
    snackbars: snackbars.concat({ ...snackbar, key: String(Math.random()) }).slice(-3),
  }))
  .case(removeSnackbar, ({ snackbars }, toBeRemovedSnackbar) => ({
    snackbars: snackbars.map((snackbar) =>
      snackbar === toBeRemovedSnackbar ? { ...snackbar, open: false } : snackbar
    ),
  }));

export default snackbarReducer;
