/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface UploadTag
 */
export interface UploadTag {
  /**
   *
   * @type {string}
   * @memberof UploadTag
   */
  tagId?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTag
   */
  area?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTag
   */
  manufacturer?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTag
   */
  model?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTag
   */
  serialNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTag
   */
  assetType?: string | null;
  /**
   *
   * @type {string}
   * @memberof UploadTag
   */
  flexFields?: string | null;
}

export function UploadTagFromJSON(json: any): UploadTag {
  return UploadTagFromJSONTyped(json, false);
}

export function UploadTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadTag {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    tagId: !exists(json, "tagId") ? undefined : json["tagId"],
    area: !exists(json, "area") ? undefined : json["area"],
    manufacturer: !exists(json, "manufacturer") ? undefined : json["manufacturer"],
    model: !exists(json, "model") ? undefined : json["model"],
    serialNumber: !exists(json, "serialNumber") ? undefined : json["serialNumber"],
    assetType: !exists(json, "assetType") ? undefined : json["assetType"],
    flexFields: !exists(json, "flexFields") ? undefined : json["flexFields"],
  };
}

export function UploadTagToJSON(value?: UploadTag | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    tagId: value.tagId,
    area: value.area,
    manufacturer: value.manufacturer,
    model: value.model,
    serialNumber: value.serialNumber,
    assetType: value.assetType,
    flexFields: value.flexFields,
  };
}
