import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import snackbarReducer from "data/snackbar/reducer";
import adminReducer from "data/admin/reducer";
import technicianReducer from "data/technician/reducer";
import userReducer from "data/user/reducer";
import { AppState } from "./types";

const buildRootReducer = (history: History) =>
  combineReducers<AppState>({
    router: connectRouter(history),
    snackbar: snackbarReducer,
    admin: adminReducer,
    technician: technicianReducer,
    user: userReducer,
  });

export { buildRootReducer };
