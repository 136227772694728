import { TypographyClassKey } from "@material-ui/core/Typography";
import { CSSProperties } from "@material-ui/styles/withStyles";

export const MuiTypographyTheme: Partial<Record<TypographyClassKey, CSSProperties>> = {
  root: {
    textRendering: "optimizeLegibility",
    fontFeatureSettings: "'liga' 'pnum' 'kern'",
  },
  button: {
    color: "inherit",
  },
};
