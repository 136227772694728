import { ExpansionPanelClassKey } from "@material-ui/core/ExpansionPanel";
import { ExpansionPanelDetailsClassKey } from "@material-ui/core/ExpansionPanelDetails";
import { ExpansionPanelSummaryClassKey } from "@material-ui/core/ExpansionPanelSummary";
import { CSSProperties } from "@material-ui/styles/withStyles";
import { grayLight2 } from "styles/colors";

export const MuiExpansionPanelTheme: Partial<Record<ExpansionPanelClassKey, CSSProperties>> = {
  root: {
    padding: 0,
    position: "initial",
    boxShadow: "none",
    "&$expanded": {
      margin: "0 0 1rem 0",
      padding: 0,
    },
  },
};

export const MuiExpansionPanelSummaryTheme: Partial<Record<ExpansionPanelSummaryClassKey, CSSProperties>> = {
  root: {
    padding: "0 1.25rem",
    backgroundColor: grayLight2,
  },
};

export const MuiExpansionPanelDetailsTheme: Partial<Record<ExpansionPanelDetailsClassKey, CSSProperties>> = {
  root: {
    margin: 0,
    padding: 0,
  },
};
