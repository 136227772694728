/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ChecklistItemDetail,
  ChecklistItemDetailFromJSON,
  ChecklistItemDetailFromJSONTyped,
  ChecklistItemDetailToJSON,
} from "./ChecklistItemDetail";
import { DataType, DataTypeFromJSON, DataTypeFromJSONTyped, DataTypeToJSON } from "./DataType";

/**
 *
 * @export
 * @interface ChecklistItemTemplate
 */
export interface ChecklistItemTemplate {
  /**
   *
   * @type {number}
   * @memberof ChecklistItemTemplate
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChecklistItemTemplate
   */
  text?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof ChecklistItemTemplate
   */
  isRequired?: boolean;
  /**
   *
   * @type {number}
   * @memberof ChecklistItemTemplate
   */
  order?: number;
  /**
   *
   * @type {number}
   * @memberof ChecklistItemTemplate
   */
  checklistSectionId?: number;
  /**
   *
   * @type {DataType}
   * @memberof ChecklistItemTemplate
   */
  dataType?: DataType;
  /**
   *
   * @type {Array<ChecklistItemDetail>}
   * @memberof ChecklistItemTemplate
   */
  checklistItemDetails?: Array<ChecklistItemDetail> | null;
}

export function ChecklistItemTemplateFromJSON(json: any): ChecklistItemTemplate {
  return ChecklistItemTemplateFromJSONTyped(json, false);
}

export function ChecklistItemTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChecklistItemTemplate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    text: !exists(json, "text") ? undefined : json["text"],
    isRequired: !exists(json, "isRequired") ? undefined : json["isRequired"],
    order: !exists(json, "order") ? undefined : json["order"],
    checklistSectionId: !exists(json, "checklistSectionId") ? undefined : json["checklistSectionId"],
    dataType: !exists(json, "dataType") ? undefined : DataTypeFromJSON(json["dataType"]),
    checklistItemDetails: !exists(json, "checklistItemDetails")
      ? undefined
      : json["checklistItemDetails"] === null
      ? null
      : (json["checklistItemDetails"] as Array<any>).map(ChecklistItemDetailFromJSON),
  };
}

export function ChecklistItemTemplateToJSON(value?: ChecklistItemTemplate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    text: value.text,
    isRequired: value.isRequired,
    order: value.order,
    checklistSectionId: value.checklistSectionId,
    dataType: DataTypeToJSON(value.dataType),
    checklistItemDetails:
      value.checklistItemDetails === undefined
        ? undefined
        : value.checklistItemDetails === null
        ? null
        : (value.checklistItemDetails as Array<any>).map(ChecklistItemDetailToJSON),
  };
}
