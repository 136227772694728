import React, { createContext, lazy, useEffect, useMemo } from "react";
import { Route, Switch, Redirect } from "react-router";
import { CircularProgress } from "@material-ui/core";
import { useCurrentUser } from "data/user";
import { routes } from "./routes";
import Layout from "components/Layout";
import canActAsTechnician from "../utils/canActAsTechnician";
import canActAsProjectManager from "../utils/canActAsProjectManager";
import { ProjectManagerContext } from "./ProjectManagerContext";

const AdminLayout = lazy(() => import("ui/Admin"));
const TechLayout = lazy(() => import("ui/Techician"));
const EmailChange = lazy(() => import("ui/Users/EmailChangeConfirmation/index"));

const AuthenticatedApp: React.FC = () => {
  const [{ user, isLoadingUser }, { getCurrentUser }] = useCurrentUser();
  const canViewAsProjectManager = useMemo(() => (user ? canActAsProjectManager(user) : false), [user]);
  const canViewAsTechnician = useMemo(() => (user ? canActAsTechnician(user) : false), [user]);
  const defaultPath = useMemo(() => {
    if (canViewAsProjectManager) {
      return routes.admin.path;
    } else if (canViewAsTechnician) {
      return routes.tech.path;
    } else {
      return routes.root.path;
    }
  }, [canViewAsProjectManager, canViewAsTechnician]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  if (isLoadingUser) {
    return <CircularProgress />;
  }

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <React.Suspense fallback={<CircularProgress />}>
      <Switch>
        <ProjectManagerContext.Provider value={canViewAsProjectManager}>
          <Route
            path={routes.users.confirm.id.path}
            render={() => (
              <Layout showSidebar>
                <EmailChange />
              </Layout>
            )}
          />
          {canViewAsProjectManager && <Route path={routes.admin.path} component={AdminLayout} />}
          {canViewAsTechnician && <Route path={routes.tech.path} component={TechLayout} />}
        
          <Redirect to={defaultPath} />
        </ProjectManagerContext.Provider>
      </Switch>
    </React.Suspense>
  );
};

export default AuthenticatedApp;
