import { reducerWithInitialState } from "typescript-fsa-reducers";
import {
  getProjectById,
  editProjectMember,
  changeProjectMemberAccess,
  inviteTeamMember,
  uploadTagSheetCSV,
  verifyTagSheetCSV,
  exportTagSheetCSV,
  getProjects,
  createProject,
  createChecklist,
  updateChecklist,
  assignTagToTechs,
  getKpiById,
  closeProject,
  deleteProject,
  getProjectAreas,
  createPhase,
  assignAreaToTechs,
  getProjectPhases,
  resetError,
  getProjectIssues,
  updateProjectIssue,
  getTagById,
  getProjectHandoverLink,
  deleteChecklist,
  getChecklistsAvailableForCopying,
  copyChecklist,
  updatePhaseName,
  generateChecklistReport,
  createOrUpdateExclusionList,
  updateProjectTag,
  getFlexFieldById,
  updateUpdateableFlexFields,
} from "./actions";
import { AdminProjectsState } from "data/admin/projects";
import { Project, Phase, User, UserRole } from "data/api";
import getProjectRoleForUserById from "../../../utils/getProjectRoleForUserById";

const initialState: AdminProjectsState = {
  data: [],
  project: {
    id: 1,
    isActive: false,
    isDeleted: false,
  },
  kpis: [],
  tag: {},
  issues: [],
  isLoadingKpis: false,
  isLoadingProjectAreas: false,
  isLoadingTag: false,
  isLoadingProjectPhases: false,
  isLoadingProjectIssues: false,
  isLoadingProjects: false,
  isCreatingProject: false,
  isEditing: false,
  isRemoving: false,
  isDeletingProject: false,
  isResending: false,
  isInviting: false,
  isUploadingCSV: false,
  isExportingCSV: false,
  isCreatingPhases: false,
  isCreatingChecklist: false,
  isCsvUploaded: false,
  isUpdatingIssue: false,
  isIssueUpdated: false,
  isGeneratingChecklistReport: false,
  error: null,
  handoverPackageLink: "",
  shouldUpdateProjectPhases: false,
  isDeletingChecklist: false,
  checklistsToCopy: [],
  isCopyingChecklist: false,
  isUpdatingPhase: false,
  completedChecklistPackageLink: "",
};

const projectsReducer = reducerWithInitialState(initialState)
  .case(getProjects.async.started, (state) => ({
    ...state,
    isLoadingProjects: true,
  }))
  .case(getProjects.async.failed, (state, { error }) => ({
    ...state,
    isLoadingProjects: false,
    error,
  }))
  .case(getProjects.async.done, (state, { result }) => ({
    ...state,
    error: null,
    isLoadingProjects: false,
    data: result.data || [],
  }))
  .case(getProjectById, (state, id) => {
    const selectedProject = state.data.filter((project: Project) => project.id === id);
    return {
      ...state,
      error: null,
      project: selectedProject[0],
    };
  })
  .case(getProjectAreas.async.started, (state) => ({
    ...state,
    isLoadingProjectAreas: true,
  }))
  .case(getProjectAreas.async.failed, (state, { error }) => ({
    ...state,
    isLoadingProjectAreas: false,
  }))
  .case(getProjectAreas.async.done, (state, { result: areas }) => ({
    ...state,
    error: null,
    isLoadingProjectAreas: false,
    isCsvUploaded: false,
    project: {
      ...state.project,
      areas,
    },
  }))
  .case(getProjectPhases.async.started, (state) => ({
    ...state,
    isLoadingProjectPhases: true,
  }))
  .case(getProjectPhases.async.failed, (state, { error }) => ({
    ...state,
    isLoadingProjectPhases: false,
  }))
  .case(getProjectPhases.async.done, (state, { result: phases }) => ({
    ...state,
    error: null,
    isLoadingProjectPhases: false,
    shouldUpdateProjectPhases: false,
    project: {
      ...state.project,
      phases,
    },
  }))
  .case(getProjectIssues.async.started, (state) => ({
    ...state,
    isLoadingProjectIssues: true,
    isIssueUpdated: false,
  }))
  .case(getProjectIssues.async.failed, (state, { error }) => ({
    ...state,
    isLoadingProjectIssues: false,
  }))
  .case(getProjectIssues.async.done, (state, { result: issues }) => ({
    ...state,
    error: null,
    isLoadingProjectIssues: false,
    issues,
  }))
  .case(updateProjectIssue.async.started, (state) => ({
    ...state,
    isUpdatingIssue: true,
  }))
  .case(updateProjectIssue.async.failed, (state, { error }) => ({
    ...state,
    isUpdatingIssue: false,
  }))
  .case(updateProjectIssue.async.done, (state, { result }) => ({
    ...state,
    error: null,
    isUpdatingIssue: false,
    isIssueUpdated: true,
  }))
  .case(getKpiById.async.started, (state) => ({
    ...state,
    isLoadingKpis: true,
  }))
  .case(getKpiById.async.failed, (state, { error }) => ({
    ...state,
    isLoadingKpis: false,
    error,
  }))
  .case(getKpiById.async.done, (state, { result }) => ({
    ...state,
    error: null,
    isLoadingKpis: false,
    shouldUpdateProjectPhases: false,
    kpis: result.data || [],
  }))
  .case(uploadTagSheetCSV.async.started, (state) => ({
    ...state,
    isUploadingCSV: true,
  }))
  .case(uploadTagSheetCSV.async.failed, (state, { error }) => ({
    ...state,
    isUploadingCSV: false,
    error,
  }))
  .case(uploadTagSheetCSV.async.done, (state, { result: assetTypes }) => ({
    ...state,
    error: null,
    isUploadingCSV: false,
    isCsvUploaded: true,
    project: { ...state.project, assetTypes },
  }))
  .case(exportTagSheetCSV.async.started, (state) => ({
    ...state,
    isExportingCSV: true,
  }))
  .case(exportTagSheetCSV.async.failed, (state, { error }) => ({
    ...state,
    isExportingCSV: false,
    error,
  }))
  .case(exportTagSheetCSV.async.done, (state) => ({
    ...state,
    error: null,
    isExportingCSV: false,
  }))
  .case(verifyTagSheetCSV.async.started, (state) => ({
    ...state,
    isUploadingCSV: true,
  }))
  .case(verifyTagSheetCSV.async.failed, (state, { error }) => ({
    ...state,
    isUploadingCSV: false,
    error,
  }))
  .case(verifyTagSheetCSV.async.done, (state) => ({
    ...state,
    error: null,
    isUploadingCSV: false,
  }))
  .case(inviteTeamMember.async.started, (state) => ({
    ...state,
    isInviting: true,
  }))
  .case(inviteTeamMember.async.failed, (state, { error }) => ({
    ...state,
    isInviting: false,
    error,
  }))
  .case(inviteTeamMember.async.done, (state, result) => {
    const projectManagers = state.project?.projectManagers?.filter((user) => user.id !== result.result.id) || [];
    const techs = state.project?.techs?.filter((user) => user.id !== result.result.id) || [];
    const clients = state.project?.clients?.filter((user) => user.id !== result.result.id) || [];

    const resultWithProjectRoles = {
      ...result.result,
      projectRoles: [
        {
          projectId: state.project.id,
          role: result.result.role,
        },
      ],
    };
    switch (result.params.role) {
      case UserRole.ProjectManager:
        projectManagers.push(resultWithProjectRoles);
        break;
      case UserRole.Tech:
        techs.push(resultWithProjectRoles);
        break;
      case UserRole.Client:
        clients.push(resultWithProjectRoles);
    }

    return {
      ...state,
      project: {
        ...state.project,
        projectManagers,
        techs,
        clients,
      },
      error: null,
      isInviting: false,
    };
  })
  .case(editProjectMember.async.started, (state) => ({
    ...state,
    isEditing: true,
  }))
  .case(editProjectMember.async.failed, (state, { error }) => ({
    ...state,
    isEditing: false,
    error,
  }))
  .case(editProjectMember.async.done, (state, { result }) => {
    const projectManagers = state.project?.projectManagers?.filter((user) => user.id !== result.user.id) || [];
    const techs = state.project?.techs?.filter((user) => user.id !== result.user.id) || [];
    const clients = state.project?.clients?.filter((user) => user.id !== result.user.id) || [];

    switch (getProjectRoleForUserById({ user: result.user, projectId: state.project.id! })) {
      case UserRole.ProjectManager:
        projectManagers.push(result.user);
        break;
      case UserRole.Tech:
        techs.push(result.user);
        break;
      case UserRole.Client:
        clients.push(result.user);
    }

    return {
      ...state,
      project: {
        ...state.project,
        projectManagers,
        techs,
        clients,
      },
      error: null,
      isEditing: false,
    };
  })
  .case(changeProjectMemberAccess.async.started, (state) => ({
    ...state,
    isRemoving: true,
  }))
  .case(changeProjectMemberAccess.async.failed, (state, { error }) => ({
    ...state,
    isRemoving: false,
    error,
  }))
  .case(changeProjectMemberAccess.async.done, (state, { result: { userId } }) => {
    return {
      ...state,
      error: null,
      project: {
        ...state.project,
        projectManagers: state.project.projectManagers?.map((user) =>
          user.id === userId ? { ...user, isActive: !user.isActive } : user
        ),
        techs: state.project.techs?.map((user) => (user.id === userId ? { ...user, isActive: !user.isActive } : user)),
      },
      isRemoving: false,
    };
  })
  .case(createProject.async.started, (state) => ({
    ...state,
    isCreatingProject: true,
  }))
  .case(createProject.async.failed, (state, { error }) => ({
    ...state,
    isCreatingProject: false,
    error,
  }))
  .case(createProject.async.done, (state) => ({
    ...state,
    error: null,
    isCreatingProject: false,
  }))
  .case(closeProject.async.started, (state) => ({
    ...state,
    isDeletingProject: true,
  }))
  .case(closeProject.async.failed, (state, { error }) => ({
    ...state,
    isDeletingProject: false,
    error,
  }))
  .case(closeProject.async.done, (state, { result: request }) => ({
    ...state,
    data: state.data.map((project) =>
      project.id === request.id ? { ...project, isActive: false, isDeleted: false } : project
    ),
    project: {
      ...state.project,
      isActive: false,
      isDeleted: false,
    },
    error: null,
    isDeletingProject: false,
  }))
  .case(deleteProject.async.started, (state) => ({
    ...state,
    isDeletingProject: true,
  }))
  .case(deleteProject.async.failed, (state, { error }) => ({
    ...state,
    isDeletingProject: false,
    error,
  }))
  .case(deleteProject.async.done, (state, { result: request }) => ({
    ...state,
    data: state.data.map((project) =>
      project.id === request.id ? { ...project, isActive: false, isDeleted: true } : project
    ),
    project: {
      ...state.project,
      isActive: false,
      isDeleted: true,
    },
    error: null,
    isDeletingProject: false,
  }))
  .case(assignAreaToTechs.async.done, (state, { result }) => {
    return {
      ...state,
      project: {
        ...state.project,
        areas: state.project.areas?.map((area) => (area.id === result.id ? result : area)),
      },
    };
  })
  .case(assignTagToTechs.async.done, (state, { result }) => {
    return {
      ...state,
      project: {
        ...state.project,
        areas: state.project.areas?.map((area) => {
          if (area.id === result.areaId) {
            return {
              ...area,
              tags: area.tags?.map((tag) => (tag.id === result.id ? result : tag)),
            };
          }
          return area;
        }),
      },
    };
  })
  .case(createPhase.async.started, (state) => ({
    ...state,
    isCreatingPhases: true,
  }))
  .case(createPhase.async.done, (state, { result }) => {
    const { project, data } = state;
    const phases: Phase[] = result.map(({ data }) => data!);
    const updatedProject = { ...project, phases };
    return {
      ...state,
      project: updatedProject,
      data: data.map((p) => (p.id === project.id ? updatedProject : p)),
      isCreatingPhases: false,
    };
  })
  .case(updatePhaseName.async.started, (state) => ({
    ...state,
    isUpdatingPhase: true,
  }))
  .case(updatePhaseName.async.done, (state, { result }) => ({
    ...state,
    isUpdatingPhase: false,
    project: {
      ...state.project,
      phases: state.project.phases?.map((phase) => (phase.id === result.id ? { ...phase, name: result.name } : phase)),
    },
  }))
  .case(updatePhaseName.async.failed, (state, { error }) => ({
    ...state,
    isUpdatingPhase: false,
    error,
  }))
  .case(createChecklist.async.started, (state) => ({
    ...state,
    isCreatingChecklist: true,
  }))
  .case(createChecklist.async.failed, (state, { error }) => ({
    ...state,
    isCreatingChecklist: false,
    error,
  }))
  .case(createChecklist.async.done, (state, { result }) => ({
    ...state,
    error: null,
    isCreatingChecklist: false,
    shouldUpdateProjectPhases: true,
  }))
  .case(updateChecklist.async.started, (state) => ({
    ...state,
    isCreatingChecklist: true,
  }))
  .case(updateChecklist.async.failed, (state, { error }) => ({
    ...state,
    isCreatingChecklist: false,
    error,
  }))
  .case(updateChecklist.async.done, (state, { result }) => ({
    ...state,
    error: null,
    isCreatingChecklist: false,
    shouldUpdateProjectPhases: true,
  }))
  .case(deleteChecklist.async.started, (state) => ({
    ...state,
    isDeletingChecklist: true,
  }))
  .case(deleteChecklist.async.failed, (state, { error }) => ({
    ...state,
    isDeletingChecklist: false,
    error,
  }))
  .case(deleteChecklist.async.done, (state, { result }) => {
    return {
      ...state,
      error: null,
      isDeletingChecklist: false,
      shouldUpdateProjectPhases: true,
    };
  })
  .case(resetError, (state) => ({
    ...state,
    error: null,
  }))
  .case(getTagById.async.started, (state) => ({
    ...state,
    isLoadingTag: true,
  }))
  .case(getTagById.async.failed, (state, { error }) => ({
    ...state,
    isLoadingTag: false,
    error,
  }))
  .case(getTagById.async.done, (state, { result: tagCompletion }) => {
    const { project } = state;
    const headers = tagCompletion.phases!.map((phase) => phase.checklistItemHeader!);
    project.checklistItemHeaders = headers;
    return {
      ...state,
      tag: tagCompletion,
      project: { ...project },
      isLoadingTag: false,
      error: null,
      isIssueUpdated: false,
    };
  })
  .case(getProjectHandoverLink.async.started, (state) => ({ ...state }))
  .case(getProjectHandoverLink.async.failed, (state, { error }) => ({ ...state, error }))
  .case(getProjectHandoverLink.async.done, (state, { result: link }) => ({
    ...state,
    handoverPackageLink: link,
  }))
  .case(getChecklistsAvailableForCopying.async.done, (state, { result }) => ({
    ...state,
    checklistsToCopy: result,
  }))
  .case(getChecklistsAvailableForCopying.async.failed, (state, { error }) => ({
    ...state,
    error,
  }))
  .case(copyChecklist.async.started, (state) => ({
    ...state,
    isCopyingChecklist: true,
  }))
  .case(copyChecklist.async.failed, (state, { error }) => ({
    ...state,
    isCopyingChecklist: false,
    error,
  }))
  .case(copyChecklist.async.done, (state, { result: checklist }) => ({
    ...state,
    isCopyingChecklist: false,
    project: {
      ...state.project,
      phases: state.project.phases?.map((phase: Phase) =>
        phase.id === checklist.phase?.id ? { ...phase, checklists: phase.checklists?.concat(checklist) } : phase
      ),
    },
  }))
  .cases([generateChecklistReport.async.started, generateChecklistReport.async.done], (state) => ({
    ...state,
    isGeneratingChecklistReport: true,
  }))
  .case(generateChecklistReport.async.failed, (state) => ({
    ...state,
    isGeneratingChecklistReport: false,
  }))
  .case(createOrUpdateExclusionList.async.done, (state, { result: exclusionList }) => {
    const phase = state.project.phases?.find((p) => p.id === exclusionList.phaseId);
    const doesExclusionListAlreadyExistOnPhase = phase?.exclusionLists?.find((ex) => ex.id === exclusionList.id);
    return {
      ...state,
      project: {
        ...state.project,
        phases: state.project.phases?.map((p) => {
          return p.id === exclusionList.phaseId
            ? {
                ...p,
                exclusionLists: doesExclusionListAlreadyExistOnPhase
                  ? p.exclusionLists?.map((ex) => (ex.id === exclusionList.id ? exclusionList : ex))
                  : [...(p.exclusionLists || []), exclusionList],
              }
            : p;
        }),
      },
    };
  })
  .case(updateProjectTag.async.started, (state) => ({ ...state }))
  .case(updateProjectTag.async.failed, (state, { error }) => ({ ...state, error }))
  .case(updateProjectTag.async.done, (state, { result: tag }) => {
    const areaToUpdateTag = state.project.areas?.find((i) => i.id === tag.areaId);
    const updatedTags = areaToUpdateTag?.tags?.map((t) => (t.id === tag.id ? { ...tag, issues: t.issues } : t));
    return {
      ...state,
      project: {
        ...state.project,
        areas: state.project.areas!.map((area) =>
          area.id === areaToUpdateTag?.id ? { ...area, tags: updatedTags } : area
        ),
      },
    };
  })
  .case(getFlexFieldById.async.done, (state, result) => {
    return {
      ...state,
      project: {
        ...state.project,
        flexFieldHeaders: result.result.data?.map((field) => field.name!),
      },
    };
  })
  .case(updateUpdateableFlexFields.async.done, (state, result) => {
    return {
      ...state,
      project: {
        ...state.project,
        updateableFlexFields: result.result.updateProjectRequest?.updateableFlexFields,
      },
    };
  });

export default projectsReducer;
