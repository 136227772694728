import React from "react";
import Grid from "@material-ui/core/Grid";

import Header from "components/Header";
import Sidebar, { SidebarProps } from "components/Sidebar";
import Footer from "components/Footer";
import useStyles from "./styles";

interface LayoutProps {
  isSidebarOpen?: boolean;
  showSidebar?: boolean;
  sideBarProps?: Omit<SidebarProps, "isOpen">;
  showMenu?: boolean;
  Menu?: React.ReactElement;
  children: React.ReactNode;
}

const Layout = ({
  isSidebarOpen = false,
  showSidebar = false,
  sideBarProps = { actions: [] },
  showMenu = false,
  Menu,
  children,
}: LayoutProps) => {
  const styles = useStyles();

  return (
    <Grid container wrap="nowrap" className={styles.container}>
      <Grid item>
        <Grid container wrap="nowrap" className={styles.fullHeight}>
          {showSidebar && (
            <Grid item>
              <Sidebar isOpen={isSidebarOpen} {...sideBarProps} />
            </Grid>
          )}
          {showMenu && <Grid item>{Menu}</Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.scroll}>
        <Grid container className={styles.fullHeight} direction="column" wrap="nowrap">
          <Grid item>
            <Header />
          </Grid>
          <Grid item className={styles.childrenContainer}>
            {children}
          </Grid>
          <Grid item xs={12} className={styles.footer}>
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;
