import { reducerWithInitialState } from "typescript-fsa-reducers";
import { TechnicianTagsReducerState } from "./types";
import { upsertTag, createIssue } from "./actions";

const initialState: TechnicianTagsReducerState = {
  isCreatingTag: false,
  error: null,
  isCreatingIssue: false,
};

const TagsReducer = reducerWithInitialState(initialState)
  .case(upsertTag.async.started, (state) => ({
    ...state,
    isCreatingTag: true,
  }))
  .case(upsertTag.async.failed, (state, { error }) => ({
    ...state,
    isCreatingTag: false,
    error,
  }))
  .case(upsertTag.async.done, (state) => ({
    ...state,
    error: null,
    isCreatingTag: false,
  }))
  .case(createIssue.async.started, (state) => ({
    ...state,
    isCreatingIssue: true,
  }))
  .case(createIssue.async.failed, (state, { error }) => ({
    ...state,
    isCreatingIssue: false,
    error,
  }))
  .case(createIssue.async.done, (state) => ({
    ...state,
    isCreatingIssue: false,
    error: null,
  }));

export default TagsReducer;
