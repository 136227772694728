/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface RegisterUserRequest
 */
export interface RegisterUserRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  registrationId?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  firstName?: string | null;
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  phoneNumber?: string | null;
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  lastName?: string | null;
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  email?: string | null;
  /**
   *
   * @type {string}
   * @memberof RegisterUserRequest
   */
  password?: string | null;
}

export function RegisterUserRequestFromJSON(json: any): RegisterUserRequest {
  return RegisterUserRequestFromJSONTyped(json, false);
}

export function RegisterUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterUserRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    registrationId: !exists(json, "registrationId") ? undefined : json["registrationId"],
    firstName: !exists(json, "firstName") ? undefined : json["firstName"],
    phoneNumber: !exists(json, "phoneNumber") ? undefined : json["phoneNumber"],
    lastName: !exists(json, "lastName") ? undefined : json["lastName"],
    email: !exists(json, "email") ? undefined : json["email"],
    password: !exists(json, "password") ? undefined : json["password"],
  };
}

export function RegisterUserRequestToJSON(value?: RegisterUserRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    registrationId: value.registrationId,
    firstName: value.firstName,
    phoneNumber: value.phoneNumber,
    lastName: value.lastName,
    email: value.email,
    password: value.password,
  };
}
