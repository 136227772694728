/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { UserRole, UserRoleFromJSON, UserRoleFromJSONTyped, UserRoleToJSON } from "./UserRole";

/**
 *
 * @export
 * @interface InviteUserToProjectRequest
 */
export interface InviteUserToProjectRequest {
  /**
   *
   * @type {string}
   * @memberof InviteUserToProjectRequest
   */
  email?: string | null;
  /**
   *
   * @type {number}
   * @memberof InviteUserToProjectRequest
   */
  projectId?: number;
  /**
   *
   * @type {UserRole}
   * @memberof InviteUserToProjectRequest
   */
  role?: UserRole;
}

export function InviteUserToProjectRequestFromJSON(json: any): InviteUserToProjectRequest {
  return InviteUserToProjectRequestFromJSONTyped(json, false);
}

export function InviteUserToProjectRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InviteUserToProjectRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: !exists(json, "email") ? undefined : json["email"],
    projectId: !exists(json, "projectId") ? undefined : json["projectId"],
    role: !exists(json, "role") ? undefined : UserRoleFromJSON(json["role"]),
  };
}

export function InviteUserToProjectRequestToJSON(value?: InviteUserToProjectRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    projectId: value.projectId,
    role: UserRoleToJSON(value.role),
  };
}
