/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { ErrorResponse, ErrorResponseFromJSON, ErrorResponseFromJSONTyped, ErrorResponseToJSON } from "./ErrorResponse";
import { Project, ProjectFromJSON, ProjectFromJSONTyped, ProjectToJSON } from "./Project";

/**
 *
 * @export
 * @interface ProjectControllerResponse
 */
export interface ProjectControllerResponse {
  /**
   *
   * @type {Project}
   * @memberof ProjectControllerResponse
   */
  data?: Project;
  /**
   *
   * @type {ErrorResponse}
   * @memberof ProjectControllerResponse
   */
  error?: ErrorResponse;
}

export function ProjectControllerResponseFromJSON(json: any): ProjectControllerResponse {
  console.log("ProjectControllerResponseFromJSON",json);
  return ProjectControllerResponseFromJSONTyped(json, false);
}

export function ProjectControllerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProjectControllerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : ProjectFromJSON(json["data"]),
    error: !exists(json, "error") ? undefined : ErrorResponseFromJSON(json["error"]),
  };
}

export function ProjectControllerResponseToJSON(value?: ProjectControllerResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: ProjectToJSON(value.data),
    error: ErrorResponseToJSON(value.error),
  };
}
